import { useState, useEffect } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Chip } from '@mui/material';
import DropdownMenu from '../components/DropdownMenu';
import Panel from '../components/Panel';
import { Title } from '../components/Titles';
import TableBasedCrud from '../external/ensolvers-core-frontend-mui/components/TableBaseCrud';
import StringUtils from '../external/pdl-common/utils/StringUtils';
import { Bike } from '../external/pdl-common/model/Bike';
import BikeService from '../services/BikeService';
import PdlAlertService from '../services/PdlAlertService';
import { AppTopBar } from '../components/common/AppTopBar';
import AppImage from '../external/pdl-common/components/commons/AppImage';
import PortalInput from '../components/PortalInput';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoutes } from '../utils/enums/AppRoutes';
import { BikeState } from '../models/location-states/BikeState';
import { BikeFilters } from '../models/Utils/BikeFIlters';
import { useFeatureToggle } from '../external/ensolvers-core-frontend-mui/hooks/useFeatureToggle';
import { Features } from '../external/pdl-common/utils/enums/Features';
import featureToggleList from '../external/pdl-common/toggleConfig.json';
import { BikeStatus } from '../external/pdl-common/utils/enums/BikeStatus';
import { ConfirmationModal } from '../components/common/ConfirmationModal';
import { BikeStatusToSet } from '../utils/enums/BikeStatusToSet';
import { AppTypography } from '../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../external/pdl-common/utils/AppTheme';
import { GenericModal } from '../components/common/GenericModal';
import RefreshIcon from '@mui/icons-material/Refresh';

interface Props {
  hideTopBar?: boolean;
  defaultFilter?: string;
}

export default function Home({ hideTopBar = false, defaultFilter }: Props) {
  const [isPortalDetailViewAvailable] = useFeatureToggle(
    featureToggleList,
    Features.IS_PORTAL_DETAIL_VIEW_AVAILABLE
  );

  const history = useHistory();
  const state = useLocation().state as BikeState;
  const path = useLocation().pathname as string;

  const [filters, setFilters] = useState<BikeFilters>(state?.filters || undefined);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    state?.selectedFilter || defaultFilter || 'allBikes'
  );
  const [updateResultsOn, setUpdateResultsOn] = useState<number>(Date.now());
  const [selectedBike, setSelectedBike] = useState<Bike | undefined>(undefined);
  const [userNameOrEmailFilter, setUserNameOrEmailFilter] = useState<string>(
    state?.searchFilter || ''
  );
  const [page, setPage] = useState<number>(state?.pageNumber || 0);
  const [pageSize, setPageSize] = useState<number>(state?.pageSize || 5);

  const [bikeStatus, setBikeStatus] = useState<BikeStatusToSet | undefined>(undefined);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    handleFilterChange(selectedFilter);
  }, [selectedFilter]);

  const handleFilterChange = (selectedOption: string) => {
    let updatedFilters = { ...filters };
    if (selectedOption === 'allBikes') {
      updatedFilters.includePending = true;
      updatedFilters.includeApproved = true;
      updatedFilters.includeActive = true;
      updatedFilters.includeDenied = true;
      updatedFilters.includeDisabled = true;
    } else {
      updatedFilters.includePending = false;
      updatedFilters.includeApproved = false;
      updatedFilters.includeActive = false;
      updatedFilters.includeDenied = false;
      updatedFilters.includeDisabled = false;
      (updatedFilters as any)[selectedOption] = true;
    }
    setFilters(updatedFilters);
    setUpdateResultsOn(Date.now());
    setSelectedFilter(selectedOption);
  };

  const handleOnDataFetch = (pageNumber: number, rowsPerPage: number) => {
    setPage(pageNumber);
    setPageSize(rowsPerPage);

    return BikeService.getBikesPage({
      page: pageNumber,
      pageSize: rowsPerPage,
      ...filters,
      searchFilter: userNameOrEmailFilter,
    });
  };

  const handleHostNameFilterChange = (value: string) => {
    if (value.trim().length > 3 || value !== userNameOrEmailFilter) {
      setUserNameOrEmailFilter(value);
    }
  };

  const getPrice = (bike: Bike) => {
    if (bike.twoDayPrice) {
      return `$${bike.customPrice || bike.listPrice}/$${bike.twoDayPrice!}/$${bike.threePlusDayPrice!}/day`;
    }
    return bike.customPrice ? `$${bike.customPrice}/day` : '-';
  }

  const getFilters = () => {
    return (
      <>
        <DropdownMenu
          handleOptionChange={handleFilterChange}
          label={'Filter:'}
          value={selectedFilter}
          options={[
            {
              title: 'All Bikes',
              value: 'allBikes',
            },
            {
              title: 'Pending',
              value: 'includePending',
            },
            {
              title: 'Approved',
              value: 'includeApproved',
            },
            {
              title: 'Active',
              value: 'includeActive',
            },
            {
              title: 'Denied',
              value: 'includeDenied',
            },
            {
              title: 'Disabled',
              value: 'includeDisabled',
            },
          ]}
        />

        {/* Host name filter */}
        <PortalInput
          label={'Search'}
          value={userNameOrEmailFilter}
          inputStyle={{ width: '15em' }}
          handleChange={(val) => handleHostNameFilterChange(val.target.value)}
          placeholder={'Bike or user name/email'}
        />
      </>
    );
  };

  const getAditionalActions = (bike: Bike, refresh: () => void) => {
    var options: { title: string; action: () => void }[] = [];

    options.push({
      title: 'View detail',
      action: () => {
        if (isPortalDetailViewAvailable) {
          history.push(`${AppRoutes.BIKE_DETAILS}/${bike.externalId}`, {
            previousPage: path,
            pageNumber: page,
            pageSize: pageSize,
            selectedFilter: selectedFilter,
            filters: filters,
            searchFilter: userNameOrEmailFilter,
          });
        }
      },
    });

    if (bike.previousBikeExternalId) {
      options.push({
        title: 'View Previous Bike detail',
        action: async () => {
          if (isPortalDetailViewAvailable) {
            history.push(`${AppRoutes.BIKE_DETAILS}/${bike.previousBikeExternalId}`, {
              previousPage: path,
              page: page,
              pageSize: pageSize,
              selectedFilter: selectedFilter,
              filters: filters,
              searchFilter: userNameOrEmailFilter,
            });
          }
        },
      });
    }

    if (bike.status === BikeStatus.PENDING) {
      options.push({
        title: 'Approve',
        action: async () => {
          (await BikeService.approve(bike.externalId!))
            .onSuccess(() => {
              PdlAlertService.showSuccessMessage('Bike approved successfully');
              refresh();
            })
            .onError((response) => {
              PdlAlertService.showSnackCustomError(response.getContent());
            });
        },
      });
      options.push({
        title: 'Deny',
        action: async () => {
          (await BikeService.deny(bike.externalId!))
            .onSuccess(() => {
              PdlAlertService.showSuccessMessage('Bike denied successfully');
              refresh();
            })
            .onError((response) => {
              PdlAlertService.showSnackCustomError(response.getContent());
            });
        },
      });
    }

    if (bike.status === BikeStatus.ACTIVE) {
      options.push({
        title: 'Disable',
        action: async () => {
          setSelectedBike(bike);
          setOpenConfirmationModal(true);
          setBikeStatus(BikeStatusToSet.DISABLE);
        },
      });
    }

    if (bike.status === BikeStatus.DISABLED) {
      options.push({
        title: 'Activate',
        action: async () => {
          setSelectedBike(bike);
          setOpenConfirmationModal(true);
          setBikeStatus(BikeStatusToSet.ACTIVATE);
        },
      });
    }

    if (bike.status === BikeStatus.DENIED) {
      options.push({
        title: 'Restore Status',
        action: async () => {
          (await BikeService.restoreStatus(bike.externalId!))
            .onSuccess(() => {
              PdlAlertService.showSuccessMessage('Bike updated to pending status successfully');
              refresh();
            })
            .onError((response) => {
              PdlAlertService.showSnackCustomError(response.getContent());
            });
        },
      });
    }
    return options;
  };

  const disableBike = async (bike: Bike) => {
    (await BikeService.disable(bike.externalId!))
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('Bike disabled successfully');
        setUpdateResultsOn(Date.now());
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const activateBike = async (bike: Bike) => {
    (await BikeService.activate(bike.externalId!))
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('Bike activated successfully');
        setUpdateResultsOn(Date.now());
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  return (
    <>
      {!hideTopBar &&
        <AppTopBar title={'Bikes'} />}

      <Title>Bikes</Title>
      <Box style={{ textAlign: 'center' }}>
        <IconButton
          onClick={() => handleFilterChange(selectedFilter)}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Panel>
        <TableBasedCrud
          page={page}
          rowsPerPage={pageSize}
          elementName={'Bike'}
          onDataFetch={handleOnDataFetch}
          renderDialogContent={(register, errors, control) => <></>}
          tableContainer={(props) => <Box>{props.children}</Box>}
          forceUpdateOn={updateResultsOn}
          additionalToolbarButtons={getFilters}
          tableColumns={[
            {
              header: 'Bike',
              content: (bike) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AppImage
                    src={bike.images && bike.images.length !== 0 ? bike.images[0].url : ''}
                    style={{ width: 56, height: 56, borderRadius: 50 }}
                    alt={
                      bike.images && bike.images.length !== 0
                        ? bike.images[0].description
                        : undefined
                    }
                  />
                  <div style={{ marginLeft: 10 }}>
                    <div>{bike.title}</div>
                    <div>{`${bike.owner.user.firstName} ${bike.owner.user.lastName}`}</div>
                  </div>
                </div>
              ),
            },
            {
              header: 'Email',
              content: (bike) => <>{bike.owner.user.email}</>,
            },
            {
              header: 'Created',
              content: (bike) => <>{new Date(bike.createdAt).toLocaleDateString()}</>,
            },
            {
              header: 'Status',
              content: (bike) => {
                switch (bike.status) {
                  case BikeStatus.PENDING:
                    return (
                      <Chip
                        label={StringUtils.capitalizeText(bike.status)}
                        sx={{ backgroundColor: '#F8EBCB', color: '#654D0F' }}
                      />
                    );
                  case BikeStatus.APPROVED:
                  case BikeStatus.ACTIVE:
                    return (
                      <Chip
                        label={StringUtils.capitalizeText(bike.status)}
                        sx={{ backgroundColor: '#E0F4E5', color: '#076046' }}
                      />
                    );
                  case BikeStatus.DENIED:
                    return (
                      <Chip
                        label={StringUtils.capitalizeText(bike.status)}
                        sx={{ backgroundColor: '#F4DDE0', color: '#981B1B' }}
                      />
                    );
                  case BikeStatus.DISABLED:
                    return (
                      <Chip
                        label={StringUtils.capitalizeText(bike.status)}
                        sx={{ backgroundColor: '#F4DDE0', color: '#981B1B' }}
                      />
                    );
                  default:
                    return <></>;
                }
              },
            },
            {
              header: '',
              content: (bike) => {
                return (
                  <>
                    {bike.previousBikeExternalId ? (
                      <div style={{ color: '#076046' }}>Cloned</div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              },
            },

            {
              header: 'Price',
              content: (bike) => <>{getPrice(bike)}</>,
            },
          ]}
          buildAdditionalActions={(bike, refresh) => getAditionalActions(bike, refresh)}
        />
      </Panel>
      <GenericModal
        title={`${bikeStatus} bike`}
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        onConfirm={() => {
          bikeStatus === BikeStatusToSet.ACTIVATE
            ? activateBike(selectedBike!)
            : disableBike(selectedBike!);
          setOpenConfirmationModal(false);
        }
        }
        children={
          <Box style={{ width: '25em', paddingTop: '1em' }}>
            <Box>
              <AppTypography
                type={PDLTypography.none}
                children={`Are you sure you want to ${bikeStatus} `}
              />
            </Box>
            <Box style={{ paddingTop: '1em' }}>
              <AppTypography
                type={PDLTypography.mediumHeading}
                children={selectedBike?.title + '?'}
              />
            </Box>
          </Box>
        }
      />
    </>
  );
}
