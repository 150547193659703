import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { IPage } from '../external/fox-typescript/core/IPage';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { ChatMessage } from '../external/pdl-common/model/ChatMessage';
import ChatMessageCreation from '../external/pdl-common/model/ChatMessageCreation';
import { PdlChatChannel } from '../external/pdl-common/model/PdlChatChannel';
import environment from '../environment.json';
import { SortType } from '../types/SortType';
import { InboxAssignedType } from '../components/Inbox/utils/InboxAssignedType';
import { InboxType } from '../components/Inbox/utils/InboxType';
import FilterUtils from '../utils/FilterUtils';
import { InboxStatusType } from '../external/pdl-common/utils/InboxStatusType';
import { ReportIssueAssignedTypeCount } from '../models/ReportIssueAssignedTypeCount';
import ApiResponseV2 from '../external/fox-typescript/core/ApiResponseV2';
import HttpClientV2 from '../external/fox-typescript/utils/HttpClientV2';
import ChatChannelCreation from '../models/creation-interfaces/ChatChannelCreation';

class InboxService extends GenericCrudService<PdlChatChannel> {
  async getChatHistory(
    channelExternalId: string,
    page?: number,
    pageSize?: number
  ): Promise<ApiResponse<IPage<ChatMessage>>> {
    return await HttpClient.get<IPage<ChatMessage>>(
      `${this.basePath}/chat-history/${channelExternalId}?page=${page}&pageSize=${pageSize}`,
      true
    );
  }

  async sendMessage(
    channelExternalId: string,
    chatMessage: ChatMessageCreation
  ): Promise<ApiResponse<ChatMessage>> {
    return await HttpClient.post(
      `${this.basePath}/send-message/${channelExternalId}`,
      chatMessage,
      true
    );
  }

  // Inbox view
  async getMessagesChannels(
    page: number,
    pageSize: number,
    status: InboxStatusType,
    sortBy: SortType,
    inboxAssignedType: keyof typeof InboxAssignedType
  ): Promise<ApiResponse<IPage<PdlChatChannel>>> {
    const filters = {
      page,
      pageSize,
      sort: FilterUtils.getSortType(sortBy),
      reportIssueStatus: FilterUtils.getStatusType(status),
      inboxAssignedType,
    };

    return await HttpClient.getWithParams<IPage<PdlChatChannel>>(
      `${this.basePath}/channels/REPORT_ISSUE`,
      filters
    );
  }

  // TODO
  async getBikesChannels(
    page: number,
    pageSize: number,
    status: InboxStatusType,
    sortBy: SortType,
    assigned: InboxAssignedType,
    inboxType: InboxType
  ): Promise<ApiResponse<IPage<PdlChatChannel>>> {
    return await HttpClient.get<IPage<PdlChatChannel>>(
      `${this.basePath}/channels?page=${page}&pageSize=${pageSize}`
    );
  }

  async getChannelByReservationId(
    reservationExternalId: string
  ): Promise<ApiResponse<PdlChatChannel>> {
    return await HttpClient.get<PdlChatChannel>(
      `${this.basePath}/channel/${reservationExternalId}`,
      true
    );
  }

  async getReportIssueAssignedTypeCounts(
    reportIssueStatus: InboxStatusType
  ): Promise<ApiResponse<ReportIssueAssignedTypeCount>> {
    return await HttpClient.get<ReportIssueAssignedTypeCount>(
      `${this.basePath}/channels/REPORT_ISSUE/count?reportIssueStatus=${FilterUtils.getStatusType(
        reportIssueStatus
      )}`,
      true
    );
  }

  async getAllChannelsByUserId(
    page: number,
    size: number,
    searchFilter: string,
    sortBy: string = 'DESC'
  ): Promise<ApiResponseV2<IPage<PdlChatChannel>>> {
    return await HttpClientV2.get<IPage<PdlChatChannel>>(
      `${this.basePath}/channels?page=${page}&pageSize=${size}&sort=${sortBy}&filter=${searchFilter}`
    );
  }

  async createChatChannel(
    chatChannelCreation: ChatChannelCreation
  ): Promise<ApiResponseV2<PdlChatChannel>> {
    return await HttpClientV2.post<ChatChannelCreation, PdlChatChannel>(
      `${this.basePath}/chat-channel`,
      chatChannelCreation
    );
  }
}

export default new InboxService(`${environment.baseURL}/portal/inbox`);
