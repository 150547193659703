import { Box, Menu, MenuItem } from '@material-ui/core';
import React from 'react';

interface Props {
  anchor: HTMLElement | null; // It is used to position the menu
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  items: AppMenuItem[];
  children: JSX.Element;
}

interface AppMenuItem {
  label: string;
  onClick: () => void;
}

export const AppMenu = ({ onOpen, onClose, items, anchor, children }: Props) => {
  const open = Boolean(anchor);

  return (
    <>
      <Box aria-expanded={open ? 'true' : undefined} onClick={onOpen}>
        {children}
      </Box>

      <Menu anchorEl={anchor} open={open} onClose={onClose}>
        {items.map((item, index) => (
          <MenuItem key={`${item.label}-${index}`} onClick={item.onClick}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
