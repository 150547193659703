import { useState } from 'react';
import Entity from '../../fox-typescript/core/Entity';
import NotificationService from '../../fox-typescript/services/NotificationService';
import { DialogPosition } from './Common/DialogPosition';
import GenericForm from './Common/GenericForm';

interface EditDialogProps<T extends Entity> {
  /* 
    If `true`, the dialog is open.
  */
  newItemDialogOpen: boolean;
  /**
   * Position of the dialog in the window
   */
  dialogPosition: DialogPosition;
  /**
   * Instance to edit
   */
  currentInstance?: T;
  /**
   * Title of the form
   */
  elementName: string;
  /**
   * Listener for instance updated events
   */
  onUpdate?: (instance: T) => boolean | void;
  /**
   * Listener for instance created events
   */
  onCreate?: (instance: T) => boolean | void;
  /**
   * Listener for cancel events
   */
  onCancel?: () => void;
  /**
   * If validateBeforeClosing is true,
   * - form inputs wont be reset on submit until validated is set to true.
   * - onValidSave will be executed after save has been validated and validated is set to true.
   */
  validateBeforeClosing?: boolean;
  /**
   * Default values to set after a reset occurs (form submission)
   */
  resetValue?: {};
  /**
   * Setter for prop `newItemDialogOpen`
   */
  setNewInstanceDialogOpen: (newValue: boolean) => void;
  /**
   * if changed, this will notify that the content of the dialog is potentially dirty
   */
  setLastUpdateTimestamp: (newValue: number) => void;
  /**
   * If `true`, the dialog changes to edit mode.
   */
  setIsEditModeActive?: (newValue: boolean) => void;
  /**
   * Function that will return the content of the form
   */
  renderDialogContent: (register: any, errors: any, control: any, instance?: T) => JSX.Element;
  /**
   * Styles for the buttons of the dialog
   */
  buttonStyles: {};
  /**
   * Classname of the styles for the form
   */
  dialogStyles?: string;
  /**
   * Styles for the container of the buttons in dialog
  */
  actionContainerStyles?: string;
  /**
   * Avoid closing the dialog when validating fields that are not controlled
  */
  additionalFormValidation?: boolean;
}

export function EditDialog<T extends Entity>(props: EditDialogProps<T>) {
  const [validated, setValidated] = useState(false);
  let isSaving = false;

  return (
    <GenericForm<T>
      dialog={true}
      open={props.newItemDialogOpen}
      dialogPosition={props.dialogPosition}
      buttonStyles={props.buttonStyles}
      actionContainerStyles={props.actionContainerStyles}
      title={!!props.currentInstance ? `Edit ${props.elementName}` : `${props.elementName}`}
      onSave={async (data) => {
        if (isSaving) {
          return;
        }

        if (props.additionalFormValidation !== undefined && !props.additionalFormValidation) {
          return;
        }

        try {
          let result;

          if (props.currentInstance && !!props.currentInstance.externalId) {
            if (!props.onUpdate) {
              return;
            }

            isSaving = true;
            result = await props.onUpdate(data as T);
          } else {
            if (!props.onCreate) {
              return;
            }

            isSaving = true;
            result = await props.onCreate(data! as T);
          }

          if (props.validateBeforeClosing && result) {
            setValidated(true);
            props.onCancel && props.onCancel();
          } else if (props.additionalFormValidation !== undefined) {
            if (!!result) {
              props.setNewInstanceDialogOpen(false);
              props.onCancel && props.onCancel();
            }
            props.setLastUpdateTimestamp(new Date().getTime());
            props.setIsEditModeActive && props.setIsEditModeActive(false);
          } else {
            props.setNewInstanceDialogOpen(false);
            props.setLastUpdateTimestamp(new Date().getTime());
            props.setIsEditModeActive && props.setIsEditModeActive(false);
            props.onCancel && props.onCancel();
          }

        } catch (error: any) {
          const errorMsg = error?.response?.data?.message;
          if (errorMsg) {
            NotificationService.error(errorMsg);
          }
        }
        isSaving = false;
      }}
      instance={props.currentInstance}
      onCancel={() => {
        props.setNewInstanceDialogOpen(false);
        props.setIsEditModeActive && props.setIsEditModeActive(false);
        props.onCancel && props.onCancel();
      }}
      validateBeforeClosing={props.validateBeforeClosing}
      validated={props.validateBeforeClosing ? validated : undefined}
      onValidSave={
        props.validateBeforeClosing
          ? () => {
            props.setNewInstanceDialogOpen(false);
            props.setLastUpdateTimestamp(new Date().getTime());
            props.setIsEditModeActive && props.setIsEditModeActive(false);
            setValidated(false);
          }
          : undefined
      }
      resetValue={props.resetValue}
      renderContent={props.renderDialogContent}
      dialogStyles={props.dialogStyles}
    />
  );
}
