import { Box, CircularProgress, CircularProgressProps } from '@material-ui/core';

export const Spinner = (props: CircularProgressProps) => (
  <Box
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress {...props} />
  </Box>
);
