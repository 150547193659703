import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Palette } from './Palette';
import { Subtitle } from './Titles';

interface Props {
  title?: string;
  children: React.ReactNode;
}

const paginatorStyle = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      backgroundColor: Palette.panelBackground,
      borderRadius: theme.spacing(3),
      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.05)',
      display: 'table',
    },
  })
);

export default function Panel(props: Props) {
  const classes = paginatorStyle();

  return (
    <>
      {props.title && <Subtitle>{props.title}</Subtitle>}
      <Grid container className={classes.panel}>
        {props.children}
      </Grid>
    </>
  );
}
