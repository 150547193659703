import { AppBar, Avatar, Box, ButtonBase, Grid, makeStyles } from '@material-ui/core';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import AppTheme from '../../external/pdl-common/utils/AppTheme';
import { Container } from '@mui/material';
import { AppAssets } from '../../assets/index';
import { useEffect, useState } from 'react';
import AuthService from '../../external/fox-typescript/services/AuthService';
import User from '../../external/fox-typescript/core/User';
import StringUtils from '../../external/pdl-common/utils/StringUtils';

interface Props {
  title: string;
  content?: JSX.Element;
}

export const AppTopBar = ({ title, content }: Props) => {
  const classes = useStyles();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const storageUser: User | undefined = AuthService.getUser();
    setUser(storageUser);
  }, []);

  return (
    <AppBar style={{ boxShadow: 'none' }}>
      <Box className={classes.container}>
        <Container maxWidth={false}>
          <Grid container className={classes.subContainer}>
            <Grid item>
              <AppTypography type={PDLTypography.portalHeader} children={title} />
            </Grid>

            <Grid item>
              {content}
            </Grid>

            <Grid item>
              {user && (
                <Box className={classes.userInfoContainer}>
                  <Avatar alt={StringUtils.formatCompleteUserName(user)} src={user?.avatarUrl} />
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <AppTypography
                      type={PDLTypography.paragraph}
                      children={StringUtils.formatCompleteUserName(user)}
                    />
                    <AppTypography
                      type={PDLTypography.paragraph}
                      children={user?.email}
                      customStyles={{ opacity: 0.5 }}
                    />
                  </Box>
                  <ButtonBase>
                    <AppAssets.ChevronDown />
                  </ButtonBase>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 255,
    paddingTop: '2.5em',
    paddingLeft: '3em',
    paddingRight: '3em',
    width: `calc(100% - ${255}px)`,
    minHeight: '5em',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: AppTheme.palette.background.default,
  },
  subContainer: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
  },
  userInfoContainer: {
    display: 'flex',
    gap: '1em',
    justifyContent: 'center',
  },
}));
