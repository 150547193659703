import { Grid, GridSpacing, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Spinner } from '../external/ensolvers-core-frontend-mui/components/Common/Spinner';
import { AppTypography } from '../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../external/pdl-common/components/Typography/PDLTypography';
import { AppFontFamily } from '../external/pdl-common/utils/AppFontFamily';

export interface option {
  title: string;
  value: string;
}

interface Props {
  handleOptionChange: (selectedOption: string) => void;
  options: option[];
  label?: string;
  value: string;
  containerSpacing?: GridSpacing;
  selectContainerStyle?: CSSProperties;
  selectStyle?: CSSProperties;
  isLoading?: boolean;
}

export default function DropdownMenu(props: Props) {
  const classes = useDropdownMenuStyles();

  return (
    <Grid container spacing={props.containerSpacing ?? 1}>
      <Grid item className={classes.label}>
        <AppTypography type={PDLTypography.portalLabel}>{props.label && props.label}</AppTypography>
      </Grid>

      <Grid item style={props.selectContainerStyle}>
        {props.isLoading ? (
          <div style={{ width: '7em' }}>
            <Spinner size={20} />
          </div>
        ) : (
          <select
            onChange={(e) => props.handleOptionChange(e.target.value)}
            className={classes.selectContainer}
            value={props.value}
            style={{
              fontFamily: AppFontFamily.INTER,
              fontWeight: 600,
              fontSize: '0.85em',
              ...props.selectStyle,
            }}
          >
            {props.options.map((option) => (
              <option
                key={option.title}
                value={option.value}
                style={{ fontFamily: AppFontFamily.INTER, fontWeight: 600 }}
              >
                {option.title}
              </option>
            ))}
          </select>
        )}
      </Grid>
    </Grid>
  );
}

export const useDropdownMenuStyles = makeStyles({
  label: {
    alignSelf: 'center',
    position: 'relative',
    bottom: '0.1rem',
  },
  selectContainer: {
    color: '#01000E',
    opacity: '0.75',
    backgroundColor: 'inherit',
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px solid #DBDBDB',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '14em',
    minWidth: '129px',
    height: '33px',
    fontFamily: 'Inter, Arial',
    fontSize: '10px',
    fontWeight: 600,
  },
});
