import AuthService from '../../../external/fox-typescript/services/AuthService';
import { PdlChatChannel } from '../../../external/pdl-common/model/PdlChatChannel';
import ChatUtils from '../../../external/pdl-common/utils/ChatUtils';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';
import { InboxItem } from './InboxItem';
import { Virtuoso } from 'react-virtuoso';
import { Spinner } from '../../../external/ensolvers-core-frontend-mui/components/Common/Spinner';
import { CoreUser } from '../../../external/pdl-common/model/CoreUser';
import { ChatChannelMembership } from '../../../external/pdl-common/model/ChatChannelMembership';

interface Props {
  channels: PdlChatChannel[];
  onClick: (selectedChannel: PdlChatChannel) => void;
  currentChannel?: PdlChatChannel;
  loadMore: () => Promise<void>;
  itemIndex: number;
  setItemIndex: (index: number) => void;
  totalElements: number;
  simpleChannels?: boolean;
  noMessage?: boolean;
}

export const InboxChannelList = ({
  channels,
  onClick,
  currentChannel,
  loadMore,
  itemIndex,
  setItemIndex,
  totalElements,
  simpleChannels,
  noMessage
}: Props) => {
  const getSubtitle = (channel: PdlChatChannel): string => {
    const issueNumber: string = ` - #${channel.reportIssue?.issueNumber}`;

    return `${DateUtils.customFormat(new Date(channel.channel.lastMessage.createdAt), 'd/M/yy')} ${simpleChannels ? '' : issueNumber
      }`;
  };

  const getCommonUserMember = (channel: PdlChatChannel): CoreUser => {
    console.log('channel.channel.members:' + JSON.stringify(channel.channel.members));
    console.log('AuthService.getUser().externalId:' + AuthService.getUser().externalId);
    const member = channel.channel.members.find(
      (m) => m.user.externalId !== AuthService.getUser().externalId
    );

    return simpleChannels
      ? member?.user!
      : channel.reportIssue?.creator?.user || channel.channel.lastMessage.sender.user;
  };

  const unreadMessage = (channel: PdlChatChannel): boolean => {
    const loggedUserAsMember = ChatUtils.getReceiverMember(channel.channel.members);

    if (loggedUserAsMember && loggedUserAsMember.lastAccessed) {
      return DateUtils.isBeforeThan(
        new Date(loggedUserAsMember.lastAccessed),
        new Date(channel.channel.lastMessage.notificationSentAt)
      );
    }
    return false;
  };

  const setChannelLastAccessed = (pdlChatChannel: PdlChatChannel) => {
    let channel = pdlChatChannel.channel;
    let members: ChatChannelMembership[] = [];

    channel.members.forEach((m) => {
      if (m.user.externalId === AuthService.getUser().externalId) {
        m.lastAccessed = new Date();
      }
      members.push(m);
    });

    channel = { ...channel, members: members };
    pdlChatChannel = { ...pdlChatChannel, channel: channel };
  };

  const renderItem = (channel: PdlChatChannel, index: number) => {
    return (
      <InboxItem
        key={`${channel.externalId}-${index}`}
        user={getCommonUserMember(channel)}
        title={ChatUtils.limitChars(channel.reportIssue?.message || '', 34)}
        subTitle={getSubtitle(channel)}
        message={channel.channel.lastMessage.body}
        onClick={() => {
          setItemIndex(index);
          onClick(channel);
          setChannelLastAccessed(channel);
        }}
        isActive={currentChannel?.externalId === channel.externalId}
        status={channel.reportIssue?.status!}
        unread={noMessage && currentChannel?.externalId === channel.externalId ? false : unreadMessage(channel)}
        simpleChannel={simpleChannels}
      />
    );
  };

  const Footer = () => {
    return <>{channels.length < totalElements && <Spinner />}</>;
  };

  return (
    <Virtuoso
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      data={channels}
      endReached={loadMore}
      itemContent={(index, channel) => renderItem(channel, index)}
      initialTopMostItemIndex={itemIndex - 1 > 0 ? itemIndex - 1 : 0}
      components={{ Footer }}
    />
  );
};
