import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  children?: React.ReactNode;
  customClass?: string;
}

export default function Title(props: Props) {
  const classes = useStyles();

  return (
    <Typography variant="h5" className={props.customClass?? classes.title}>
      {props.children}
    </Typography>
  );
}
