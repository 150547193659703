import { Grid } from '@mui/material';
import { AppTypography } from '../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../external/pdl-common/components/Typography/PDLTypography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { COLORS } from '../external/pdl-common/utils/AppTheme';
import { CSSProperties } from 'react';

interface Props {
  title: string;
  onClick: () => void;
  style?: CSSProperties;
}

export const BackButton = ({ title, onClick, style }: Props) => {
  return (
    <>
      <Grid container mb={'0.6em'} style={{ cursor: 'pointer', ...style }} onClick={onClick}>
        <Grid item>
          <ArrowBackIosIcon
            style={{ width: '16px', height: '22px' }}
            fontSize={'small'}
            htmlColor={COLORS.persianBlue_2238CB}
          />
        </Grid>
        <Grid item>
          <AppTypography
            type={PDLTypography.portalItem}
            customStyles={{ opacity: '0.5' }}
            children={title}
          />
        </Grid>
      </Grid>
    </>
  );
};
