import { PdlChatChannel } from '../../../external/pdl-common/model/PdlChatChannel';
import { ChatComponent } from '../../Chat/ChatComponent';
import { InboxContentHeader } from './InboxContentHeader';
import { AppButton } from '../../common/AppButton';
import DropdownMenu from '../../DropdownMenu';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';
import ReportIssueService from '../../../services/ReportIssueService';
import PdlAlertService from '../../../services/PdlAlertService';
import ReportIssue from '../../../external/pdl-common/model/ReportIssue';
import { ChannelOptions } from '../utils/ChannelOptions';
import { RequestStatus } from '../../../external/pdl-common/utils/RequestStatus';
import { useState } from 'react';

interface Props {
  channel: PdlChatChannel;
  reservationChannel?: PdlChatChannel;
  channelOptions: string;
  onUpdate: () => void;
  setCurrentChannel: (channel: PdlChatChannel) => void;
  setChannelOptions: (option: string) => void;
  onUpdateTicket: () => Promise<void>;
  updateCounts: () => Promise<void>;
}

export const InboxChannelDetail = ({
  channel,
  channelOptions,
  reservationChannel,
  onUpdate,
  setCurrentChannel,
  setChannelOptions,
  onUpdateTicket,
  updateCounts,
}: Props) => {
  const { reportIssue } = channel;

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const isReservationChat: boolean = channelOptions === ChannelOptions.RESERVATION;

  const updateTicket = async () => {
    if (reportIssue) {
      setRequestStatus(RequestStatus.LOADING);
      const response = reportIssue.closedAt
        ? await ReportIssueService.reopenTicket(reportIssue.externalId!)
        : await ReportIssueService.closeTicket(reportIssue.externalId!);

      response
        .onSuccess((res) => {
          const content: ReportIssue = res.getContent();
          setRequestStatus(RequestStatus.SUCCESS);

          PdlAlertService.showSuccessMessage(
            `Ticket succesfully ${content.closedAt ? 'closed' : 'reopened'}`
          );

          setCurrentChannel({ ...channel, reportIssue: content });
          onUpdateTicket();
          updateCounts();
        })
        .onError((error) => {
          setRequestStatus(RequestStatus.ERROR);
          PdlAlertService.showSnackCustomError(error.getContent());
        });
    }
  };

  return (
    <>
      <InboxContentHeader
        title={reportIssue?.message || ''}
        subTitle={DateUtils.customFormat(new Date(channel.createdAt), 'MMMM dd, Y')}
        extraInfo={`${reportIssue?.reservation ? 'Report issue' : 'Request support'} - #${
          reportIssue?.issueNumber
        }`}
        reportIssue={reportIssue}
        setReportIssue={(reportIssue) => {
          setCurrentChannel({ ...channel, reportIssue });
        }}
        updateCounts={updateCounts}
        buttons={
          <>
            {reportIssue && (
              <AppButton
                text={`${!reportIssue.closedAt ? 'Close' : 'Reopen'} Ticket`}
                onClick={updateTicket}
                type="outlined"
              />
            )}
            <DropdownMenu
              handleOptionChange={(e) => setChannelOptions(e)}
              value={channelOptions}
              containerSpacing={0}
              options={
                reportIssue?.reservation
                  ? [
                      {
                        title: ChannelOptions.MORE,
                        value: ChannelOptions.MORE,
                      },
                      {
                        title: isReservationChat
                          ? ChannelOptions.REPORT_ISSUE
                          : ChannelOptions.RESERVATION,
                        value: isReservationChat
                          ? ChannelOptions.REPORT_ISSUE
                          : ChannelOptions.RESERVATION,
                      },
                      {
                        title: ChannelOptions.RESERVATION_DETAIL,
                        value: ChannelOptions.RESERVATION_DETAIL,
                      },
                      {
                        title: ChannelOptions.BIKE_DETAIL,
                        value: ChannelOptions.BIKE_DETAIL,
                      },
                    ]
                  : [
                      {
                        title: ChannelOptions.MORE,
                        value: ChannelOptions.MORE,
                      },
                    ]
              }
            />
          </>
        }
      />

      <ChatComponent
        channel={channel}
        channelExternalId={isReservationChat ? reservationChannel?.externalId : channel?.externalId}
        isReservationChat={isReservationChat}
      />
    </>
  );
};
