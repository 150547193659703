import { Box, makeStyles } from '@material-ui/core';
import { Button, DialogActions, Modal } from '@mui/material';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';

interface Props {
  title: string;
  openModal: boolean;
  setOpenModal: (state: boolean) => void;
  disabled?: boolean;
  onConfirm: () => void;
  onClose?: () => void;
  children: JSX.Element;
}

export const GenericModal = ({
  title,
  openModal,
  setOpenModal,
  disabled,
  onConfirm,
  onClose,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <Modal open={openModal} onClose={() => (onClose ? onClose() : setOpenModal(false))}>
      <Box className={classes.modal}>
        {/* Title */}
        <AppTypography type={PDLTypography.largeHeading} children={title} />

        {/* Content */}
        {children}

        {/* Action buttons */}
        <DialogActions>
          <Button children={'Confirm'} disabled={disabled} onClick={() => onConfirm()} />
          <Button children={'Cancel'} onClick={() => (onClose ? onClose() : setOpenModal(false))} />
        </DialogActions>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: COLORS.white_FFFFFF,
    borderRadius: '1em',
    padding: '2em',
  },
}));
