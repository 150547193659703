import Reservation from '../../external/pdl-common/model/Reservation';
import { Grid } from '@mui/material';
import { Box, Checkbox } from '@material-ui/core';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { AppItem } from '../common/AppItem';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../../external/pdl-common/utils/AppTheme';
import { PickUpDropOffCheckList } from '../../external/pdl-common/model/PickUpDropOffCheckList';
import { Bike } from '../../external/pdl-common/model/Bike';
import Image from '../../../src/external/fox-typescript/core/Image';
import DateUtils from '../../external/fox-typescript/utils/DateUtils';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import environment from '../../environment.json';
interface Props {
  checklist: PickUpDropOffCheckList;
  bike: Bike;
  images: Image[];
  date: Date;
  paymentIntentId?: string;
  cancelHoldOnStatus?: string;
}

const PickUpDropOff = ({ checklist, bike, images, date, paymentIntentId, cancelHoldOnStatus }: Props) => {
  const useStyles = makeStyles((theme: IAppTheme) => ({
    container: {
      borderRadius: '2em',
      backgroundColor: COLORS.gray_F9F9F9,
      margin: '0.5em',
      padding: '0.5em',
    },
    carousel: {
      cursor: 'pointer',
    },
  }));
  const classes = useStyles();

  const getHoldOnInfo = () => {
    return cancelHoldOnStatus ? paymentIntentId + ' (cancellation: ' + cancelHoldOnStatus  + ')' : paymentIntentId;

  }
  return (
    <>
      <Grid container className={classes.container}>
        <Grid item>
          <Box style={{ width: '30em' }}>
            <Carousel
              className={classes.carousel}
              bike={bike}
              showIndexIndicator
              slidesToShow={{ lg: 1, md: 1, sm: 1 }}
              data={images ?? []}
              renderItem={(img) => (
                <AppImage
                  key={img.externalId}
                  src={img.url}
                  alt={bike?.title}
                  style={{ width: '30em' }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sx={{ marginLeft: '2em' }}>
          <Box className={classes.container}>
            <AppItem title={'Date'} value={date ? DateUtils.formatDate(date, 'ddd, MMM DD, HH:mm A') : "Still in progress"} />
          </Box>
          {paymentIntentId && (
          <Box className={classes.container}>
            <AppItem 
              title={'Hold on card - Stripe Id'} 
              value={getHoldOnInfo()!}
              valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
              onValueClick={() => window.open(environment.stripeURL + 'payments/'+ paymentIntentId)}
            />
          </Box>
          )}
          <Box className={classes.container}>
            <AppItem title={'Checklist'} value={''} />
            <Box>
              <Checkbox checked={checklist.makeAndModel} disabled />
              <AppTypography type={PDLTypography.paragraph}>Make and Model</AppTypography>
            </Box>
            <Box>
              <Checkbox checked={checklist.bikeCondition} disabled />
              <AppTypography type={PDLTypography.paragraph}>Condition</AppTypography>
            </Box>
            <Box>
              <Checkbox checked={checklist.damage} disabled />
              <AppTypography type={PDLTypography.paragraph}>Damage</AppTypography>
            </Box>
            <Box>
              <Checkbox checked={checklist.parts} disabled />
              <AppTypography type={PDLTypography.paragraph}>Parts</AppTypography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Box style={{ marginBottom: '4em' }}>
          <AppItem title={'Comments'} value={checklist?.userComments!} />
        </Box>
      </Grid>
    </>
  );
};

export default PickUpDropOff;
