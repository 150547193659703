import { Box, makeStyles } from '@material-ui/core';
import { ChatMessage } from './ChatMessage';
import { useState, useEffect, useRef, useCallback } from 'react';
import { ChatMessage as ChatMessageModel } from '../../external/pdl-common/model/ChatMessage';
import ChatMessageCreation from '../../external/pdl-common/model/ChatMessageCreation';
import InboxService from '../../services/InboxService';
import PdlAlertService from '../../services/PdlAlertService';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import useInterval from '../../external/pdl-common/hooks/useInterval';
import { AppSimpleList } from '../../external/pdl-common/components/commons/AppSimpleList';
import { Debouncer } from '../../external/pdl-common/utils/Debouncer';
import { ChatInput } from './ChatInput';
import { PAGE_SIZE, Pagination } from '../../models/Utils/Pagination';
import { PdlChatChannel } from '../../external/pdl-common/model/PdlChatChannel';
import AuthService from '../../external/fox-typescript/services/AuthService';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import AppTheme from '../../external/pdl-common/utils/AppTheme';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import LoadingSpinner from '../../external/pdl-common/components/commons/LoadingSpinner';

interface Props {
  channelExternalId?: string;
  channel: PdlChatChannel;
  isReservationChat?: boolean;
  onSend?: () => void;
  chatAlwaysEnabled?: boolean;
  autofocus?: boolean;
}

const REFRESH_TIME = 10 * 1000; // 10 seconds

export const ChatComponent = ({
  channelExternalId,
  channel,
  isReservationChat,
  onSend,
  chatAlwaysEnabled,
  autofocus = true
}: Props) => {
  const classes = useStyles();

  const messagesStartRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [newMessage, setNewMessage] = useState<string>('');
  const [messages, setMessages] = useState<ChatMessageModel[]>([]);
  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);
  const [pageData, setPageData] = useState<Pagination>({ number: 0, totalPages: 0 });
  const [newMessagesLength, setNewMessagesLength] = useState<number>(0);

  const getMessages = useCallback(
    async (showLoader: boolean = true) => {
      if (channelExternalId) {
        showLoader && setRequestStatus(RequestStatus.LOADING);

        (await InboxService.getChatHistory(channelExternalId, 0, PAGE_SIZE))
          .onSuccess((response) => {
            setRequestStatus(RequestStatus.SUCCESS);

            const newMessages = response.getContent().content;

            if (
              messages.length > 0 &&
              newMessages[0].externalId === messages[messages.length - 1].externalId
            ) {
              // It's a refresh and there are no new messages, so we don't update the state.
              return;
            }

            setMessages(newMessages.reverse());
            setNewMessagesLength(newMessages.length);
            setPageData({
              number: response.getContent().number,
              totalPages: response.getContent().totalPages,
            });
            scrollToBottom();
          })
          .onError((response) => {
            PdlAlertService.showSnackCustomError(response.getContent());
            setRequestStatus(RequestStatus.ERROR);
          });
      }
    },
    [channelExternalId]
  );

  useEffect(() => {
    getMessages();
  }, [channelExternalId]);

  // Refresh chat messages every certain time.
  useInterval(() => {
    getMessages(false);
  }, REFRESH_TIME);

  const sendMessage = async () => {
    if (newMessage && channelExternalId) {
      setSubmitStatus(RequestStatus.LOADING);

      (await InboxService.sendMessage(channelExternalId, new ChatMessageCreation(newMessage)))
        .onSuccess((response) => {
          setSubmitStatus(RequestStatus.SUCCESS);

          setMessages([...messages, response.getContent()]);
          setNewMessage('');
          scrollToBottom();

          onSend && onSend();
        })
        .onError((response) => {
          setSubmitStatus(RequestStatus.ERROR);
          PdlAlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const loadMoreMessages = async () => {
    Debouncer.debounce(
      'loadMoreMessages',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages || !channelExternalId) {
          return;
        }

        (await InboxService.getChatHistory(channelExternalId, pageData.number + 1, PAGE_SIZE))
          .onSuccess((response) => {
            const newMessages = response.getContent().content;

            setMessages([...newMessages.reverse(), ...messages]);
            setNewMessagesLength(newMessages.length);
            setPageData({ ...pageData, number: pageData.number + 1 });
            scrollToMiddle();
          })
          .onError((response) => {
            PdlAlertService.showSnackCustomError(response.getContent());
          });
      },
      250
    );
  };

  const renderItem = (message: ChatMessageModel, index: number) => {
    return (
      <div key={`${message.externalId!}-${index}`}>
        {index === newMessagesLength && <div ref={messagesStartRef} />}

        <ChatMessage
          message={message}
          showDivider={messages.length - 1 === index ? false : true}
          isFirstMessage={index === 0}
          rentalDates={
            channel.reportIssue?.reservation
              ? DateUtils.formattedRangeDates(
                  new Date(channel.reportIssue?.reservation.startDate!),
                  new Date(channel.reportIssue?.reservation.endDate!)
                )
              : ''
          }
        />

        {messages.length - 1 === index && <div ref={messagesEndRef} />}
      </div>
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  const scrollToMiddle = () => {
    messagesStartRef.current?.scrollIntoView();
  };

  const isInputDisabled = (): boolean => {
    return chatAlwaysEnabled
      ? false
      : submitStatus === RequestStatus.LOADING ||
          channel.reportIssue?.assignedTo === undefined ||
          channel.reportIssue?.assignedTo.user.externalId !== AuthService.getUser().externalId;
  };

  return (
    <Box className={classes.chatContainer}>
      <>
        {isReservationChat && channelExternalId && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {requestStatus !== RequestStatus.LOADING && (
              <div className={classes.rentalInfoContainer}>
                <AppTypography
                  type={PDLTypography.callToAction}
                  children={`${channel.reportIssue?.reservation.renter.user.firstName} rented 
                    ${channel.reportIssue?.reservation.bike.owner.user.firstName}'s bike:  
                    ${channel.reportIssue?.reservation.bike.title} `}
                />
              </div>
            )}
          </div>
        )}

        {/* In case of error, no conversation is shown */}
        {channelExternalId && (
          <Box
            className={classes.messagesContainer}
            style={isReservationChat ? { height: '105%' } : { height: '90%' }}
          >
            {requestStatus === RequestStatus.LOADING ? (
              <LoadingSpinner />
            ) : (
              <AppSimpleList
                className={classes.list}
                data={messages}
                loadMore={loadMoreMessages}
                renderItem={renderItem}
                topScroll
              />
            )}
          </Box>
        )}

        {!isReservationChat && (
          <ChatInput
            message={newMessage}
            setMessage={setNewMessage}
            isLoading={submitStatus === RequestStatus.LOADING}
            inputDisabled={isInputDisabled()}
            onSubmit={sendMessage}
            autofocus={autofocus}
          />
        )}
      </>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '2em',
    padding: '2em',
    height: '62.5vh',
  },
  messagesContainer: {
    overflowY: 'auto',
    paddingBottom: '1em',
  },
  list: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  rentalInfoContainer: {
    borderRadius: '3.125em',
    border: `0.06em solid ${AppTheme.colors.persianBlue_2238CB}`,
    paddingInline: '1.875em',
    paddingTop: '0.2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.875em',
  },
}));
