import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { IPage } from '../external/fox-typescript/core/IPage';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { Content } from '../external/pdl-common/model/Content';
import environment from '../environment.json';

class ContentService extends GenericCrudService<Content> {
  async getAllContent(
    page: number,
    pageSize: number,
    filter?: string
  ): Promise<ApiResponse<IPage<Content>>> {
    return await HttpClient.get<IPage<Content>>(
      `${this.basePath}/all?page=${page}&size=${pageSize}&contentTitle=${filter}`,
      true
    );
  }

  async getContentDetails(externalId: string): Promise<ApiResponse<Content>> {
    return await HttpClient.get(`${this.basePath}/${externalId}`, true);
  }

  async synchronizeContent(): Promise<ApiResponse<String>> {
    return await HttpClient.put(`${this.basePath}/wp-update`, true);
  }
}

export default new ContentService(`${environment.baseURL}/content`);
