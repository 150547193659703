export enum PaymentTypeFilter {
  ALL = 'ALL',
  ONLY_PAYOUTS = 'ONLY_PAYOUTS',
  ONLY_PENALTIES = 'ONLY_PENALTIES',
}

export enum PaymentStatusFilter {
  ALL = 'ALL',
  ONLY_PAID = 'ONLY_PAID',
  ONLY_UNPAID = 'ONLY_UNPAID',
}
