import { Box, Grid, makeStyles } from '@material-ui/core';
import { AppAssets } from '../../../assets';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import DropdownMenu, { option } from '../../DropdownMenu';
import { InboxStatus } from './InboxStatus';
import { useEffect, useState } from 'react';
import UserService from '../../../services/UserService';
import PdlAlertService from '../../../services/PdlAlertService';
import StringUtils from '../../../external/pdl-common/utils/StringUtils';
import ReportIssueService from '../../../services/ReportIssueService';
import ReportIssue from '../../../external/pdl-common/model/ReportIssue';
import { RequestStatus } from '../../../external/pdl-common/utils/RequestStatus';
import { COLORS } from '../../../external/pdl-common/utils/AppTheme';
import ChatUtils from '../../../external/pdl-common/utils/ChatUtils';

interface Props {
  title: string;
  subTitle: string;
  extraInfo?: string;
  buttons?: JSX.Element;
  reportIssue?: ReportIssue;
  setReportIssue: (reportIssue: ReportIssue) => void;
  updateCounts: () => Promise<void>;
}

export const InboxContentHeader = ({
  title,
  subTitle,
  extraInfo,
  updateCounts,
  buttons,
  reportIssue,
  setReportIssue,
}: Props) => {
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  const [supportUsersOptions, setSupportUsersOptions] = useState<option[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>(
    reportIssue?.assignedTo?.externalId || ''
  );

  useEffect(() => {
    getSupportUsers();
  }, []);

  useEffect(() => {
    if (reportIssue) {
      setSelectedValue(reportIssue.assignedTo?.externalId || '');
    }
  }, [reportIssue]);

  const getSupportUsers = async () => {
    const response = await UserService.getSupportUsers();

    response
      .onSuccess((response) => {
        const supportUsers = response.getContent();

        const options: option[] = supportUsers.map((su) => ({
          title: StringUtils.formatCompleteUserName(su.user),
          value: su.externalId || '',
        }));

        options.unshift({ title: 'Unassigned', value: '-1' });

        setSupportUsersOptions(options);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const assignSupportUser = async (supportExternalId: string) => {
    if (reportIssue?.externalId && supportExternalId) {
      setRequestStatus(RequestStatus.LOADING);

      const response = await ReportIssueService.assignTicket(
        reportIssue.externalId,
        supportExternalId
      );

      response
        .onSuccess((response) => {
          setRequestStatus(RequestStatus.SUCCESS);
          const content = response.getContent();
          setReportIssue(content);
          updateCounts();

          if (content.assignedTo) {
            PdlAlertService.showSuccessMessage(
              `Ticket assigned to ${StringUtils.formatCompleteUserName(content.assignedTo.user)}`
            );
          }
        })
        .onError((response) => {
          setRequestStatus(RequestStatus.ERROR);
          PdlAlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const unassignSupportUser = async () => {
    if (reportIssue?.externalId) {
      const response = await ReportIssueService.unassignTicket(reportIssue.externalId);

      response
        .onSuccess((response) => {
          const content = response.getContent();
          setReportIssue(content);
          updateCounts();

          PdlAlertService.showSuccessMessage('Ticket unassigned');
        })
        .onError((response) => {
          PdlAlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  return (
    <>
      <Grid container className={classes.headerContainer}>
        <Box className={classes.actionsContainer}>
          {/* Inbox status */}
          <AppTypography type={PDLTypography.smallHeading} children={'Status: '} />
          {reportIssue?.status && <InboxStatus status={reportIssue.status} withLabel />}

          {/* Inbox support assigned */}
          <DropdownMenu
            isLoading={requestStatus === RequestStatus.LOADING}
            label={'Assigned: '}
            handleOptionChange={(value) => {
              setSelectedValue(value);

              if (value !== '-1') {
                assignSupportUser(value);
              } else {
                unassignSupportUser();
              }
            }}
            value={selectedValue}
            options={supportUsersOptions}
          />
        </Box>

        {/* Buttons */}
        <Box className={classes.buttonsContainer}>{buttons}</Box>
      </Grid>

      {/* Inbox general information */}
      <Box className={classes.titleContainer}>
        <Box>
          <AppAssets.PortalCalendar />
        </Box>
        <AppTypography
          type={PDLTypography.smallHeading}
          children={subTitle}
          customClasses={classes.title}
        />
      </Box>
      <Box className={classes.extraInfoContainer}>
        {extraInfo && (
          <AppTypography
            type={PDLTypography.largeHeading}
            children={extraInfo}
            customStyles={{
              color: reportIssue?.reservation ? COLORS.RED_FF0000 : COLORS.persianBlue_2238CB,
            }}
          />
        )}
      </Box>
      <Box style={{ marginBottom: '1em', marginTop: '1em' }}>
        <AppTypography
          type={PDLTypography.largeHeading}
          children={ChatUtils.limitChars(title, 32)}
        />
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    gap: '0.375em',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.25em',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  extraInfoContainer: {
    textAlign: 'center',
  },
  title: {
    marginLeft: '.5em',
    marginRight: '0.875em',
  },
}));
