import { Grid } from '@mui/material';
import { AppItem } from '../common/AppItem';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import Logger from '../../external/pdl-common/utils/Logger';
import environment from '../../environment.json'
import { COLORS } from '../../external/pdl-common/utils/AppTheme';

interface Props {
  user: FullUser;
  last4CreditCardNumber?: string
}

const logger = new Logger('UserMeasurement');

const UserStripeInformation = ({ user, last4CreditCardNumber }: Props) => {
  
  return (
    <Grid container justifyContent={'space-around'} pt="1em" pb="1em">
      <AppItem 
        title={'Customer Id'}
        value={user.stripeCustomerId} 
        valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
        onValueClick={() => window.open(environment.stripeURL + 'customers/'+ user.stripeCustomerId)}

      />
      <AppItem title={'Credit Card - Last 4 digits'} value={last4CreditCardNumber!} />
      <AppItem 
        title={'Account Id'}
        value={user.stripeAccountId}
        valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
        onValueClick={() => window.open(environment.stripeURL + 'connect/accounts/'+ user.stripeAccountId)}
      />
    </Grid>
  );
};

export default UserStripeInformation;
