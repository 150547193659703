export enum SidebarTabs {
  DASHBOARD = 'Dashboard',
  USERS = 'Users',
  REVENUE = 'Revenue',
  INBOX = 'Inbox',
  CHAT = 'Chat',
  ANALYTICS = 'Analytics',
  BIKES = 'Bikes',
  TRIPS = 'Rides',
  PAYOUTS = 'Payouts',
  UTILS = 'Utils',
}
