import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClient from '../external/fox-typescript/utils/HttpClient';

class StripeService {
  async getUserCreditCard(externalId: string): Promise<ApiResponse<string>> {
    return await HttpClient.get(`/portal/stripe/${externalId}/card/`);
  }
}

export default new StripeService();
