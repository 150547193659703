import { useState } from 'react';
import { Grid, Box, ButtonBase, makeStyles } from '@material-ui/core';
import { AppAssets } from '../../../assets/index';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import DropdownMenu from '../../DropdownMenu';
import { SortType } from '../../../types/SortType';
import { AppMenu } from '../../common/AppMenu';
import { InboxType } from '../utils/InboxType';
import { InboxAssignedType } from '../utils/InboxAssignedType';
import FilterUtils from '../../../utils/FilterUtils';
import { InboxStatusType } from '../../../external/pdl-common/utils/InboxStatusType';
import { ReportIssueAssignedTypeCount } from '../../../models/ReportIssueAssignedTypeCount';

interface Props {
  // Status filters
  statusFilter: InboxStatusType;
  setStatusFilter: (newStatus: InboxStatusType) => void;

  // Assigned filters
  assignedFilter: keyof typeof InboxAssignedType;
  setAssignedFilter: (newAssigned: keyof typeof InboxAssignedType) => void;

  // Inbox types
  inboxTypeFilter: InboxType;
  setInboxTypeFilter: (newType: InboxType) => void;

  // Sort
  sortFilter: SortType;
  setSortFilter: (newSort: SortType) => void;

  //Count ReportIssues by type assigned
  countOfReportIssuesByAssignedTypes: ReportIssueAssignedTypeCount | undefined;
  setCountOfReportIssuesByAssignedTypes: (
    newCount: ReportIssueAssignedTypeCount | undefined
  ) => void;
}

export const InboxFilter = (props: Props) => {
  const {
    statusFilter,
    setStatusFilter,
    assignedFilter,
    setAssignedFilter,
    inboxTypeFilter,
    setInboxTypeFilter,
    sortFilter,
    setSortFilter,
  } = props;

  const classes = useStyles();

  // Used to position the menu
  const [anchorSortFilter, setAnchorSortFilter] = useState<null | HTMLElement>(null);
  const [anchorInboxStatusFilter, setAnchorInboxStatusFilter] = useState<null | HTMLElement>(null);

  const getAssignedTypeAndCount = (assignedType: string): string => {
    let count: number | undefined = 0;

    switch (assignedType) {
      case 'All':
        count = props.countOfReportIssuesByAssignedTypes?.all!;
        break;
      case 'For you':
        count = props.countOfReportIssuesByAssignedTypes?.forYou!;
        break;
      case 'Assigned':
        count = props.countOfReportIssuesByAssignedTypes?.assignedCount!;
        break;
      case 'Unassigned':
        count = props.countOfReportIssuesByAssignedTypes?.unassignedCount!;
        break;
    }
    return `${assignedType}(${count || 0})`;
  };

  const getLabelAndCount = (status: string): string => {
    return `${status}(${props.countOfReportIssuesByAssignedTypes?.all!})`;
  };

  return (
    <>
      <Grid container className={classes.filterContainer}>
        {/* Inbox status filter */}
        <Grid item className={classes.filterTitleContainer}>
          <ButtonBase>
            <AppMenu
              anchor={anchorInboxStatusFilter}
              onOpen={(event) => setAnchorInboxStatusFilter(event.currentTarget)}
              onClose={() => setAnchorInboxStatusFilter(null)}
              items={[
                {
                  label: InboxStatusType.ALL,
                  onClick: () => {
                    setStatusFilter(InboxStatusType.ALL);
                    setAnchorInboxStatusFilter(null);
                  },
                },
                {
                  label: InboxStatusType.OPEN,
                  onClick: () => {
                    setStatusFilter(InboxStatusType.OPEN);
                    setAnchorInboxStatusFilter(null);
                  },
                },
                {
                  label: InboxStatusType.PENDING,
                  onClick: () => {
                    setStatusFilter(InboxStatusType.PENDING);
                    setAnchorInboxStatusFilter(null);
                  },
                },
                {
                  label: InboxStatusType.ASSIGNED,
                  onClick: () => {
                    setStatusFilter(InboxStatusType.ASSIGNED);
                    setAnchorInboxStatusFilter(null);
                  },
                },
                {
                  label: InboxStatusType.CLOSE,
                  onClick: () => {
                    setStatusFilter(InboxStatusType.CLOSE);
                    setAnchorInboxStatusFilter(null);
                  },
                },
              ]}
              children={
                <Box className={classes.filterTitleContainer}>
                  <Box>
                    <AppAssets.PortalInbox />
                  </Box>
                  <AppTypography
                    type={PDLTypography.smallHeading}
                    children={getLabelAndCount(statusFilter)}
                  />
                  <AppAssets.ChevronDown />
                </Box>
              }
            />
          </ButtonBase>
        </Grid>

        {/* Sort filter */}
        <Grid item className={classes.filterTitleContainer}>
          <ButtonBase>
            <AppMenu
              anchor={anchorSortFilter}
              onOpen={(event) => setAnchorSortFilter(event.currentTarget)}
              onClose={() => setAnchorSortFilter(null)}
              items={[
                {
                  label: SortType.DESC,
                  onClick: () => {
                    setSortFilter(SortType.DESC);
                    setAnchorSortFilter(null);
                  },
                },
                {
                  label: SortType.ASC,
                  onClick: () => {
                    setSortFilter(SortType.ASC);
                    setAnchorSortFilter(null);
                  },
                },
                {
                  label: SortType.LU,
                  onClick: () => {
                    setSortFilter(SortType.LU);
                    setAnchorSortFilter(null);
                  },
                },
              ]}
              children={
                <Box className={classes.filterTitleContainer}>
                  <AppTypography type={PDLTypography.smallHeading} children={sortFilter} />
                  <AppAssets.ChevronDown />
                </Box>
              }
            />
          </ButtonBase>
        </Grid>
      </Grid>

      {/* Filter for assigned items */}
      <Box className={classes.filterItemsContainer}>
        {(Object.keys(InboxAssignedType) as Array<keyof typeof InboxAssignedType>).map(
          (assigned, index) => (
            <Box key={`${assigned}-${index}`} onClick={() => setAssignedFilter(assigned)}>
              <AppTypography
                type={PDLTypography.callToAction}
                children={getAssignedTypeAndCount(InboxAssignedType[assigned])}
                customClasses={`${classes.filterItem} ${
                  assignedFilter === assigned ? classes.activeFilterItem : ''
                }`}
              />
            </Box>
          )
        )}
      </Box>

      {/* Dropdown for inbox types */}
      <Box className={classes.dropdownContainer}>
        <DropdownMenu
          handleOptionChange={(e) => setInboxTypeFilter(e as unknown as InboxType)}
          value={inboxTypeFilter ? (inboxTypeFilter as unknown as string) : ''}
          containerSpacing={0}
          selectContainerStyle={{ width: '90%' }}
          selectStyle={{ width: '100%' }}
          options={[{ title: 'All', value: 'ALL' }]}
        />

        {/* TODO: What is this? */}
        <ButtonBase>
          <AppAssets.PortalFilter />
        </ButtonBase>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.6em',
  },
  filterTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5em',
  },
  filterItemsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
  },
  filterItem: {
    fontSize: '.85em',
    cursor: 'pointer',
    color: '#01000E',
    opacity: 0.75,
  },
  activeFilterItem: {
    opacity: 1,
    color: '#2238CB',
  },
  dropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.1875em',
  },
}));
