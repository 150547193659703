import { makeStyles } from '@material-ui/core';
import { CSSProperties, useState } from 'react';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import ImageGallery from '../../external/pdl-common/components/commons/ImageGallery';
import { BikePartType } from '../../external/pdl-common/utils/enums/BikePartType';
import { PartDetail } from '../../external/pdl-common/model/PartDetail';
import { AppItem } from '../common/AppItem';
import { Grid } from '@mui/material';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import placeholder from '../../external/pdl-common/assets/img/pdl-placeholder.png';

interface Props {
  part: PartDetail;
  containerStyle?: CSSProperties;
}

const BikePartDetail = ({ part, containerStyle }: Props) => {
  const classes = useStyles();

  const [imageGalleryOpen, setImageGalleryOpen] = useState<boolean>(false);

  const getMeasurementLabel = (measurement: number) => {
    if (measurement === 0) {
      return '';
    }
    // Only the handlebar is measured in cm, all other parts are measured in mm.
    return `${measurement} ${part.type === BikePartType.handlebar ? 'cm' : 'mm'}`;
  };

  const getCategoryLabel = (category: string) => {
    var categoryAdditionalLabel = '';
    if (category === '') {
      return '';
    }
    if (part.type === BikePartType.handlebar) {
      categoryAdditionalLabel = 'cm';
    }
    if (part.type === BikePartType.crankset || part.type === BikePartType.stem) {
      categoryAdditionalLabel = 'mm';
    }
    return `${category} ${categoryAdditionalLabel}`;
  };

  const info = [
    part.brand,
    part.model,
    part.size || '',
    part.category ? getCategoryLabel(part.category) : '',
    part.additionalCategory || '',
    part.measurement ? getMeasurementLabel(part.measurement) : '',
    part.material || '',
    part.additionalInfo || '',
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <Grid container flexDirection="column" sx={muiStyles.grid} style={containerStyle}>
        <Grid item>
          <div
            className={classes.imgWithViewIcon}
            style={{ cursor: part.images.length > 0 ? 'pointer' : 'raw' }}
          >
            <div style={{ width: '12em', height: '12em' }}>
              <Carousel
                showIndexIndicator
                slidesToShow={{ lg: 1, md: 1, sm: 1, xs: 1 }}
                data={part.images ?? []}
                emptyComponent={
                  <AppImage
                    src={placeholder}
                  />
                } itemsToShow={1}
                renderItem={(img) => (
                  <AppImage
                    key={img.externalId}
                    src={img.url ? img.url : ''}
                    style={{
                      width: '12em',
                      height: '12em',
                      borderRadius: '0.8em',
                      objectFit: 'cover',
                      cursor: 'pointer',
                    }}
                  />
                )}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={7} mb="1em">
          <div className={classes.partInfo}>
            <AppItem title={part.type} value={`${info}`} />
          </div>
        </Grid>

      </Grid>
    </>
  );
};

export default BikePartDetail;

const muiStyles = {
  grid: {
    borderRadius: '0.8em',
    marginBottom: '0.5em',
    marginTop: '0.5em',
    marginRight: '2.5em',
  },
};

const useStyles = makeStyles({
  title: {
    textAlign: 'left',
    marginTop: 0,
    marginBottom: '0.5em',
    paddingRight: '0.2em',
  },
  partInfo: {
    textAlign: 'left',
    marginTop: '1em'
  },
  buttonBase: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: 'white',
  },
  imgWithViewIcon: {
    width: '12em',
    height: '12em',
    borderRadius: '0.8em',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
  },
});
