import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { debounce } from '../../utils/debounce';

export const DebounceButton = (props: ButtonProps & { 
  /**
   * Button label
   */
  text: string; 
  /**
   * Callback fired when the button is clicked
   */
  onClick: () => void; 
  /**
   * Seconds that must pass between each call
   */
  ms?: number 
}) => {
  return (
    <Button variant="text" color="inherit" {...props} onClick={debounce(props.onClick, props.ms || 1000)}>
      {props.text}
    </Button>
  );
};
