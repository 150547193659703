import { AppSimpleList } from '../../../external/pdl-common/components/commons/AppSimpleList';
import { Bike } from '../../../external/pdl-common/model/Bike';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';
import { InboxStatusType } from '../../../external/pdl-common/utils/InboxStatusType';
import { InboxItem } from './InboxItem';

interface Props {
  bikes: Bike[];
  onClick: (selectedBike: Bike) => void;
  currentBike?: Bike;
  loadMore: () => Promise<void>;
}

export const InboxBikeList = ({ bikes, onClick, currentBike, loadMore }: Props) => {
  const renderItem = (bike: Bike, index: number) => {
    return (
      <InboxItem
        key={`${bike.externalId}-${index}`}
        user={bike.owner.user}
        title={bike.title}
        subTitle={`${DateUtils.customFormat(new Date(bike.createdAt), 'd/M/yy')} - ${
          bike.location.geocode
        }`}
        onClick={() => onClick(bike)}
        isActive={currentBike?.externalId === bike.externalId}
        status={InboxStatusType.PENDING} // TODO: Remove hardcoded status
      />
    );
  };

  return (
    <AppSimpleList
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      data={bikes}
      loadMore={loadMore}
      renderItem={renderItem}
      topScroll
    />
  );
};
