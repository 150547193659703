import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme as createThemeV4 } from '@material-ui/core';
import { createTheme as createThemeV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV4 } from '@material-ui/core';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';

import AppContainer from './external/ensolvers-core-frontend-mui/components/Main/AppContainer';
import SnackbarComponent from './external/ensolvers-core-frontend-mui/components/Main/SnackbarComponent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import authService, {
  AuthEvents,
  LoginError,
} from './external/fox-typescript/services/AuthService';
import AppNavigation from './components/AppNavigation';
import { AppSidebar } from './components/Sidebar/AppSidebar';
import { Palette } from './components/Palette';
import { SidebarIcons, SidebarOptions } from './utils/SidebarUtils';
import { createGenerateClassName } from '@material-ui/core/styles';
import { StylesProvider } from '@mui/styles';

function App(): JSX.Element {
  const [isLogged, setLogged] = useState<boolean>(false);

  const themeV4 = createThemeV4({
    palette: {
      primary: {
        main: '#000000',
        dark: '#000000',
      },
      secondary: {
        main: '#FFFF00',
      },
      background: {
        default: Palette.background,
      },
    },
    typography: {
      fontFamily: 'Inter, Arial',
    },
  });

  const themeV5 = createThemeV5({
    palette: {
      primary: {
        main: themeV4.palette.primary.main,
        dark: themeV4.palette.primary.dark,
      },
      secondary: {
        main: themeV4.palette.secondary.main,
      },
      background: {
        default: themeV4.palette.background.default,
      },
    },
    typography: {
      fontFamily: themeV4.typography.fontFamily,
    },
  });

  useEffect(() => {
    authService.redirectUserIfNeededDependingOnLoginState();

    const error = authService.loginErrorJustHappened();

    if (error === LoginError.NOT_AUTHORIZED) {
      alert('You are not authorized');
    }
  });

  useEffect(() => {
    setLogged(authService.isLogged());
  }, [isLogged]);

  authService.subscribe(AuthEvents.LOGOUT, () => {
    setLogged(false);
  });

  const generateClassName = createGenerateClassName({
    disableGlobal: true,
    seed: 'pdl-mui',
  });

  return (
    <Router>
      {/* We wrapping this with StylesProvider in order to override production css class names with "pdl-mui" prefix to avoid classnames conflicts */}
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProviderV4 theme={themeV4}>
          <ThemeProviderV5 theme={themeV5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AppContainer
                customOptions={[]}
                isLogged={isLogged}
                staticIconsActions={SidebarIcons}
                hideTopBar
                backgroundColor={(theme) => theme.palette.background.default}
                customDrawer={<AppSidebar links={SidebarOptions} staticIconsActions={SidebarIcons} />}
                openDrawer
                containerSize={'xl'}
                mainStyle={{ paddingLeft: '3em', paddingRight: '3em' }}
              >
                <AppNavigation isLogged={isLogged} />
                <SnackbarComponent />
              </AppContainer>
            </MuiPickersUtilsProvider>
          </ThemeProviderV5>
        </ThemeProviderV4>
      </StylesProvider>
    </Router>
  );
}

export default App;
