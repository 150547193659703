import { makeStyles, Box, Divider } from '@material-ui/core';
import Reservation from '../../external/pdl-common/model/Reservation';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { AppItem } from '../common/AppItem';
import environment from '../../environment.json';

interface Props {
  reservation: Reservation;
}

const ReservationPayment = ({ reservation }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <AppItem
          title={'Total Cost'}
          value={`$${Math.round(reservation!.totalPrice * 100) / 100}`}
        />
        <AppItem title={'Paid on'} value={DateUtils.stringToShortDate(reservation?.createdAt!)} />
        <AppItem 
            title={'Payment Stripe Id'}
            value={reservation?.stripeChargeId!}  
            valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
            onValueClick={() => window.open(environment.stripeURL + 'payments/'+ reservation?.stripeChargeId!)}
        />

        {reservation!.receiptUrl && (
          <Box ml="3em">
            <AppItem
              title={'Receipt'}
              value={'View Receipt'}
              valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
              onValueClick={() => window.open(reservation!.receiptUrl, '_blank')}
            />
          </Box>
        )}

        {reservation!.refundReceiptUrl && (
          <Box ml="3em">
            <AppItem
              title={'Refund receipt'}
              value={'View Refund Receipt'}
              valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
              onValueClick={() => window.open(reservation.refundReceiptUrl, '_blank')}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReservationPayment;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '1em',
    paddingTop: '.3em',
    paddingBottom: '.3em',
  },
}));
