import { makeStyles, Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import { SimpleCellText } from '../../external/pdl-common/components/SimpleCellText';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import Reservation from '../../external/pdl-common/model/Reservation';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { Features } from '../../external/pdl-common/utils/enums/Features';
import { AppItem } from '../common/AppItem';

interface Props {
  reservation: Reservation;
}

const ReservationInsurance = ({ reservation }: Props) => {
  const classes = useStyles();
  const [isInuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);

  if (!isInuranceFeatureEnabled ) {
    return <></>
  } else {
    return reservation.renterInsurance ? (
      <>
        <Box className={classes.container}>
          <AppItem title={'Plan name'} value={reservation.renterInsurance?.planName!} />
          <AppItem title={'Price'} value={`$${reservation.renterInsurance?.price!}`} />
          <AppItem
            valueStyle={{ marginRight: '1em' }}
            title={'Effective start date'}
            value={DateUtils.formattedChatDateFromString(reservation.renterInsurance?.effectiveDate!)}
          />
          <AppItem
            title={'Effective end date'}
            value={DateUtils.formattedChatDateFromString(reservation.renterInsurance?.termEndDate!)}
          />
        </Box>
        <Box className={classes.container}>
          <AppItem
            title={'Protection Fee'}
            value={`$${reservation.renterInsurance?.coverages[0].premium!}`}
          />
          <AppItem
            title={'Maximum Refund'}
            value={`$${reservation.renterInsurance?.coverages[0].limitPrice!}`}
          />
          <AppItem
            title={'Customer Share'}
            value={`$${reservation.renterInsurance?.coverages[0].deductible!}`}
          />
        </Box>
      </>
    ) : (
      <>
        <Grid item mt={5}>
          <AppTypography type={PDLTypography.labelsSmallSerif}>No insurance</AppTypography>
        </Grid>
      </>
    );
  }
};

export default ReservationInsurance;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1em',
    paddingTop: '.3em',
    paddingBottom: '.3em',
  },
}));
