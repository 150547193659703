import { Box, makeStyles } from '@material-ui/core';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';

interface Props {
  text: string;
  onClick: () => void;
  badge?: number;
  isActive?: boolean;
}

export const InboxAction = ({ text, onClick, badge, isActive = false }: Props) => {
  const classes = useStyles();

  return (
    <Box
      onClick={onClick}
      className={classes.titleContainer}
      style={!isActive ? { opacity: '0.25' } : {}}
    >
      <AppTypography type={PDLTypography.portalSmallHeading}>
        <span style={{ fontSize: '1.3em' }}>{text}</span>
      </AppTypography>

      {badge && (
      <AppTypography type={PDLTypography.portalItem}>
        <Box className={classes.badgeContainer}>{badge}</Box>
      </AppTypography>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.375em',
    cursor: 'pointer',
  },
  badgeContainer: {
    backgroundColor: '#232FC6',
    borderRadius: '50%',
    color: '#FFFFFF',
    padding: '0.25em 0.375em 0.17em 0.375em',
    marginBottom: '0.15em',
  },
}));
