import ApiResponse from "../fox-typescript/core/ApiResponse";
import httpClient from "../fox-typescript/utils/HttpClient";

//TODO we need move this to fox and the backend side to core
class MainBarService {
  constructor(private basePath: string) {}

  read(): Promise<ApiResponse<any>> {
    return httpClient.get(this.basePath);
  }
}

export default new MainBarService("/ticketon/bar/");
