import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PagedResult from '../../../fox-typescript/core/PagedResult';
import { TableHead, TableSortLabel } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import useInterval from '../../hooks/useInterval';

const paginatorStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

interface TablePaginationActionsProps {
  /**
   * Total number of rows
   */
  count: number;
  /**
   * Actual page
   */
  page: number;
  /**
   * Number of rows per page
   */
  rowsPerPage: number;
  /**
   * Callback fired when a new page is requested.
   */
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = paginatorStyle();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const tableStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  tableHeader: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  none: {},
  paginationContainer: {
    '& .MuiTablePagination-spacer': {
      flex: 'none',
    },
  },
});

export interface ColumnSpec<T> {
  header: string;
  content: (instance: T) => JSX.Element;
  icon?: JSX.Element;
  orderBy?: string;
}

interface Props<T> {
  /**
   * Function that will be triggered every time that data has to be fetched
   * e.g. when the UI loads, when a page changes, etc.
   */
  fetchData: (page: number, rowsPerPage: number, sortBy?: string, sortDir?: string) => Promise<PagedResult<T>>;
  /**
   * This prop should be updated every time the list of T changed, thus triggering a potential update.
   */
  lastUpdateTimestamp: number;
  /**
   * Specs for each of the columns of the table, including header names, cell builders, etc. Null values are provided as a declarative way of
   * including/excluding columns in a functional way - for instance, doing something like (`user.isEmployee() ? {header: "Admission date", content: ...} : null`)
   */
  columns: (ColumnSpec<T> | null)[];
  /**
   * whether pagination section of the table will be rendered or not
   */
  enablePagination?: boolean;
  /**
   * Action to be executed when clicking on an element in the table
   */
  onElementClick?: (instance: T) => void;
  /**
   * Redefines the container for the table, overriding the existing one (a Paper)
   */
  tableContainer?: (props: { children: React.ReactNode }) => JSX.Element;
  /**
   * Style for table headers
   */
  stylesForHeader?: CSSProperties;
  /**
   * Style for "actions" header (specific header)
   */
  stylesForActionsHeader?: CSSProperties;
  /**
   * Apply styles to the row according to some attribute of the instance
   */
  stylesForRowIfCondition?: (instance: T) => CSSProperties | undefined;
  /**
   * Render only the rows that satisfy the given condition
   */
  renderRowIfCondition?: (instance: T) => boolean | undefined;
  /**
   * Number of page to show
   */
  page?: number;
  /**
   * Number of rows to show in a page
   */
  rowsPerPage?: number;
  /**
   * Time in millis indicating how often the data should be refreshed by querying the backend
   */
  refreshTime?: number;
  /**
   * If `true`, the rows that has no Actions the menu won't be shown.
   */
  hideEmptyActionMenu?: boolean;
  /**
   * Default order direction of the rows.
   * Default value is DESC
   */
  defaultSortDir?: OrderDirection;
  /**
   * Default property used for sorting
   */
  defaultSortBy?: string;
  /**
   * If `true`, the size of the table will be "small"
   */
  denseTable?: boolean;
  /**
   * Component to render if table data is empty.
   * Default value is "No data to display"
   */
  tableEmptyComponent?: JSX.Element;
}

export enum OrderDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export default function GenericTable<T>(props: Props<T>) {
  const classes = tableStyles();
  const [page, setPage] = useState(props.page || 0);
  const [rows, setRows] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage ?? 5);
  const [sortDir, setSortDir] = useState<OrderDirection>(props.defaultSortDir || OrderDirection.DESC);
  const [sortBy, setSortBy] = useState<string>(props.defaultSortBy || '');

  useInterval(() => {
    fetch();
  }, props.refreshTime ?? 50000);

  useEffect(() => {
    fetch();
  }, [page, rowsPerPage, props.lastUpdateTimestamp, sortBy, sortDir, props.fetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = () => {
    props.fetchData(page, rowsPerPage, sortBy, sortDir).then((result) => {
      setRows(result.content);
      setTotalCount(result.totalElements);
      // prevents the table from staying on an empty page when data is filtered
      if (page * rowsPerPage >= result.totalElements) {
        setPage(0);
      }
    });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPagination = props.enablePagination === undefined ? true : props.enablePagination;

  const columns = props.columns.filter((column) => !!column);

  const rowsPerPageOptions: number[] = props.rowsPerPage ? [5, 10, 25, props.rowsPerPage].sort((n1, n2) => n1 - n2) : [5, 10, 25];

  const handleSort = (index: number) => {
    const { orderBy } = props.columns[index]!!;
    const isAsc = sortBy === orderBy && sortDir === OrderDirection.ASC;
    !!orderBy && setSortBy(orderBy);
    !!orderBy && setSortDir(isAsc ? OrderDirection.DESC : OrderDirection.ASC);
  };

  const renderHeaderLabel = (header: string, icon?: JSX.Element) => {
    let customStyles =
      header === 'Actions' ? { ...props?.stylesForHeader, ...props.stylesForActionsHeader } : { ...props?.stylesForHeader };

    return (
      <span className={classes.tableHeader} style={customStyles}>
        {icon}
        {header}
      </span>
    );
  };

  const renderHeader = ({ orderBy, header, icon }: ColumnSpec<T>, index: number) => {
    const hasSort = typeof orderBy !== 'undefined';

    if (!hasSort) {
      return renderHeaderLabel(header, icon);
    }

    return (
      <TableSortLabel
        active={sortBy === orderBy}
        direction={sortBy === orderBy ? sortDir : OrderDirection.DESC}
        onClick={() => handleSort(index)}
      >
        {renderHeaderLabel(header, icon)}
      </TableSortLabel>
    );
  };

  const emptyComponent = props.tableEmptyComponent || <div style={{ textAlign: 'center' }}>No data to display</div>;

  return (
    <TableContainer component={props.tableContainer || Paper}>
      <Table className={classes.table} size={props.denseTable ? 'small' : undefined} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell key={index}>{renderHeader(column!, index)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows?.length && (
            <TableRow>
              <TableCell colSpan={columns?.length}>{emptyComponent}</TableCell>
            </TableRow>
          )}
          {rows?.map((row, index) => {
            if (!!props.renderRowIfCondition && !props.renderRowIfCondition(row)) return;
            return (
              <TableRow
                key={index}
                className={props.onElementClick ? classes.pointer : classes.none}
                onClick={() => {
                  if (props.onElementClick) props.onElementClick!(row);
                }}
                style={props.stylesForRowIfCondition && props.stylesForRowIfCondition(row)}
              >
                {columns?.map((column, index) => {
                  if (
                    props.hideEmptyActionMenu &&
                    column?.header === 'Actions' &&
                    column.content(row).props.entries.every((menuEntry: any) => menuEntry === false)
                  ) {
                    return <TableCell key={index}></TableCell>;
                  }

                  return <TableCell key={index}>{column!.content(row)}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>

        {renderPagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={props.columns?.length || 3}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                className={classes.paginationContainer}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export function SimpleTable<T>(props: {
  rows: T[];
  columns: (ColumnSpec<T> | null)[];
  stylesForHeader?: CSSProperties;
  tableContainer?: (props: { children: React.ReactNode }) => JSX.Element;
}) {
  return (
    <GenericTable<T>
      fetchData={() => Promise.resolve(PagedResult.fromArray(props.rows))}
      lastUpdateTimestamp={new Date().getTime()}
      enablePagination={false}
      columns={props.columns}
      stylesForHeader={props.stylesForHeader}
      tableContainer={props.tableContainer}
    />
  );
}
