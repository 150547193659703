import { useState, useEffect } from 'react';
import { ToggleItem } from '../../fox-typescript/core/ToggleItem';
import FeatureToggleService from '../../fox-typescript/services/FeatureToggleService';
import NotificationService from '../../fox-typescript/services/NotificationService';

export function useFeatureToggle(featureToggleList: ToggleItem[], feature: string): [boolean | undefined] {
  const [featureToggle, setFeatureToggle] = useState<ToggleItem | undefined>(undefined);

  useEffect(() => {
    getFeatureToggleItem();
  }, []);

  const getFeatureToggleItem = async () => {
    (await FeatureToggleService.getFeatureToggleItem(featureToggleList, feature))
      .onSuccess((response) => {
        setFeatureToggle(response.getContent());
      })
      .onError(() => {
        NotificationService.error(`Error trying to fetch ${feature} toggle`);
      });
  };

  return [featureToggle?.enabled];
}
