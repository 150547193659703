import { ButtonBase, CircularProgress, makeStyles } from '@material-ui/core';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';

interface Props {
  text: string;
  onClick: () => void;
  type?: 'contained' | 'outlined';
  disabled?: boolean;
  isLoading?: boolean;
}

export const AppButton = ({ text, onClick, type = 'contained', disabled, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <ButtonBase
      onClick={onClick}
      className={type === 'contained' ? classes.contained : classes.outlined}
      disabled={disabled}
    >
      <AppTypography
        type={PDLTypography.callToAction}
        children={text}
        customStyles={type === 'contained' ? { color: '#FFFFFF' } : {}}
      />
      {isLoading ? <CircularProgress color="inherit" size={20} style={{ color: 'white' }} /> : <></>}
    </ButtonBase>
  );
};

const useStyles = makeStyles((theme) => ({
  contained: {
    backgroundColor: '#232FC6',
    padding: '.6em',
    borderRadius: '0.6em',
    minWidth: '12em',
  },
  outlined: {
    borderRadius: '0.6em',
    color: '#FFFFFF',
    padding: '.6em',
    border: '1px solid #232FC6',
    minWidth: '100px',
  },
}));
