import { Grid } from '@mui/material';
import { AppItem } from '../common/AppItem';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import Logger from '../../external/pdl-common/utils/Logger';

interface Props {
  user: FullUser;
}

const logger = new Logger('UserMeasurement');

const UserMeasurement = ({ user }: Props) => {
  //TODO: get information from service and transform
  const shoeSizeType = ['', 'US Women', 'US Men', 'Euro Women', 'Euro Men', 'UK Women', 'UK Men'];
  //TODO: get information from service and transform
  const flexibilityType = [
    '',
    'Fingers touch kneecaps',
    'Fingers touch middle of shins',
    'Fingers touch toes',
    'Fingers touch ground',
    'Knuckles flat on ground',
    'Fingers flat on ground',
    'Palms flat on ground',
  ];
  return (
    <Grid container justifyContent={'space-around'} pt="1em" pb="1em">
      <AppItem title={'Height'} value={user.height ? `${user.height} (inches)` : ''} />
      <AppItem
        title={'Sternum Notch'}
        value={user.sternumNotch ? `${user.sternumNotch} (inches)` : ''}
      />
      <AppItem
        title={'Inseam Length'}
        value={user.inseamLength ? `${user.inseamLength} (inches)` : ''}
      />
      <AppItem title={'Arm Length'} value={user.armLength ? `${user.armLength} (inches)` : ''} />
      <AppItem
        title={'Flexibility'}
        value={user.flexibility ? `${user.flexibility} - ${flexibilityType[user.flexibility]}` : ''}
      />
      <AppItem title={'Weight'} value={user.weight ? `${user.weight} (pounds)` : ''} />
      <AppItem
        title={'Shoe Size'}
        value={user.shoeSize ? `${user.shoeSize} - ${shoeSizeType[user.shoeSizeType]}` : ''}
      />
    </Grid>
  );
};

export default UserMeasurement;
