import { Box, Divider, Grid } from '@mui/material';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';

interface Props {
  title?: string;
  component?: JSX.Element;
  hideDivider?: boolean;
}

export const SectionDetail = ({ title, component, hideDivider }: Props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Box width={'100%'}>
        {title && (
          <>
            <AppTypography type={PDLTypography.portalLargeHeading}>{title}</AppTypography>
            <Box style={{ width: '100%', margin: '1em 0 1em 0' }}>
              {!hideDivider && <Divider />}
            </Box>
          </>
        )}
        {component}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '2em',
    backgroundColor: COLORS.gray_F9F9F9,
    margin: '1em 1em 1em 0',
    padding: '2em 1.5em 1.5em 2em',
  },
}));
