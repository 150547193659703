import { ReactComponent as Bike } from './icons/bike.svg';
import { ReactComponent as BikesDashboard } from './icons/BikesDashboard.svg';
import { ReactComponent as Analytics } from './icons/Analytics.svg';
import { ReactComponent as Dashboard } from './icons/Dashboard.svg';
import { ReactComponent as Inbox } from './icons/Inbox.svg';
import { ReactComponent as Revenue } from './icons/Revenue.svg';
import { ReactComponent as Trips } from './icons/Trips.svg';
import { ReactComponent as Users } from './icons/Users.svg';
import { ReactComponent as LogOut } from './icons/LogOut.svg';
import { ReactComponent as PDLLogoSVG } from './icons/PDLLogoSVG.svg';
import { ReactComponent as ChevronDown } from './icons/ChevronDown.svg';
import { ReactComponent as PortalInbox } from './icons/inbox.svg';
import { ReactComponent as PortalFilter } from './icons/filter.svg';
import { ReactComponent as PortalEllipse } from './icons/ellipse.svg';
import { ReactComponent as PortalGreenEllipse } from './icons/GreenEllipse.svg';
import { ReactComponent as PortalOrangeEllipse } from './icons/OrangeEllipse.svg';
import { ReactComponent as PortalRedEllipse } from './icons/RedEllipse.svg';
import { ReactComponent as PortalCalendar } from './icons/calendar.svg';
import { ReactComponent as PortalSend } from './icons/send.svg';
import { ReactComponent as PortalImage } from './icons/image.svg';
import Utils from '@mui/icons-material/CloudSync';

export const AppAssets = {
  Bike,
  BikesDashboard,
  Analytics,
  Dashboard,
  Inbox,
  Revenue,
  Trips,
  Users,
  LogOut,
  PDLLogoSVG,
  ChevronDown,
  PortalInbox,
  PortalFilter,
  PortalEllipse,
  PortalGreenEllipse,
  PortalOrangeEllipse,
  PortalRedEllipse,
  PortalCalendar,
  PortalSend,
  PortalImage,
  Utils,
};

export const IMAGE_PLACEHOLDER = 'https://via.placeholder.com/400x400.png?text=Bike+image';
