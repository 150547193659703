import { IPage } from '../external/fox-typescript/core/IPage';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import environment from '../environment.json';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import Payment from '../external/pdl-common/model/Payment';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { PaymentType } from '../external/pdl-common/utils/enums/PaymentType';
import { PaymentPageRequestDTO } from '../models/PaymentPageRequestDTO';

class PaymentService extends GenericCrudService<Payment> {
  async getAllPayments(pageRequest: PaymentPageRequestDTO): Promise<PagedResult<Payment>> {
    const res = await HttpClient.getWithParams<IPage<Payment>>(
      `${this.basePath}/paginated`,
      pageRequest
    );
    return PagedResult.fromIPage(res.getContent());
  }

  async markAsPaid(externalId: string, type: PaymentType): Promise<ApiResponse<Payment>> {
    return await HttpClient.put(`${this.basePath}/${externalId}/${type}/mark-paid`, {}, true);
  }


  async deletePenalty(externalId: string): Promise<ApiResponse<Payment>> {
    return await HttpClient.delete(`${this.basePath}/penalty/${externalId}`);
  }

  async deletePayout(externalId: string): Promise<ApiResponse<Payment>> {
    return await HttpClient.delete(`${this.basePath}/payout/${externalId}`);
  }
}

export default new PaymentService(`${environment.baseURL}/portal/payment`);
