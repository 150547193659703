import { Grid, GridSpacing, InputBase } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { AppTypography } from '../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../external/pdl-common/components/Typography/PDLTypography';
import { AppFontFamily } from '../external/pdl-common/utils/AppFontFamily';
import { useDropdownMenuStyles } from './DropdownMenu';

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value: string;
  containerSpacing?: GridSpacing;
  inputContainerStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  placeholder?: string;
}

const PortalInput = (props: Props) => {
  const classes = useDropdownMenuStyles();

  return (
    <Grid container spacing={props.containerSpacing ?? 1}>
      <Grid item className={classes.label}>
        <AppTypography type={PDLTypography.portalLabel}>{props.label}</AppTypography>
      </Grid>
      <Grid item style={props.inputContainerStyle}>
        <InputBase
          value={props.value}
          onChange={props.handleChange}
          className={classes.selectContainer}
          style={{
            fontFamily: AppFontFamily.INTER,
            fontWeight: 600,
            fontSize: '0.85em',
            ...props.inputStyle,
          }}
          placeholder={props.placeholder}
        />
      </Grid>
    </Grid>
  );
};

export default PortalInput;
