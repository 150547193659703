import { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment } from '@material-ui/core';
import DropdownMenu from '../components/DropdownMenu';
import Panel from '../components/Panel';
import { Title } from '../components/Titles';
import TableBasedCrud from '../external/ensolvers-core-frontend-mui/components/TableBaseCrud';
import StringUtils from '../external/pdl-common/utils/StringUtils';
import { AppTopBar } from '../components/common/AppTopBar';
import AppImage from '../external/pdl-common/components/commons/AppImage';
import ReservationService from '../services/ReservationService';
import DateUtils from '../external/pdl-common/utils/DateUtils';
import RoundedImageWithText from '../external/pdl-common/components/RoundedImageWithText';
import PortalInput from '../components/PortalInput';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoutes } from '../utils/enums/AppRoutes';
import { ViewDetailState } from '../models/location-states/GenericViewDetailState';
import Reservation from '../external/pdl-common/model/Reservation';
import PdlAlertService from '../services/PdlAlertService';
import { RequestStatus } from '../external/pdl-common/utils/RequestStatus';
import {TextField, Typography} from '@mui/material';
import { CancelationParamsDTO } from '../models/CancelationParamsDTO';
import { GenericModal } from '../components/common/GenericModal';
import RefreshIcon from '@mui/icons-material/Refresh';

type Filters = {
  includeNotStarted: boolean;
  includeInProgress: boolean;
  includeCancelled: boolean;
  includeComplete: boolean;
};

interface Props {
  hideTopBar?: boolean;
  customFilters?: Filters;
}

export default function Home({ hideTopBar = false, customFilters }: Props) {
  const MAX_PENALTY = 50;

  const history = useHistory();
  let state: ViewDetailState = useLocation().state as ViewDetailState;

  const [filters, setFilters] = useState<Filters>({
    includeNotStarted: true,
    includeInProgress: true,
    includeCancelled: true,
    includeComplete: true,
  });
  const [selectedFilter, setSelectedFilter] = useState<string>(
    state?.selectedFilter || 'allReservations'
  );

  const [selectedReservation, setSelectedReservation] = useState<Reservation | undefined>(
    undefined
  );
  const [updateResultsOn, setUpdateResultsOn] = useState<number>(Date.now());
  const [searchFilter, setSearchFilter] = useState<string>(state?.searchFilter || '');
  const [pageNumber, setPageNumber] = useState<number>(state?.pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(state?.pageSize || 5);

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const [form, setForm] = useState<CancelationParamsDTO>({ refund: 0, payout: 0, penalty: 0 });

  useEffect(() => {
    handleFilterChange(selectedFilter);
  }, []);

  useEffect(() => {
    if (customFilters) {
      // @ts-ignore
      handleFilterChange(Object.keys(customFilters).find((key) => customFilters[key]));
    }
  }, [customFilters]);

  const handleFilterChange = (selectedOption: string) => {
    let updatedFilters = { ...filters };
    if (selectedOption === 'allReservations') {
      updatedFilters.includeNotStarted = true;
      updatedFilters.includeInProgress = true;
      updatedFilters.includeCancelled = true;
      updatedFilters.includeComplete = true;
    } else {
      updatedFilters.includeNotStarted = false;
      updatedFilters.includeInProgress = false;
      updatedFilters.includeCancelled = false;
      updatedFilters.includeComplete = false;
      (updatedFilters as any)[selectedOption] = true;
    }
    setFilters(updatedFilters);
    setUpdateResultsOn(Date.now());
    setSelectedFilter(selectedOption);
  };

  const handleOnDataFetch = (pageNumber: number, rowsPerPage: number) => {
    setPageNumber(pageNumber);
    setRowsPerPage(rowsPerPage);
    return ReservationService.getReservationsPage({
      page: pageNumber,
      pageSize: rowsPerPage,
      ...filters,
      searchFilter: searchFilter,
    });
  };

  const handleSearchFilter = (value: string) => {
    if (value.trim().length > 3 || value !== searchFilter) {
      setSearchFilter(value);
    }
  };

  const getFilters = () => {
    return (
      <>
        <DropdownMenu
          handleOptionChange={handleFilterChange}
          label={'Filter:'}
          value={selectedFilter}
          options={[
            {
              title: 'All Rides',
              value: 'allReservations',
            },
            {
              title: 'Not Started',
              value: 'includeNotStarted',
            },
            {
              title: 'In Progress',
              value: 'includeInProgress',
            },
            {
              title: 'Cancelled',
              value: 'includeCancelled',
            },
            {
              title: 'Complete',
              value: 'includeComplete',
            },
          ]}
        />
        <PortalInput
          inputStyle={{ width: '18em' }}
          label={'Search: '}
          value={searchFilter}
          handleChange={(val) => handleSearchFilter(val.target.value)}
          placeholder={'Bike or host/renter user name'}
        />
      </>
    );
  };

  const viewTripDetail = (reservation: Reservation) => {
    return (
      <>
        {history.push(`${AppRoutes.TRIP_DETAILS}/${reservation.externalId}`, {
          previousPage: AppRoutes.TRIPS,
          pageSize: rowsPerPage,
          pageNumber: pageNumber,
          selectedFilter: selectedFilter,
          searchFilter: searchFilter,
        })}
      </>
    );
  };
  const confirmTripcancellation = async (reservation: Reservation) => {
    setRequestStatus(RequestStatus.SUCCESS);
    setSelectedReservation(reservation);
    setForm({ refund: reservation.totalPrice, payout: 0, penalty: 0 });
    setOpenConfirmationModal(true);
  };

  const cancelTrip = async (reservation: Reservation) => {
    setOpenConfirmationModal(false);
    setRequestStatus(RequestStatus.LOADING);

    (await ReservationService.cancelReservation(reservation.externalId!, form))
      .onSuccess((response) => {
        setRequestStatus(RequestStatus.SUCCESS);
        PdlAlertService.showSuccessMessage(`Ride cancelled`);
        setUpdateResultsOn(Date.now());
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const handleInputChange = (value: string, input: 'refund' | 'payout' | 'penalty') => {
    const valueNumber = parseFloat(value);
    switch (input) {
      case 'refund':
        setForm({ ...form, refund: valueNumber });
        break;
      case 'payout':
        setForm({ ...form, payout: valueNumber });
        break;
      case 'penalty':
        setForm({ ...form, penalty: valueNumber });
        break;
    }
  };

  const invalidRefund = () => {
    if (!selectedReservation) {
      return false;
    }
    return form.refund < 0 || form.refund > selectedReservation!.totalPrice;
  };

  const invalidPenalty = () => {
    if (!selectedReservation) {
      return false;
    }
    return (
      form.penalty < 0 ||
      (form.penalty > 0 && form.penalty < 1) ||
      form.penalty > MAX_PENALTY ||
      (form.penalty && form.payout ? form.penalty != 0 && form.payout != 0 : false)
    );
  };

  const invalidPayout = () => {
    if (!selectedReservation) {
      return false;
    }
    return (
      form.payout < 0 ||
      form.payout > selectedReservation!.price - selectedReservation!.hostFee! ||
      (form.penalty && form.payout ? form.penalty != 0 && form.payout != 0 : false)
    );
  };

  const invalidModalParam = () => {
    return invalidRefund() || invalidPenalty() || invalidPayout();
  };

  const cancelTripModalContent = (reservation: Reservation) => {
    if (!reservation) {
      return <></>;
    }
    return (
      <Box style={{ width: '20em', height: '15em', textAlign: 'center' }}>
        <Box style={{ margin: '1em' }}>
          {reservation.bike.title} -{' '}
          {DateUtils.formattedRangeDates(
            new Date(reservation!.startDate),
            new Date(reservation!.endDate)
          )}
        </Box>
        <Box>
          <TextField
            label="Refund to rider"
            type="number"
            error={invalidRefund()}
            value={form.refund}
            onChange={(val) => handleInputChange(val.target.value, 'refund')}
            style={{ marginTop: '1em' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
              style: { width: '10em' },
              min: 0,
              max: selectedReservation?.totalPrice,
              step: '0.1',
              lang: 'en-US',
            }}
          />
        </Box>
        <Box style={{ margin: '1em' }}>
            *Default value corresponds to full refund
        </Box>
        {/*
        <Box>
          <TextField
            label="Payout to host"
            type="number"
            error={invalidPayout()}
            value={form.payout}
            onChange={(val) => handleInputChange(val.target.value, 'payout')}
            style={{ marginTop: '1em' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
              style: { width: '10em' },
              min: 0,
              max: selectedReservation?.totalPrice! - selectedReservation?.hostFee!,
              step: '0.1',
              lang: 'en-US',
            }}
          />
        </Box>
        <Box>
          <TextField
            label="Penalty to host"
            type="number"
            error={invalidPenalty()}
            value={form.penalty}
            onChange={(val) => handleInputChange(val.target.value, 'penalty')}
            style={{ marginTop: '1em' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
              style: { width: '10em' },
              min: 0,
              max: MAX_PENALTY,
              step: '0.1',
              lang: 'en-US',
            }}
          />
        </Box>
        */}
      </Box>
    );
  };

  return (
    <>
      {!hideTopBar &&
        <AppTopBar title={'Rides'} />}

      <Title>Rides</Title>
      <Box style={{ textAlign: 'center' }}>
        <IconButton
          onClick={() => handleFilterChange(selectedFilter)}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Panel>
        <TableBasedCrud
          elementName={'Reservation'}
          onDataFetch={handleOnDataFetch}
          page={state?.pageNumber}
          rowsPerPage={state?.pageSize}
          renderDialogContent={(register, errors, control) => <></>}
          tableContainer={(props) => <Box>{props.children}</Box>}
          forceUpdateOn={updateResultsOn}
          additionalToolbarButtons={getFilters}
          tableColumns={[
            {
              header: 'Bike',
              content: (reservation) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AppImage
                    src={
                      reservation.bike.images && reservation.bike.images.length !== 0
                        ? reservation.bike.images[0].url
                        : ''
                    }
                    style={{ width: 56, height: 56, borderRadius: 50 }}
                    alt={
                      reservation.bike.images && reservation.bike.images.length !== 0
                        ? reservation.bike.images[0].description
                        : undefined
                    }
                  />
                  <div style={{ marginLeft: 10 }}>
                    <div>{reservation.bike.title}</div>
                  </div>
                </div>
              ),
            },
            {
              header: 'Host',
              content: (reservation) => (
                <RoundedImageWithText
                  src={reservation.bike.owner.user.avatarUrl}
                  title={StringUtils.formatCompleteUserName(reservation.bike.owner.user)}
                />
              ),
            },
            {
              header: 'Renter',
              content: (reservation) => (
                <RoundedImageWithText
                  src={reservation.renter.user.avatarUrl}
                  title={StringUtils.formatCompleteUserName(reservation.renter.user)}
                />
              ),
            },
            {
              header: 'Ride',
              content: (reservation) => (
                <div>
                  {DateUtils.formattedRangeDates(
                    new Date(reservation.startDate),
                    new Date(reservation.endDate)
                  )}
                </div>
              ),
            },
            {
              header: 'Status',
              content: (reservation) => {
                if (reservation.cancelledAt) {
                  return <>Cancelled</>;
                }
                if (reservation.pickedUpAt && reservation.droppedOffAt) {
                  return <>Complete</>;
                }
                if (reservation.pickedUpAt && !reservation.droppedOffAt) {
                  return <>In Progress</>;
                }
                return <>Not Started</>;
              },
            },
            {
              header: 'Total',
              content: (reservation) => <>{Math.round(reservation.totalPrice * 100) / 100}</>,
            },
            {
              header: 'Pick-up',
              content: (reservation) => (
                <>
                  {' '}
                  {reservation.pickedUpAt
                    ? new Date(reservation.pickedUpAt).toLocaleDateString()
                    : ''}
                </>
              ),
            },
            {
              header: 'Drop-off',
              content: (reservation) => (
                <>
                  {' '}
                  {reservation.droppedOffAt
                    ? new Date(reservation.droppedOffAt).toLocaleDateString()
                    : ''}
                </>
              ),
            },
          ]}
          buildAdditionalActions={(reservation, refresh) => {
            var options: { title: string; action: () => void }[] = [];
            options.push({
              title: 'View detail',
              action: () => viewTripDetail(reservation),
            });
            if (!reservation.cancelledAt && !reservation.pickedUpAt) {
              options.push({
                title: 'Cancel trip',
                action: () => confirmTripcancellation(reservation),
              });
            }
            return options;
          }}
          isLoading={(reservation) =>
            requestStatus === RequestStatus.LOADING &&
            selectedReservation?.externalId === reservation.externalId
          }
        />
      </Panel>

      <GenericModal
        title={'Cancel ride'}
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        onConfirm={() => cancelTrip(selectedReservation!)}
        disabled={invalidModalParam()}
        children={cancelTripModalContent(selectedReservation!)}
      />
    </>
  );
}
