import { Box, Button, createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import GenericTable, { ColumnSpec, OrderDirection } from './Common/GenericTable';
import DropdownMenu from './Common/DropdownMenu';
import { Add } from '@material-ui/icons';
import PagedResult from '../../fox-typescript/core/PagedResult';
import Entity from '../../fox-typescript/core/Entity';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DetailListCrud, { CardItemSpec } from './DetailListCrud';
import { DialogPosition } from './Common/DialogPosition';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Spinner } from './Common/Spinner';
import { EditDialog } from './EditDialog';

export interface ActionsMenuEntry {
  title: string;
  action: () => void;
}

interface Props<T extends Entity> {
  /**
   * Title of the form
   */
  elementName: string;
  /**
   * Function that will be triggered every time that data has to be fetched
   * e.g. when the UI loads, when a page changes, etc.
   */
  onDataFetch: (page: number, rowsPerPage: number, sortBy?: string, sortDir?: string) => Promise<PagedResult<T>>;
  /**
   * Listener for instance updated events
   */
  onUpdate?: (instance: T) => void;
  /**
   * Listener for instance created events
   */
  onCreate?: (instance: T) => void;
  /**
   * Listener for instance deleted events
   */
  onDelete?: (instance: T) => void;
  /**
   * Function that will return the content of the form
   */
  renderDialogContent: (register: any, errors: any, control: any, instance?: T) => JSX.Element;
  /**
   * If `true`, the dialog changes to edit mode.
   */
  setIsEditModeActive?: (value: boolean) => void;
  /**
   * Configuration to specify for each column:
   *  - His header.
   *  - A function to render a row inside this column using the object instance.
   */
  tableColumns: (ColumnSpec<T> | null)[];
  /**
   * Whether pagination section of the table will be rendered or not. Default value is true.
   */
  enablePagination?: boolean;
  /**
   * Actions specified here will be added to the Actions menu
   */
  buildAdditionalActions?: (instance: T, refresh: () => void) => ActionsMenuEntry[];
  /**
   * Content specified here will be added to the toolbar
   */
  additionalToolbarButtons?: () => JSX.Element;
  /**
   * Define this prop if you need a custom way of building a fresh instance - e.g. to provid some sample values
   */
  instanceConstructor?: () => T;
  /**
   * Specifies a timestamp on which the data should be forced
   */
  forceUpdateOn?: number;
  /**
   * Action to be triggered when clicking on a table entry
   */
  onElementClick?: (instance: T) => void;
  /**
   * If `true`, the actions section in each column will be not rendered
   */
  hideActions?: boolean;
  /**
   * Function that must return the button used to create a new entity.
   * Default value is `defaultButtonBuilder('Add')`
   */
  addButtonBuilder: (onClick: () => void, style?: CSSProperties) => JSX.Element;
  /**
   * Redefines the container for the table, overriding the existing one (a Paper)
   */
  tableContainer?: (props: { children: React.ReactNode }) => JSX.Element;
  /**
   * if `true`, Items are shown as a List of Cards instead of showing items as Table.
   */
  showItemsAsCards?: boolean;
  /**
   * When `showItemsAsCards` is true.
   * Specifies the content of the cards as Header, Body and Footer.
   */
  cardItemSpec?: CardItemSpec<T>;
  /**
   * Element to be render instead of actions dropdown menu.
   */
  actionsElement?: JSX.Element;
  /**
   * Render a spinner if the instance is beign updated.
   */
  isLoading?: (instance: T) => boolean;
  /**
   * Position of the dialog in the window
   */
  dialogPosition: DialogPosition;
  /**
   * Apply styles to the row according to some attribute of the instance
   */
  stylesForRowIfCondition?: (instance: T) => CSSProperties | undefined;
  /**
   * Render only the rows that satisfy the given condition
   */
  renderRowIfCondition?: (instance: T) => boolean | undefined;
  /**
   * Number of page to show
   */
  page?: number;
  /**
   * Number of rows to show in a page
   */
  rowsPerPage?: number;
  /**
   * Styles for the button used to create a new entity.
   */
  stylesForButton?: CSSProperties;
  /**
   * Styles for the table header labels
   */
  stylesForHeader?: CSSProperties;
  /**
   * Styles for the button container (Paper)
   */
  buttonContainer?: CSSProperties;
  /**
   * Style for "actions" header (specific header)
   */
  stylesForActionHeader?: CSSProperties;
  /**
   * Listener for cancel events
   */
  onCancelDialog?: () => void;
  /**
   * Dialog property:
   * If validateBeforeClosing is true,
   * - form inputs wont be reset on submit until validated is set to true.
   * - onValidSave will be executed after save has been validated and validated is set to true.
   */
  validateBeforeClosing?: boolean;
  /**
   * Dialog property: Default values to set after a reset occurs (form submission)
   */
  resetValue?: {};
  /**
   * Time in millis indicating how often the data should be refreshed by querying the backend
   */
  refreshTime?: number;
  /**
   * Listener for click events coming from edit button
   */
  onClickEdit?: (instance: T) => void;
  /**
   * Dialog Property: Styles for the buttons of the dialog
   */
  buttonStyles: {};
  /**
   * If `true`, the rows that has no Actions the menu won't be shown.
   */
  hideEmptyActionMenu?: boolean;
  /**
   * Dialog property: classname of the styles for the form
   */
  dialogStyles?: string;
  /**
   * Default order direction of the rows.
   * Default value is DESC
   */
  defaultSortDir?: OrderDirection;
  /**
   * Default property used for sorting
   */
  defaultSortBy?: string;
  /**
   * If `true`, the size of the table will be "small"
   */
  denseTable?: boolean;
  /**
   * Component to render if table data is empty.
   * Default value is "No data to display"
   */
  tableEmptyComponent?: JSX.Element;
  /**
   * If true, creates a view option in the menu and take this
   * as callback function
   */
  onViewMode?: (instance: T) => void;
  /**
   * Styles for the container of the buttons in dialog
   */
  actionContainerStyles?: string;
  /**
   * Avoid closing the dialog when validating fields that are not controlled
   */
  additionalFormValidation?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    customPaper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
  })
);

const defaultButtonBuilder = (label: string) => {
  return (onClick: () => void, style?: CSSProperties) => (
    <Button type="submit" startIcon={<Add />} color="primary" onClick={onClick} style={style}>
      {label}
    </Button>
  );
};

TableBasedCrud.defaultProps = {
  addButtonBuilder: defaultButtonBuilder('Add'),
  dialogPosition: DialogPosition.Center,
  buttonStyles: {},
};

function TableBasedCrud<T extends Entity>(props: Props<T>) {
  const { t } = useTranslation();
  const classes = useStyles();

  // if `true`, the new item dialog will be open for editing/creation
  const [newItemDialogOpen, setNewInstanceDialogOpen] = useState(false);

  // if changed, this will notify that the content of the table
  // is potentially dirty, thus executing a new data request to the
  // backend
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(new Date().getTime());

  // current item being edited
  const [currentInstance, setCurrentInstance] = useState<T | undefined>(undefined);

  useEffect(() => {
    const newDate = new Date().getTime();

    // limit to 1 update per second
    if (newDate - lastUpdateTimestamp > 1000) {
      setLastUpdateTimestamp(newDate);
    }
  }, [props.forceUpdateOn]); // eslint-disable-line react-hooks/exhaustive-deps

  // set UI state for creating a new item
  const handleNew = () => {
    setCurrentInstance(props.instanceConstructor ? props.instanceConstructor() : undefined);
    setNewInstanceDialogOpen(true);
  };

  function buildDefaultActions(instance: T): (ActionsMenuEntry | undefined)[] {
    return [
      !props.onViewMode
        ? undefined
        : {
            title: 'View',
            action: async () => {
              await props.onViewMode!(instance);
            },
          },
      !props.onDelete
        ? undefined
        : {
            title: 'Delete',
            action: async () => {
              await props.onDelete!(instance);
              setLastUpdateTimestamp(new Date().getTime());
            },
          },
      !props.onUpdate
        ? undefined
        : {
            title: 'Edit',
            action: () => {
              setCurrentInstance(instance);
              setNewInstanceDialogOpen(true);

              props.setIsEditModeActive && props.setIsEditModeActive(true);
              props.onClickEdit && props.onClickEdit(instance);
            },
          },
      // filter null elements
    ].filter((menuItem) => !!menuItem);
  }

  function buildActions(instance: T) {
    return buildDefaultActions(instance).concat(
      (props.buildAdditionalActions && props.buildAdditionalActions(instance, () => setLastUpdateTimestamp(new Date().getTime()))) ||
        [].filter((menuItem) => !!menuItem)
    );
  }

  const columns = props.tableColumns.concat(
    props.hideActions
      ? []
      : [
          {
            header: t('Actions'),
            content: (instance) => {
              if (props.isLoading && props.isLoading(instance)) {
                return <Spinner style={{ color: 'grey' }} size={30} />;
              }

              return buildActions(instance).length !== 0 ? (
                <DropdownMenu containerStyles={props.stylesForActionHeader} entries={buildActions(instance)} />
              ) : (
                <></>
              );
            },
          },
        ]
  );

  const shouldRenderToolbar = !!props.additionalToolbarButtons || !!props.onCreate;

  const cardSpecs: CardItemSpec<T> | undefined = props.cardItemSpec;

  // we use useEffect to concat the Action Button only once, when the props.cardItemSpec changes
  // if we don't use useEffect, the Action Button appears multiple times, one time for every render
  useEffect(() => {
    if (cardSpecs && props.cardItemSpec?.header) {
      cardSpecs.header = props.cardItemSpec?.header.concat(
        props.hideActions
          ? []
          : [
              {
                content: (instance) =>
                  buildActions(instance).length !== 0 ? (
                    <DropdownMenu containerStyles={props.stylesForActionHeader} entries={buildActions(instance)} />
                  ) : (
                    <></>
                  ),
              },
            ]
      );
    }
  }, [props.cardItemSpec]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className={classes.root}>
        <EditDialog<T>
          newItemDialogOpen={newItemDialogOpen}
          dialogPosition={props.dialogPosition}
          currentInstance={currentInstance}
          elementName={props.elementName}
          onUpdate={props.onUpdate}
          onCreate={props.onCreate}
          setNewInstanceDialogOpen={setNewInstanceDialogOpen}
          setLastUpdateTimestamp={setLastUpdateTimestamp}
          setIsEditModeActive={props.setIsEditModeActive}
          renderDialogContent={props.renderDialogContent}
          onCancel={props.onCancelDialog}
          validateBeforeClosing={props.validateBeforeClosing}
          resetValue={props.resetValue}
          buttonStyles={props.buttonStyles}
          dialogStyles={props.dialogStyles}
          actionContainerStyles={props.actionContainerStyles}
          additionalFormValidation={props.additionalFormValidation}
        />

        <Grid container spacing={0}>
          {shouldRenderToolbar && (
            <Grid item xs={12}>
              <Paper className={props.buttonContainer ? classes.customPaper : classes.paper} style={props?.buttonContainer}>
                <Box display="flex" alignItems="flex-end">
                  {props.onCreate && props.addButtonBuilder(handleNew, props.stylesForButton)}
                  {props.additionalToolbarButtons && props.additionalToolbarButtons()}
                </Box>
              </Paper>
            </Grid>
          )}

          {props.showItemsAsCards ? (
            <Grid item xs={12}>
              <DetailListCrud<T>
                fetchData={props.onDataFetch}
                cardItemSpec={cardSpecs}
                lastUpdateTimestamp={lastUpdateTimestamp}
                listEmptyComponent={props.tableEmptyComponent}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <GenericTable<T>
                fetchData={props.onDataFetch}
                lastUpdateTimestamp={lastUpdateTimestamp}
                columns={columns}
                enablePagination={props.enablePagination}
                onElementClick={props.onElementClick}
                tableContainer={props.tableContainer}
                stylesForRowIfCondition={props.stylesForRowIfCondition}
                renderRowIfCondition={props.renderRowIfCondition}
                refreshTime={props.refreshTime}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                stylesForHeader={props.stylesForHeader}
                hideEmptyActionMenu={props.hideEmptyActionMenu}
                defaultSortBy={props.defaultSortBy}
                defaultSortDir={props.defaultSortDir}
                denseTable={props.denseTable}
                stylesForActionsHeader={props.stylesForActionHeader}
                tableEmptyComponent={props.tableEmptyComponent}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default TableBasedCrud;
