import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export const Accordion = styled((props: AccordionProps) => <MuiAccordion defaultExpanded disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '&:before': {
      display: 'none',
    },
  })
);

export const AccordionSummary = styled((props: { title: string; icon: JSX.Element; closable?: boolean }) => {
  const { title, icon, closable = true } = props;
  return (
    <MuiAccordionSummary
      sx={closable ? undefined : { cursor: 'default !important' }}
      expandIcon={closable ? <ExpandMoreIcon sx={{ fontSize: '1.5rem' }} /> : undefined}
      {...props}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MuiAccordionSummary>
  );
})(({ theme }) => ({
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiAccordionSummary-content': {
    marginRight: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));
