import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BackButton } from '../components/BackButton';
import { AppTopBar } from '../components/common/AppTopBar';
import { RequestStatus } from '../external/pdl-common/utils/RequestStatus';
import PdlAlertService from '../services/PdlAlertService';
import userService from '../services/UserService';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../external/pdl-common/utils/AppTheme';
import { UserDetails } from '../components/Bike/UserDetails';
import UserMeasurement from '../components/User/UserMeasurements';
import { FullUser } from '../external/pdl-common/model/FullUser';
import { ViewDetailState } from '../models/location-states/GenericViewDetailState';
import { SectionDetail } from '../components/common/SectionDetail';
import UserStripeInformation from '../components/User/UserStripeInformation';
import StripeService from '../services/StripeService';
import UserReviews from '../components/User/UserReviews';

export function PdlUserOverview() {
  const classes = useStyles();

  const history = useHistory();
  const state = useLocation().state as ViewDetailState;

  const { externalId } = useParams<{ externalId: string }>();
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [user, setUser] = useState<FullUser | undefined>(undefined);
  const [last4card, setLast4Card] = useState<string>('');

  useEffect(() => {
    if (externalId) {
      loadUser();
    }
  }, [externalId]);

  useEffect(() => {
    if (user?.stripeCustomerId) {
      loadCreditCardLast4Digits();
    }
  }, [user]);


  const loadUser = async () => {
    setRequestStatus(RequestStatus.LOADING);
    (await userService.getByExternalId(externalId))
      .onSuccess((response) => {
        setUser(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };
  const loadCreditCardLast4Digits = async () => {
    setRequestStatus(RequestStatus.LOADING);
    (await StripeService.getUserCreditCard(externalId))
      .onSuccess((response) => {
        setLast4Card(response.getContent().toString());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  }

  return requestStatus === RequestStatus.ERROR ? (
    <></>
  ) : (
    <>
      <AppTopBar title={'Users'} />

      {/* Back button */}
      <BackButton
        title={'Back to all users'}
        onClick={() => history.push(`${state.previousPage}`, { ...state })}
      />

      {user && (
        <>
          <SectionDetail title="Personal Information" component={<UserDetails user={user} />} />

          <SectionDetail title="Stripe Information" component={<UserStripeInformation user={user} last4CreditCardNumber={last4card} />} />

          <SectionDetail title="Measurements" component={<UserMeasurement user={user} />} />

          {/*<SectionDetail title="Reviews" component={<UserReviews user={user} />} />*/}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  userContainer: {
    borderRadius: '2em',
    backgroundColor: COLORS.gray_F9F9F9,
    marginTop: '.8em',
    paddingLeft: '1.5em',
    paddingTop: '2em',
  },
  measurementContainer: {
    borderRadius: '2em',
    backgroundColor: COLORS.gray_F9F9F9,
    marginTop: '.8em',
    maxWidth: '76em',
  },
}));
