import { ItemType } from '../external/ensolvers-core-frontend-mui/components/Main/AppContainer';
import { AppAssets } from '../assets/index';
import { SidebarTabs } from './SidebarTabs';
import { AppRoutes } from './enums/AppRoutes';
import PdlCommandAction from './PdlCommandAction';
import environment from '../environment.json';

export const SidebarOptions: PdlCommandAction[] = [
  {
    key: '1',
    iconKey: 'dashboardIcon',
    actionPath: AppRoutes.DASHBOARD,
    label: SidebarTabs.DASHBOARD,
    tooltip: '',
    roles: ['ROLE_ADMIN'],
  },
  {
    key: '2',
    iconKey: 'userIcon',
    actionPath: AppRoutes.USERS,
    label: SidebarTabs.USERS,
    tooltip: '',
    roles: ['ROLE_ADMIN'],
  },
  {
    key: '3',
    iconKey: 'revenueIcon',
    actionPath: AppRoutes.REVENUE,
    label: SidebarTabs.REVENUE,
    tooltip: '',
    roles: ['ROLE_ADMIN'],
  },
  {
    key: '4',
    iconKey: 'inboxIcon',
    actionPath: AppRoutes.INBOX,
    label: SidebarTabs.INBOX,
    tooltip: '',
    roles: ['ROLE_ADMIN'],
  },
  {
    key: '5',
    iconKey: 'analyticsIcon',
    actionPath: '/analytics',
    label: SidebarTabs.ANALYTICS,
    tooltip: '',
    roles: ['ROLE_ADMIN'],
  },
  {
    key: '6',
    iconKey: 'bikeIcon',
    actionPath: AppRoutes.BIKES,
    label: SidebarTabs.BIKES,
    tooltip: '',
    roles: ['ROLE_ADMIN'],
  },
  {
    key: '7',
    iconKey: 'tripIcon',
    actionPath: AppRoutes.TRIPS,
    label: SidebarTabs.TRIPS,
    tooltip: '',
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_SUPPORT'],
  },
  {
    key: '8',
    iconKey: 'revenueIcon',
    actionPath: AppRoutes.PAYOUTS,
    label: SidebarTabs.PAYOUTS,
    tooltip: '',
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_SUPPORT'],
  },
  {
    key: '9',
    iconKey: 'utilsIcon',
    actionPath: AppRoutes.UTILS,
    label: SidebarTabs.UTILS,
    tooltip: '',
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_SUPPORT'],
  },
  {
    key: '10',
    iconKey: 'inboxIcon',
    actionPath: AppRoutes.CHAT,
    label: SidebarTabs.CHAT,
    tooltip: '',
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_SUPPORT'],
  },
];

export const SidebarIcons: ItemType = {
  userIcon: <AppAssets.Users />,
  bikeIcon: <AppAssets.BikesDashboard />,
  inboxIcon: <AppAssets.Inbox />,
  dashboardIcon: <AppAssets.Dashboard />,
  analyticsIcon: <AppAssets.Analytics />,
  revenueIcon: <AppAssets.Revenue />,
  tripIcon: <AppAssets.Trips />,
  utilsIcon: <AppAssets.Utils sx={{ fill: 'gray', fontSize: 'large', marginLeft: '-0.2em' }} />,
};
