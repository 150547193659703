import { Box, Checkbox, IconButton } from '@material-ui/core';
import { Chip, SxProps } from '@mui/material';
import { useState } from 'react';
import DropdownMenu from '../DropdownMenu';
import Panel from '../Panel';
import { Title } from '../Titles';
import TableBasedCrud from '../../external/ensolvers-core-frontend-mui/components/TableBaseCrud';
import RoundedImageWithText from '../../external/pdl-common/components/RoundedImageWithText';
import Payment from '../../external/pdl-common/model/Payment';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import StringUtils from '../../external/pdl-common/utils/StringUtils';
import PaymentService from '../../services/PaymentService';
import PdlAlertService from '../../services/PdlAlertService';
import { PaymentStatusFilter, PaymentTypeFilter } from './utils/PayoutFilters';
import PortalInput from '../PortalInput';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import { PaymentType } from '../../external/pdl-common/utils/enums/PaymentType';
import { AppTopBar } from '../common/AppTopBar';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';
import { AppItem } from '../common/AppItem';
import environment from '../../environment.json'
import { ConfirmationModal } from '../common/ConfirmationModal';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import ReportIssueService from '../../services/ReportIssueService';
import { GenericModal } from '../common/GenericModal';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../external/pdl-common/utils/enums/Features';

interface InstanceStatus {
  requestStatus: RequestStatus | undefined;
  instance: Payment | undefined;
}

interface ConfirmationModal {
  title: string | undefined;
  message: string | undefined;
  apply: boolean;
}


const Payouts = () => {
  const [updateResultsOn, setUpdateResultsOn] = useState<number>(Date.now());
  const [typeFilter, setTypeFilter] = useState<PaymentTypeFilter>(PaymentTypeFilter.ALL);
  const [statusFilter, setStatusFilter] = useState<PaymentStatusFilter>(PaymentStatusFilter.ALL);
  const [userNameOrEmailFilter, setUserNameOrEmailFilter] = useState<string>('');
  const [instanceStatus, setInstanceStatus] = useState<InstanceStatus>({
    requestStatus: undefined,
    instance: undefined,
  });
  const [selectedPayout, setSelectedPayout] = useState<Payment | undefined>(undefined);
  const [openRenterIssues, setOpenRenterIssues] = useState<number | undefined>(undefined);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>({
    title: '',
    message: '',
    apply: false
  });
  const [isInuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);

  const handleOnDataFetch = (pageNumber: number, rowsPerPage: number) => {
    return PaymentService.getAllPayments({
      page: pageNumber,
      pageSize: rowsPerPage,
      typeFilter,
      statusFilter,
      nameOrEmailFilter: userNameOrEmailFilter,
    });
  };

  const confirmationNeeded = async (payment: Payment) => {
    setInstanceStatus({ requestStatus: RequestStatus.LOADING, instance: payment });

    const response = await ReportIssueService.checkOpenRenterIssuesByReservationId(payment.reservation.externalId!);
    response
      .onSuccess((response) => {
        setInstanceStatus({ ...instanceStatus, requestStatus: RequestStatus.SUCCESS });
        if (response.getContent() > 0) {
          setSelectedPayout(payment);
          setOpenConfirmationModal(true);
          setConfirmationModal({
            title: 'Payout confirmation',
            message: 'Rider reported an issue on this reservation that is still open. Are you sure you want to pay to host?',
            apply: true
          });
        } else {
          markAsPaid(payment);
        }
      })
      .onError((response) => {
        setInstanceStatus({ requestStatus: RequestStatus.ERROR, instance: undefined });
        PdlAlertService.showSnackCustomError(response.getContent());
      });

  }
  const markAsPaid = async (payment: Payment) => {
    setInstanceStatus({ requestStatus: RequestStatus.LOADING, instance: payment });

    const response = await PaymentService.markAsPaid(payment.externalId!, payment.type);

    response
      .onSuccess((response) => {
        setInstanceStatus({ ...instanceStatus, requestStatus: RequestStatus.SUCCESS });

        PdlAlertService.showSuccessMessage(
          payment.type === PaymentType.PENALTY ? `Penalty applied` : `Payout applied`
        );
        setUpdateResultsOn(Date.now());
      })
      .onError((response) => {
        setInstanceStatus({ requestStatus: RequestStatus.ERROR, instance: undefined });

        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const deletePayment = async (payment: Payment) => {
    setInstanceStatus({ requestStatus: RequestStatus.LOADING, instance: payment });

    const response = payment.type === PaymentType.PENALTY ? await PaymentService.deletePenalty(payment.externalId!) :
      await PaymentService.deletePayout(payment.externalId!)

    response
      .onSuccess((response) => {
        setInstanceStatus({ ...instanceStatus, requestStatus: RequestStatus.SUCCESS });

        PdlAlertService.showSuccessMessage(
          payment.type === PaymentType.PENALTY ? `Penalty deleted` : `Payout deleted`
        );
        setUpdateResultsOn(Date.now());
      })
      .onError((response) => {
        setInstanceStatus({ requestStatus: RequestStatus.ERROR, instance: undefined });

        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const confirmDeletePayment = async (payment: Payment) => {
    setSelectedPayout(payment);
    setOpenConfirmationModal(true);
    setConfirmationModal({
      title: 'Delete confirmation',
      message: 'Are you sure of deleting ' + ((payment.type === PaymentType.PENALTY) ? 'penalty?' : 'payout?'),
      apply: false
    });
  }
  const getEarnings = (payment: Payment): number | undefined => {
    const { revenue = 0, payoutFee = 0, insuranceFee = 0 } = payment;

    if (payment.type === PaymentType.PAYOUT) {
      return Math.round((revenue - payoutFee) * 100) / 100;
    }
  };

  const getPriceChip = (sx: SxProps, value?: number): JSX.Element => {
    if (value) {
      return (
        <Box display="flex" justifyContent={'center'}>
          <Chip label={StringUtils.formatPrice(value)} sx={sx} />
        </Box>
      );
    }

    return <></>;
  };

  const handleTypeFilterChange = (selectedOption: string) => {
    setTypeFilter(selectedOption as PaymentTypeFilter);
    setUpdateResultsOn(Date.now());
  };

  const handleStatusFilterChange = (selectedOption: string) => {
    setStatusFilter(selectedOption as PaymentStatusFilter);
    setUpdateResultsOn(Date.now());
  };

  const handleHostNameFilterChange = (value: string) => {
    if (value.trim().length > 3 || value !== userNameOrEmailFilter) {
      setUserNameOrEmailFilter(value);
      setUpdateResultsOn(Date.now());
    }
  };

  const getFilters = () => {
    return (
      <>
        {/* Type filter */}
        <DropdownMenu
          handleOptionChange={handleTypeFilterChange}
          label={'Filter by type:'}
          value={typeFilter}
          options={[
            {
              title: 'All',
              value: PaymentTypeFilter.ALL,
            },
            {
              title: 'Payouts',
              value: PaymentTypeFilter.ONLY_PAYOUTS,
            },
            {
              title: 'Penalties',
              value: PaymentTypeFilter.ONLY_PENALTIES,
            },
          ]}
        />

        {/* Status filter */}
        <DropdownMenu
          handleOptionChange={handleStatusFilterChange}
          label={'Filter by status:'}
          value={statusFilter}
          options={[
            {
              title: 'All',
              value: PaymentStatusFilter.ALL,
            },
            {
              title: 'Paid/Charged',
              value: PaymentStatusFilter.ONLY_PAID,
            },
            {
              title: 'Unpaid/Unharged',
              value: PaymentStatusFilter.ONLY_UNPAID,
            },
          ]}
        />

        {/* Host name filter */}
        <PortalInput
          label={'Search'}
          value={userNameOrEmailFilter}
          handleChange={(val) => handleHostNameFilterChange(val.target.value)}
          placeholder={'User name or email'}
        />
      </>
    );
  };

  return (
    <>
      <AppTopBar title={'Payouts'} />
      <Title>Payouts</Title>
      <Box style={{ textAlign: 'center' }}>
        <IconButton
          onClick={() => setUpdateResultsOn(Date.now())}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Panel>
        <TableBasedCrud
          elementName={'Payouts'}
          onDataFetch={handleOnDataFetch}
          renderDialogContent={(register, errors, control) => <></>}
          tableContainer={(props) => <Box>{props.children}</Box>}
          forceUpdateOn={updateResultsOn}
          additionalToolbarButtons={getFilters}
          tableColumns={[
            {
              header: 'Type',
              content: (payment: Payment) => (
                <Chip label={StringUtils.capitalizeText(payment.type)} sx={ChipSx.yellow} />
              ),
            },
            {
              header: 'Host',
              content: (payment: Payment) => (
                <RoundedImageWithText
                  src={payment.reservation.bike.owner.user.avatarUrl}
                  title={StringUtils.formatCompleteUserName(payment.reservation.bike.owner.user)}
                />
              ),
            },
            {
              header: 'Email',
              content: (payment: Payment) => <div>{payment.reservation.bike.owner.user.email}</div>,
            },
            {
              header: 'Bike',
              content: (payment: Payment) => <div>{payment.reservation.bike.title}</div>,
            },
            {
              header: 'Bike price',
              content: (payment: Payment) => (
                <div>{`${StringUtils.formatPrice(Math.round(payment.reservation.price /
                  DateUtils.getFullDaysBetweenDates(new Date(payment.reservation.startDate), new Date(payment.reservation.endDate)) * 100) / 100)} / day`}</div>
              ),
            },
            {
              header: 'Ride',
              content: (payment: Payment) => (
                <div>
                  {DateUtils.formattedRangeDates(
                    new Date(payment.reservation.startDate),
                    new Date(payment.reservation.endDate)
                  )}
                </div>
              ),
            },
            {
              header: 'Revenue',
              content: (payment: Payment) => getPriceChip(ChipSx.green, payment.revenue),
            },
            {
              header: 'Fee',
              content: (payment: Payment) => getPriceChip(ChipSx.red, payment.payoutFee! < 0 ? 0 : payment.payoutFee! - payment.insuranceFee!),
            },
            {
              header: 'Insurance',
              content: (payment: Payment) => getPriceChip(ChipSx.red, payment.payoutFee! < 0 ? 0 : payment.type === PaymentType.PAYOUT ? payment.insuranceFee! : 0),
            },
            {
              header: 'Earnings',
              content: (payment: Payment) => getPriceChip(ChipSx.green, getEarnings(payment)),
            },
            {
              header: 'Penalty',
              content: (payment: Payment) => getPriceChip(ChipSx.red, payment.penaltyFee),
            },
            {
              header: 'Paid / Charged',
              content: (payment: Payment) => <Checkbox checked={payment.paid} disabled />,
            },
            {
              header: 'Stripe',
              content: (payment: Payment) => (
                <AppItem
                  title={''}
                  value={payment.paid ? payment.type === PaymentType.PENALTY ? 'Payment' : 'Transfer' : ''}
                  valueStyle={{ color: COLORS.persianBlue_2238CB, cursor: 'pointer', fontSize: '1em' }}
                  onValueClick={() => window.open(environment.stripeURL +
                    (payment.type === PaymentType.PENALTY ? 'payments/' : 'connect/transfers/')
                    + payment.stripePaymentId)}
                />
              ),
            },

          ].filter(column => (isInuranceFeatureEnabled || column.header !== 'Insurance'))}
          buildAdditionalActions={(payment, refresh) => {
            return !payment.paid
              ? [
                {
                  title: `${payment.type === PaymentType.PENALTY ? 'Apply Penalty' : 'Apply Payout'
                    }`,
                  action: () => payment.type === PaymentType.PAYOUT ? confirmationNeeded(payment) : markAsPaid(payment),
                },
                {
                  title: `${payment.type === PaymentType.PENALTY ? 'Delete Penalty' : 'Delete Payout'
                    }`,
                  action: () => confirmDeletePayment(payment),
                },
              ]
              : [];
          }}
          isLoading={(payment) =>
            instanceStatus.requestStatus === RequestStatus.LOADING &&
            instanceStatus.instance?.externalId === payment.externalId
          }
        />
      </Panel>
      <GenericModal
        title={confirmationModal.title!}
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
        onConfirm={() => {
          confirmationModal.apply ? markAsPaid(selectedPayout!) : deletePayment(selectedPayout!)
          setOpenConfirmationModal(false);
        }
        }
        children={
          <Box style={{ width: '25em', paddingTop: '1em' }}>
            <AppTypography
              type={PDLTypography.none}
              children={confirmationModal.message}
            />
          </Box>
        }
      />
    </>
  );
};

export default Payouts;

const ChipSx = {
  red: {
    backgroundColor: '#F4DDE0',
    color: '#981B1B',
  } as SxProps,
  green: {
    backgroundColor: '#E0F4E5',
    color: '#076046',
  } as SxProps,
  yellow: {
    backgroundColor: '#F8EBCB',
    color: '#654D0F',
  },
};
