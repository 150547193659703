import { SnackBarData } from '../external/ensolvers-core-frontend-mui/types/SnackbarData';

class PdlAlertService {
  showSnackInternalError() {
    const snackData: SnackBarData = {
      text: 'An internal error occurred, please try again later',
      severity: 'error',
    };
    const customEvent = new CustomEvent('snackMessage', { detail: { snackData } });
    document.dispatchEvent(customEvent);
  }

  showSnackCustomError(message: string): void {
    const snackData: SnackBarData = {
      text: message,
      severity: 'error',
    };
    const customEvent = new CustomEvent('snackMessage', { detail: { snackData } });
    document.dispatchEvent(customEvent);
  }

  showSuccessMessage(message: string): void {
    const snackData: SnackBarData = {
      text: message,
      severity: 'success',
    };
    const customEvent = new CustomEvent('snackMessage', { detail: { snackData } });
    document.dispatchEvent(customEvent);
  }

  showInfoMessage(message: string): void {
    const snackData: SnackBarData = {
      text: message,
      severity: 'warning',
    };
    const customEvent = new CustomEvent('snackMessage', { detail: { snackData } });
    document.dispatchEvent(customEvent);
  }
}

export default new PdlAlertService();
