import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { AppButton } from '../components/common/AppButton';
import { AppTopBar } from '../components/common/AppTopBar';
import AppTheme from '../external/pdl-common/utils/AppTheme';
import { RequestStatus } from '../external/pdl-common/utils/RequestStatus';
import ContentService from '../services/ContentService';
import PdlAlertService from '../services/PdlAlertService';

export const Utils = () => {
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  const synchronizeContent = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await ContentService.synchronizeContent())
      .onSuccess((response) => {
        PdlAlertService.showSuccessMessage(response.getContent() as string);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  return (
    <>
      <AppTopBar title={'Utils'} />

      <Grid container className={classes.container}>
        <Grid className={classes.content}>
          <AppButton
            text={(requestStatus !== RequestStatus.LOADING) ? "Synchronize content" : ""}
            type={'contained'}
            onClick={() => synchronizeContent()}
            disabled={requestStatus === RequestStatus.LOADING}
            isLoading={requestStatus === RequestStatus.LOADING}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F9F9F9',
    height: '85vh',
    borderRadius: '2em',
    padding: '1.25em 0 1.875em 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '1.8em',
  },
}));
