import { Route, Switch } from 'react-router-dom';
import Login from '../external/ensolvers-core-frontend-mui/components/Main/Login';
import pdlLogo from '../pdl-logo.png';
import background from '../background.jpg';
import Users from '../views/Users';
import Bikes from '../views/Bikes';
import { Dashboard } from '../views/Dashboard';
import { ToBeImplementedView } from '../views/ToBeImplementedView';
import { Inbox } from './Inbox/Inbox';
import Payouts from './Payout/Payouts';
import { Utils } from '../views/Utils';
import Trips from '../views/Trips';
import environment from '../environment.json';
import { AppRoutes } from '../utils/enums/AppRoutes';
import BikeOverview from './Bike/BikeOverview';
import { TripOverview } from '../views/TripOverview';
import { PdlUserOverview } from '../views/PdlUserOverview';
import { DirectMessages } from '../views/DirectMessages';

interface Props {
  isLogged: boolean;
}

export default function AppNavigation(props: Props) {
  return (
    <Switch>
      <>
        <Route exact path="/">
          <Login
            register={false}
            loginWithCode={false}
            topic={'bike'}
            allowToRegister={false}
            imageLogo={pdlLogo}
            showTitle={false}
            onLoginSuccess={() => (window.location.href = '/home')}
            backgroundImage={background}
            customStyle={{ height: '86vh' }}
            allowedRoles={['ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_SUPERADMIN']}
            convertEmailToLowercase={true}
          />
        </Route>

        <Route path={AppRoutes.DASHBOARD}>
          <Dashboard />
        </Route>

        <Route path={AppRoutes.USERS}>
          <Users />
        </Route>

        <Route path={AppRoutes.BIKES}>
          <Bikes />
        </Route>

        <Route exact path={`${AppRoutes.BIKE_DETAILS}/:externalId`}>
          <BikeOverview />
        </Route>

        <Route path={AppRoutes.REVENUE}>
          <ToBeImplementedView />
        </Route>

        <Route path={AppRoutes.INBOX}>
          <Inbox />
        </Route>

        <Route path={AppRoutes.CHAT}>
          <DirectMessages />
        </Route>

        <Route path={AppRoutes.ANALYTICS}>
          <ToBeImplementedView />
        </Route>

        <Route path={AppRoutes.TRIPS}>
          <Trips />
        </Route>

        <Route exact path={[`${AppRoutes.TRIP_DETAILS}/:externalId`]}>
          <TripOverview />
        </Route>

        <Route exact path={[`${AppRoutes.USER_DETAILS}/:externalId`]}>
          <PdlUserOverview />
        </Route>

        <Route path={AppRoutes.PAYOUTS}>
          <Payouts />
        </Route>

        <Route path={AppRoutes.UTILS}>
          <Utils />
        </Route>
      </>
    </Switch>
  );
}
