import ApiResponse from '../core/ApiResponse';
import { HttpErrors } from '../core/HttpErrors';
import { ResponseData } from '../core/ResponseData';
import { ToggleItem } from '../core/ToggleItem';

class FeatureToggleService {
  getFeatureToggleItem = async (featureToggleList: ToggleItem[], id: string): Promise<ApiResponse<ToggleItem | undefined>> => {
    const item = featureToggleList.find((i) => i.id === id);

    const response: ResponseData<ToggleItem | undefined> = {
      content: item,
      statusCode: item ? HttpErrors.OK : HttpErrors.NOT_FOUND,
      success: !!item,
      responseCode: item ? 'OK' : 'NOT FOUND',
    };

    return Promise.resolve(ApiResponse.fromData(response));
  };
}
export default new FeatureToggleService();
