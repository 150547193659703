import { Box, Divider, makeStyles } from '@material-ui/core';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { Bike } from '../../external/pdl-common/model/Bike';
import { AppItem } from '../common/AppItem';

interface Props {
  bike: Bike;
  extraButton?: JSX.Element;
}

export const BikeInformation = ({ bike, extraButton }: Props) => {
  const classes = useStyles();

  const bikeLocation = [
    bike.location?.streetAddress || '',
    bike.location?.streetAddressDetails || '',
    bike.location?.city || '',
    bike.location?.state || '',
    bike.location?.zipCode || '',
    bike.location?.countryCode || '',
  ]
    .filter(Boolean)
    .join(', ');

  const getPrice = () => {
    if (bike.twoDayPrice) {
      return `$${bike.customPrice || bike.listPrice}/$${bike.twoDayPrice!}/$${bike.threePlusDayPrice!}/day`;
    }
    return `$${bike.customPrice || bike.listPrice}/day`;
  }

  return (
    <>
      <Box className={classes.bikeTitle}>
        <AppTypography
          type={PDLTypography.portalLargeHeading}
          customClasses={classes.bikeTitle}
          children={bike.title}
        />
      </Box>

      <Divider style={{ marginBottom: '1.1875em' }} />

      <Box className={classes.bikeInfoContainer}>
        <AppItem title={'Serial Number'} value={bike.serialNumber ? bike.serialNumber : ''} />
        <AppItem title={'Make'} value={bike.customMake || bike.make} />
        <AppItem title={'Model'} value={bike.model} />
        <AppItem title={'Type'} value={bike.type} />
        <AppItem title={'Frame Material'} value={bike.frameMaterial} />
        <AppItem title={'Year'} value={`${bike.year}`} />
      </Box>

      <Box className={classes.bikeInfoContainer}>
        <AppItem title={'Color'} value={bike.customColor || bike.color} />
        <AppItem title={'Condition'} value={bike.customCondition || bike.condition} />
        <AppItem title={'Size'} value={bike.size} />
        <AppItem title={'Saddle Height'} value={`${bike.topSaddleHeight} cm`} />
        <AppItem title={'Reach'} value={`${bike.bottomSaddleHeight} cm`} />
      </Box>

      <Divider style={{ marginBottom: '1.1875em' }} />

      <Box className={`${classes.bikeInfoContainer}`} style={{ justifyContent: 'space-between' }}>
        <Box display="flex">
          <AppItem title={'Market price'} value={`$${bike.marketPrice}`} />
          <AppItem title={'Price'} value={getPrice()} valueStyle={{ marginRight: '3em' }} />
          <AppItem title={'Location'} value={bikeLocation} />
          <AppItem
            title={'Time Zone'}
            value={bike.location.timezoneId!}
            titleStyle={{ marginLeft: '3em' }}
            valueStyle={{ marginLeft: '3em' }}
          />
        </Box>
        {extraButton && <Box>{extraButton}</Box>}
      </Box>

      <Divider style={{ marginBottom: '1.875em' }} />

      <Box className={classes.aboutSection}>
        <AppTypography
          type={PDLTypography.portalSmallHeading}
          children={'About this bike'}
          customStyles={{ marginBottom: '1em' }}
        />
        <AppTypography type={PDLTypography.paragraph} children={bike.description} />
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bikeTitle: {
    marginTop: '1.875em',
    marginBottom: '1em',
  },
  bikeInfoContainer: {
    width: '40vw',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1em',
    paddingTop: '.3em',
    paddingBottom: '.3em',
  },
  aboutSection: {
    maxWidth: '40vw',
    marginBottom: '.6em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'flex-start',
  },
}));
