import { useEffect, useRef } from 'react';
import { Box, CircularProgress, IconButton, InputBase, makeStyles } from '@material-ui/core';
import { AppAssets } from '../../assets';

interface Props {
  message: string;
  setMessage: (newMessage: string) => void;
  isLoading: boolean;
  inputDisabled?: boolean;
  onSubmit: () => void;
  autofocus: boolean;
}

export const ChatInput = ({
  message,
  setMessage,
  isLoading,
  inputDisabled,
  onSubmit,
  autofocus,
}: Props) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    return () => setMessage('');
  }, [setMessage]);

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [onSubmit]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Box className={classes.inputContainer}>
        <IconButton aria-label="image">
          <AppAssets.PortalImage />
        </IconButton>
        <InputBase
          autoFocus={true}
          inputRef={inputRef}
          style={{ marginLeft: 1, flex: 1 }}
          placeholder="Write a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={isLoading || inputDisabled}
        />
        <IconButton type="submit" aria-label="search" disabled={isLoading}>
          {isLoading ? <CircularProgress color="inherit" size={15} /> : <AppAssets.PortalSend />}
        </IconButton>
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    marginTop: '2em',
  },
}));
