import { Box, Divider, makeStyles, Avatar, Grid } from '@material-ui/core';
import { AppAssets } from '../../../assets/index';
import User from '../../../external/fox-typescript/core/User';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import { CoreUser } from '../../../external/pdl-common/model/CoreUser';
import { InboxStatusType } from '../../../external/pdl-common/utils/InboxStatusType';
import StringUtils from '../../../external/pdl-common/utils/StringUtils';
import { InboxStatus } from './InboxStatus';

interface Props {
  user: User | CoreUser;
  title: string;
  subTitle: string;
  onClick: () => void;
  status: InboxStatusType;
  message?: string;
  isActive?: boolean;
  unread?: boolean;
  simpleChannel?: boolean;
}

export const InboxItem = ({
  user,
  title,
  subTitle,
  message,
  status,
  isActive = false,
  onClick,
  unread = false,
  simpleChannel,
}: Props) => {
  const classes = useStyles();

  return (
    <Box onClick={onClick}>
      <Grid
        container
        className={
          isActive
            ? `${classes.activeContainer} ${classes.messageContainer}`
            : `${classes.messageContainer}`
        }
      >
        <Grid item xs={1} style={{ paddingTop: '.5em' }}>
          {unread && <AppAssets.PortalEllipse />}
        </Grid>

        <Grid item xs={11}>
          {/* User information */}
          <Box className={classes.messageUserContainer}>
            <Avatar src={user.avatarUrl} />

            <Box className={classes.userContainer}>
              <AppTypography
                type={PDLTypography.smallHeading}
                children={StringUtils.formatShortUserName(user)}
              />

              <Box className={classes.messageInfoContainer}>
                <AppTypography
                  type={PDLTypography.smallHeading}
                  children={subTitle}
                  customStyles={{ color: '#A8ACAD' }}
                />
                {!simpleChannel && <InboxStatus status={status} />}
              </Box>
            </Box>
          </Box>

          {/* Message information */}
          <Box>
            {!simpleChannel && (
              <AppTypography
                type={PDLTypography.smallHeading}
                children={StringUtils.sliceText(title, 60)}
              />
            )}

            {!!message && (
              <Box>
                <AppTypography
                  type={PDLTypography.portalLabel}
                  children={StringUtils.sliceText(message, 60)}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box style={{ marginBottom: '1em', height: '.5em' }}>{!isActive && <Divider />}</Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    padding: '1.45em 1.625em 1.0625em 0.975em',
    cursor: 'pointer',
    display: 'flex',
  },
  activeContainer: {
    borderRadius: '0.625em',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  },
  messageUserContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.4375em',
    marginBottom: '1.25em',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.4375em',
  },
}));
