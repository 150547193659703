import { ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';

interface Props {
  path: string;
  label: string;
  icon: JSX.Element;
}

export const SidebarItem = ({ path, label, icon }: Props) => {
  const classes = useStyles();

  return (
    <NavLink to={path} activeClassName={classes.activeLink} className={classes.linkItem}>
      <ListItemIcon className={classes.linkIcon}>{icon}</ListItemIcon>
      <ListItemText
        children={
          <AppTypography
            type={PDLTypography.portalDrawerItem}
            customStyles={{ opacity: 'inherit' }}
            children={label}
          />
        }
      />
    </NavLink>
  );
};

const useStyles = makeStyles((theme) => ({
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    textDecoration: 'none',
    padding: '0.4em 0 0.4em 0',
    opacity: '0.5',
  },
  linkIcon: {
    minWidth: '2.7em',
    marginRight: '1.1em',
    paddingLeft: '1em',
  },
  activeLink: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '0.6em 0px 0px 0.6em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
  },
}));
