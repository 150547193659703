import { IPage } from '../external/fox-typescript/core/IPage';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import Reservation from '../external/pdl-common/model/Reservation';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import environment from '../environment.json';
import { ReservationPageRequestDTO } from '../models/ReservationPageRequestDTO';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClientV2 from '../external/fox-typescript/utils/HttpClientV2';
import ApiResponseV2 from '../external/fox-typescript/core/ApiResponseV2';
import { CancelationParamsDTO } from '../models/CancelationParamsDTO';
import { ReservedBlockoutDatesDTO } from '../models/ReservedBlockoutDatesDTO';

class ReservationService extends GenericCrudService<Reservation> {
  async getReservationsPage(filters: ReservationPageRequestDTO): Promise<PagedResult<Reservation>> {
    const res = await httpClient.getWithParams<IPage<Reservation>>(
      `${this.basePath}/paginated`,
      filters
    );
    return PagedResult.fromIPage(res.getContent());
  }
  async getSemiPublicReservation(externalId: string): Promise<ApiResponse<Reservation>> {
    return await httpClient.get(`${this.basePath}/${externalId}`);
  }

  async getReservationAndBlockoutDates(externalId: string): Promise<ApiResponseV2<ReservedBlockoutDatesDTO>> {
    return await HttpClientV2.get(`${this.basePath}/blockout-dates/${externalId}`);
  }
  async cancelReservation(externalId: string, cancelationParams: CancelationParamsDTO): Promise<ApiResponse<string>> {
    return await httpClient.put(`${this.basePath}/cancel/${externalId}`, cancelationParams, true);
  }

  async forceDropOff(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.post(`${this.basePath}/${externalId}/force-confirm-drop-off`, true);
  }

}

export default new ReservationService(`${environment.baseURL}/portal/reservations`);
