import { Box, Container, IconButton, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ChatComponent } from '../components/Chat/ChatComponent';
import { AppTopBar } from '../components/common/AppTopBar';
import { Spinner } from '../components/common/Spinner';
import { InboxChannelList } from '../components/Inbox/common/InboxChannelList';
import PortalInput from '../components/PortalInput';
import { PdlChatChannel } from '../external/pdl-common/model/PdlChatChannel';
import { Debouncer } from '../external/pdl-common/utils/Debouncer';
import { RequestStatus } from '../external/pdl-common/utils/RequestStatus';
import { PAGE_SIZE, Pagination } from '../models/Utils/Pagination';
import InboxService from '../services/InboxService';
import PdlAlertService from '../services/PdlAlertService';
import RefreshIcon from '@mui/icons-material/Refresh';

export const DirectMessages = () => {
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  const [channels, setChannels] = useState<PdlChatChannel[]>([]);
  const [currentChannel, setCurrentChannel] = useState<PdlChatChannel | undefined>(undefined);

  const [inboxItemIndex, setInboxItemIndex] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageData, setPageData] = useState<Pagination>({ number: 0, totalPages: 0 });

  const [userNameOrEmail, setUserNameOrEmail] = useState<string>('');

  useEffect(() => {
    loadChannels();
  }, []);

  useEffect(() => {
    loadChannels();
  }, [userNameOrEmail]);

  const loadChannels = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (
      await InboxService.getAllChannelsByUserId(
        0,
        PAGE_SIZE * (inboxItemIndex / PAGE_SIZE + 1),
        userNameOrEmail
      )
    )
      .onSuccess((response) => {
        setChannels(response.content);
        setTotalElements(response.totalElements);
        setPageData({
          number: response.number,
          totalPages: response.totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response);
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreChannels = async () => {
    Debouncer.debounce(
      'loadMoreChannels',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        (await InboxService.getAllChannelsByUserId(pageData.number + 1, PAGE_SIZE, userNameOrEmail))
          .onSuccess((response) => {
            const newChannels = response.content;

            setChannels([...channels, ...newChannels]);
            setPageData({ ...pageData, number: pageData.number + 1 });
          })
          .onError((response) => {
            PdlAlertService.showSnackCustomError(response);
          });
      },
      250
    );
  };

  const handleInputChange = (value: string) => {
    if (value.trim().length > 3 || value !== userNameOrEmail) {
      setUserNameOrEmail(value);
    }
  };

  return (
    <>
      <AppTopBar title={'Direct Messages'}
        content={
          <Box>
            <IconButton
              onClick={() => loadChannels()}
              disabled={requestStatus === RequestStatus.LOADING}
            >
              <RefreshIcon />
            </IconButton>
          </Box>}
      />

      < Box className={classes.container} >
        <Box padding="0 0 1em 3.5em">
          <PortalInput
            label={'Search: '}
            value={userNameOrEmail}
            handleChange={(val) => handleInputChange(val.target.value)}
            placeholder={'User name or email'}
          />
        </Box>
        {/* Channels list */}
        <Box display="flex" width="100%">
          <Box className={classes.chatList}>
            {requestStatus === RequestStatus.LOADING ? (
              <Spinner />
            ) : (
              <InboxChannelList
                channels={channels}
                loadMore={loadMoreChannels}
                currentChannel={currentChannel}
                onClick={(selectedChannel) => setCurrentChannel(selectedChannel)}
                itemIndex={inboxItemIndex}
                setItemIndex={setInboxItemIndex}
                totalElements={totalElements}
                simpleChannels
                noMessage
              />
            )}
          </Box>

          {/* Inbox detail */}
          <Container>
            <Box>
              {currentChannel && (
                <ChatComponent
                  channel={currentChannel}
                  channelExternalId={requestStatus === RequestStatus.SUCCESS ? currentChannel?.externalId : ""}
                  isReservationChat={false}
                  chatAlwaysEnabled
                  autofocus={false}
                />
              )}
            </Box>
          </Container>
        </Box>
      </Box >
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#F9F9F9',
    height: '85vh',
    borderRadius: '2em',
    padding: '1.25em 0 1.875em 0',
  },
  chatList: {
    display: 'flex',
    flexDirection: 'column',
    width: '20vw',
    height: '75vh',
    overflowY: 'auto',
    paddingLeft: '1.5em',
  },
}));
