import { Box, Grid, IconButton, Menu, MenuItem, SxProps, TextField } from '@mui/material';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { Avatar } from '@mui/material';
import { PedalUser } from '../../external/pdl-common/model/PedalUser';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { makeStyles } from '@mui/styles';
import StringUtils from '../../external/pdl-common/utils/StringUtils';
import { AppItem } from '../common/AppItem';
import React, { useState } from 'react';
import ChatChannelCreation from '../../models/creation-interfaces/ChatChannelCreation';
import { GenericModal } from '../common/GenericModal';
import InboxService from '../../services/InboxService';
import PdlAlertService from '../../services/PdlAlertService';
import { MoreVert } from '@material-ui/icons';

interface Props {
  user: PedalUser;
  temporaryLocation?: string;
}

interface Option {
  name: string;
  action: () => void;
}

export const UserDetails = ({ user, temporaryLocation }: Props) => {
  const classes = useStyles();

  const location = [
    user?.additionalPersonalInformation?.streetAddress || '',
    user?.additionalPersonalInformation?.city || '',
    user?.additionalPersonalInformation?.stateCode! || '',
    user?.additionalPersonalInformation?.zipCode || '',
    user?.additionalPersonalInformation?.countryCode || '',
  ]
    .filter(Boolean)
    .join(', ');

  const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
  const [contactUserMessage, setContactUserMessage] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      name: "Contact User",
      action: () => {
        setOpenMessageModal(true);
        handleClose();
      }
    } as Option
  ];

  const ITEM_HEIGHT = 48;

  const sendNewMessage = async () => {
    const chatChannelDto = new ChatChannelCreation(user.externalId!, contactUserMessage);

    (await InboxService.createChatChannel(chatChannelDto))
      .onSuccess(() => {
        setOpenMessageModal(false);
        setContactUserMessage('');
        PdlAlertService.showSuccessMessage('Message successfully sent');
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response);
      });
  };

  return (
    <>
      <Grid className={classes.container}>
        <Box display="flex" alignItems="center">
          <Avatar sx={muiStyles.profileImage}>
            <AppImage
              src={user.user.avatarUrl}
              alt={'Avatar'}
              width={'3.425em'}
              style={{ width: '3.425em', height: '3.425em', objectFit: 'cover' }}
            />
          </Avatar>
          <AppTypography type={PDLTypography.mediumHeading}>
            {StringUtils.formatCompleteUserName(user?.user)}
          </AppTypography>
        </Box>
        <Box>
          <AppItem title={temporaryLocation ? 'Temporary Location' : 'Location'} value={temporaryLocation ? temporaryLocation : location}></AppItem>
        </Box>
        <Box>
          <AppItem title={'Email Address'} value={user?.user.email!} />
        </Box>
        <Box>
          <AppItem title={'Phone'} value={user?.additionalPersonalInformation?.phoneNumber!} />
        </Box>
        <Box>
          <AppItem title={'Date of birth'} value={user?.birthDate!} />
        </Box>
        <Box>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
        </Box>
      </Grid>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.name} selected={option.name === 'Pyxis'} onClick={option.action}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
      <GenericModal
        title={'Send a message'}
        openModal={openMessageModal}
        disabled={contactUserMessage === ''}
        setOpenModal={setOpenMessageModal}
        onConfirm={sendNewMessage}
        onClose={() => {
          setOpenMessageModal(false);
          setContactUserMessage('');
        }}
        children={
          <TextField
            multiline
            rows={8}
            value={contactUserMessage}
            onChange={(val) => setContactUserMessage(val.target.value)}
            placeholder="Write a message..."
            style={{ margin: '1em 0', width: '600px' }}
          />
        }
      />
    </>
  );
};

const muiStyles = {
  profileImage: {
    width: '3.425em',
    height: '3.425em',
    marginRight: '1.25em',
  } as SxProps,
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '10em',
    marginBottom: '0.3em',
    marginRight: '3em',
  },
  profileImage: {
    width: '5.125em',
    height: '5.125em',
    marginRight: '1.25em',
  },
  title: {
    color: '#01000E',
    opacity: 0.5,
  },
}));
