import { AppAssets } from '../../../assets';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import { InboxStatusType } from '../../../external/pdl-common/utils/InboxStatusType';

interface Props {
  status: InboxStatusType;
  withLabel?: boolean;
}

export const InboxStatus = ({ status, withLabel = false }: Props) => {
  const getIconStatus = (): JSX.Element => {
    switch (status) {
      case InboxStatusType.OPEN:
      case InboxStatusType.ASSIGNED:
        return <AppAssets.PortalGreenEllipse />;
      case InboxStatusType.PENDING:
        return <AppAssets.PortalOrangeEllipse />;
      default:
        return <AppAssets.PortalRedEllipse />;
    }
  };

  const getColorStatus = (): string => {
    switch (status) {
      case InboxStatusType.OPEN:
      case InboxStatusType.ASSIGNED:
        return '#3AB65B';
      case InboxStatusType.PENDING:
        return '#FFA834';
      default:
        return '#FF6E7D';
    }
  };

  return (
    <>
      {getIconStatus()}

      {withLabel && (
        <AppTypography
          type={PDLTypography.smallHeading}
          children={status}
          customStyles={{ color: getColorStatus(), marginRight: '2em' }}
        />
      )}
    </>
  );
};
