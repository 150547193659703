import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';

interface Props {
  label: string;
  status?: string;
  button?: JSX.Element | false;
  extraButton?: JSX.Element | false;
  leftButton?: JSX.Element | false;
}

export const StatusBar = ({ label, status, button, extraButton, leftButton }: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={classes.containerLeftButtons}>
        <Box pr="1em">{leftButton}</Box>
      </Grid>
      <Grid className={classes.containerButtons}>
        <Box pr="1em">
          <AppTypography type={PDLTypography.callToAction} children={`${label}: `} />
          <AppTypography type={PDLTypography.callToAction} children={status} />
        </Box>
        <Box pr="1em">{button}</Box>
        <Box pr="1em">{extraButton}</Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '3em',
  },
  containerLeftButtons: {
    display: 'flex',
    alignItems: 'left',
    width: '100%',
    height: '3em',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
    height: '3em',
  },
}));
