import moment from 'moment';

export default class DateUtils {
  /**
   * Formats the date by using the specified format
   * @param date the date to be formatted
   * @param dateFormat the foramt spec
   */
  public static formatDate(date: Date, dateFormat: string) {
    return moment(date).format(dateFormat);
  }

  /**
   * Formats the date by using DD/MMM/YYYY format
   * @param date the date to be formatted
   */
  public static formatAsDDMMMYYY(date: Date) {
    return moment(date).format('DD/MMM/YYYY');
  }

  /**
   * Formats the date by using "dddd DD, MMMYYYY" format
   * @param date the date to be formatted
   */
  public static formatAsDDDMMMYYY(date: Date) {
    return moment(date).format('ddd DD, MMM YYYY');
  }

  /**
   * Formats the date by using "YYYY-MM-DD HH-mm-ss" format
   * @param date the date to be formatted
   */
  public static formatAsYYYMMDDHHmmss(date: Date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Formats the date by using "DD-MM" format
   * @param date the date to be formatted
   */
  public static formatAsDDMMM(date: Date) {
    return moment(date).format('DD/MMM');
  }
}
