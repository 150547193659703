import { IPage } from "./IPage";

export default class PagedResult<T> {
  readonly content: T[];
  readonly pageNumber: number;
  readonly totalElements: number;

  constructor(content: T[], pageNumber: number, totalElements: number) {
    this.content = content;
    this.pageNumber = pageNumber;
    this.totalElements = totalElements;
  }

  public static fromArray<T>(array: T[]) {
    return new PagedResult<T>(array, 0, array.length);
  }

  /**
   * @deprecated The array parameter is not needed
   */
  public static empty<T>(array: T[]): PagedResult<T>;
  public static empty<T>(): PagedResult<T>;

  public static empty<T>(array?: T[]) {
    return new PagedResult<T>([], 0, 0);
  }

  public static fromIPage<T>(iPage: IPage<T>) {
    return new PagedResult(iPage.content, iPage.number, iPage.totalElements);
  }
}
