import Title from '../external/ensolvers-core-frontend-mui/components/Common/Title';

/**
 * Component used to display "to be implemented" message for wip routes
 * Remove this when we have all routes implemented
 */
export function ToBeImplementedView() {
  return (
    <div
      style={{ height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Title>Section to be implemented</Title>
    </div>
  );
}
