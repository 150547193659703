import { Box, IconButton } from '@material-ui/core';
import { useState, useEffect } from 'react';
import DropdownMenu from '../components/DropdownMenu';
import Panel from '../components/Panel';
import PortalInput from '../components/PortalInput';
import { Title } from '../components/Titles';
import TableBasedCrud from '../external/ensolvers-core-frontend-mui/components/TableBaseCrud';
import { AccountWaitList } from '../models/AccountWaitList';
import AccountWaitListService from '../services/AccountWaitListService';
import PdlAlertService from '../services/PdlAlertService';
import RefreshIcon from '@mui/icons-material/Refresh';

type Filters = {
  includeApproved: boolean;
  includeDisabled: boolean;
  includeNotEvaluated: boolean;
  includeUsed: boolean;
  includeAccountCreated: boolean;
};

interface Props {
  defaultFilter?: string;
}

export default function WaitListUsers({ defaultFilter }: Props) {
  const [selectedFilter, setSelectedFilter] = useState<string>(
    defaultFilter || 'includeNotEvaluated'
  );
  const [userNameOrEmail, setUserNameOrEmail] = useState<string>('');

  const [filters, setFilters] = useState<Filters>({
    includeApproved: false,
    includeDisabled: false,
    includeNotEvaluated: true,
    includeUsed: false,
    includeAccountCreated: false,
  });

  const handleOnDataFetch = (pageNumber: number, rowsPerPage: number) => {
    return AccountWaitListService.getAccountsPage({
      page: pageNumber,
      pageSize: rowsPerPage,
      ...filters,
      nameOrEmailFilter: userNameOrEmail,
    });
  };

  const handleFilterChange = (selectedOption: string) => {
    let updatedFilters = { ...filters };
    if (selectedOption === 'allUsers') {
      updatedFilters.includeApproved = true;
      updatedFilters.includeDisabled = true;
      updatedFilters.includeNotEvaluated = true;
      updatedFilters.includeUsed = true;
      updatedFilters.includeAccountCreated = true;
    } else {
      updatedFilters.includeApproved = false;
      updatedFilters.includeDisabled = false;
      updatedFilters.includeNotEvaluated = false;
      updatedFilters.includeUsed = false;
      (updatedFilters as any)[selectedOption] = true;
    }
    setFilters(updatedFilters);
    setSelectedFilter(selectedOption);
  };

  const approveUser = async (user: AccountWaitList, refresh: () => void) => {
    const res = await AccountWaitListService.approve(user.externalId!);
    res
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage(`User ${user.firstname} was approved!`);
        refresh();
      })
      .onError((response) => {
        PdlAlertService.showSnackInternalError();
      });
  };

  const resendAccountCreationEmail = async (user: AccountWaitList, refresh: () => void) => {
    const res = await AccountWaitListService.resendAccountCreationEmail(user.externalId!);
    res
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage(`Account creation email for ${user.firstname} was resent successfully!`);
        refresh();
      })
      .onError((response) => {
        PdlAlertService.showSnackInternalError();
      });
  };

  const disableUser = async (user: AccountWaitList, refresh: () => void) => {
    const res = await AccountWaitListService.disable(user.externalId!);
    res
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage(`User ${user.firstname} was disabled.`);
        refresh();
      })
      .onError((response) => {
        PdlAlertService.showSnackInternalError();
      });
  };

  const getStatus = (user: AccountWaitList) => {
    if (user.approvedAt) {
      if(user.usedAt) {
        return 'Account Created';
      } else {
        return 'Approved';
      }
    } else if (user.disabledAt) {
      return 'Disabled';
    } else {
      return 'Pending';
    }
  };
  const handleUserNameFilterChange = (value: string) => {
    if (value.trim().length > 3 || value !== userNameOrEmail) {
      setUserNameOrEmail(value);
    }
  };

  return (
    <>
      <Title>Users in waitlist</Title>
      <Box style={{ textAlign: 'center' }}>
        <IconButton
          onClick={() => handleFilterChange(selectedFilter)}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Panel>
        <TableBasedCrud<AccountWaitList>
          elementName={'User'}
          onDataFetch={handleOnDataFetch}
          renderDialogContent={(register, errors, control) => <></>}
          tableContainer={(props) => <Box>{props.children}</Box>}
          additionalToolbarButtons={() => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <DropdownMenu
                handleOptionChange={handleFilterChange}
                label={'Filter:'}
                value={selectedFilter}
                options={[
                  {
                    title: 'All users',
                    value: 'allUsers',
                  },
                  {
                    title: 'Approved',
                    value: 'includeApproved',
                  },
                  {
                    title: 'Account Created',
                    value: 'includeUsed',
                  },
                  {
                    title: 'Disabled',
                    value: 'includeDisabled',
                  },
                  {
                    title: 'Pending',
                    value: 'includeNotEvaluated',
                  },
                ]}
              />
              <PortalInput
                label={'Search: '}
                value={userNameOrEmail}
                handleChange={(val) => handleUserNameFilterChange(val.target.value)}
                placeholder={'User name or email'}
              />
            </div>
          )}
          tableColumns={[
            {
              header: 'First name',
              content: (instance) => <>{instance.firstname}</>,
            },
            {
              header: 'Last name',
              content: (instance) => <>{instance.lastname}</>,
            },
            {
              header: 'Email',
              content: (instance) => <>{instance.email}</>,
            },
            {
              header: 'Bike',
              content: (instance) => <>{instance.bike}</>,
            },
            {
              header: 'Status',
              content: (instance) => <>{getStatus(instance)}</>,
            },
          ]}
          buildAdditionalActions={(instance, refresh) => {
            if (instance.disabledAt) {
              return [
                {
                  title: 'Approve',
                  action: () => approveUser(instance, refresh),
                },
              ];
            } else if (!instance.approvedAt) {
              return [
                {
                  title: 'Approve',
                  action: () => approveUser(instance, refresh),
                },
                {
                  title: 'Deny',
                  action: () => disableUser(instance, refresh),
                },
              ];
            } else if (instance.approvedAt && !instance.usedAt) {
              return [
                {
                  title: 'Resend account creation email',
                  action: () => resendAccountCreationEmail(instance, refresh),
                },
              ];
            }
            return [];
          }}
        />
      </Panel>
    </>
  );
}
