import React, { ReactNode, useEffect, useState, CSSProperties, MutableRefObject } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from 'react-hook-form';
import { createStyles, Drawer, DrawerProps, Grid, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import { Alert } from '@material-ui/lab';
import { ValidationContext } from './FormComponents';
import { DialogPosition } from './DialogPosition';
import { DebounceButton } from './DebounceButton';

const MAX_WIDTH_DRAWER: number = 350;

interface Props<T> {
  /**
   * Content of the form
   */
  children?: ReactNode;
  /**
   * `true` if the form is going to be rendered in a dialog
   */
  dialog: boolean;
  /**
   * Only valid if `dialog == true`
   */
  open?: boolean;
  /**
   * Only valid if `dialog == true`
   * Dialog content title
   */
  text?: string;
  /**
   * Dialog/Form header
   */
  title?: string;
  /**
   * Callback fired in form submission
   */
  onSave: (instance: T, submitContext?: SubmitContext) => void;
  /**
   * Callback fired in dialog cancelation
   */
  onCancel?: () => void;
  /**
   * If validateBeforeClosing is true,
   *  - form inputs wont be reset on submit until validated is set to true.
   *  - onValidSave will be executed after save has been validated and validated is set to true.
   */
  validateBeforeClosing?: boolean;
  /**
   * `true` if the form was validated
   */
  validated?: boolean;
  /**
   * Callback fired in valid form submission
   */
  onValidSave?: () => void;

  // TODO: add proper types here
  // NOTE: since we are using MUI, we need to wrap the components
  //       in a Controller (https://react-hook-form.com/api/usecontroller/controller)
  /**
   * Object instance that the form will manage
   */
  instance?: T;
  /**
   * Function used to add content inside the form
   */
  renderContent: (register: any, errors: any, control: any, instance?: T) => JSX.Element;
  /**
   * Default values to set after a reset occurs (form submission)
   */
  resetValue?: {};
  /**
   * Function that must return the button used to submit the form.
   */
  saveButtonBuilder: (onClick: () => void) => JSX.Element;
  /**
   * Function used to add additional buttons to the form
   */
  additionalButtonsBuilder: () => React.ReactNode;
  /**
   * Position of the dialog in the window
   */
  dialogPosition: DialogPosition;
  /**
   * Disable snackbar alerts
   */
  disableAlert?: boolean;
  /**
   * Styles for the "save" button
   */
  buttonStyles: {};
  /**
   * Label for the "save" button
   * The default value is "save"
   */
  dialogCustomSubmitMessage?: string;
  /**
   * Styles for the form
   */
  dialogStyles?: string;
  /**
   * Class for buttons container
   */
  buttonsContainerClass?: string;
  /**
   * If true hide the cancel button
   */
  hideCancel?: boolean;
  /**
   * Prop to save the save reference
   */
  submitRef?: MutableRefObject<any>;
  /**
   * Styles for the container of the buttons in dialog
   */
  actionContainerStyles?: string;

  additionalButtons?: { label: string; onAction: () => void }[];

  /**
   * If true, hitting outside modal will not fire the onClose callback.
   */
  disableBackdropClick?: boolean;
  /**
   * Reference to watch changes in form inputs
   */
  watchRef?: MutableRefObject<Object | null>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
  })
);

const defaultButtonBuilder = (label: string) => {
  return (onClick: () => void) => (
    <Button type="submit" color="primary" onClick={onClick}>
      {label}
    </Button>
  );
};

GenericForm.defaultProps = {
  saveButtonBuilder: defaultButtonBuilder('Save'),
  additionalButtonsBuilder: () => <></>,
  dialogPosition: DialogPosition.Center,
  disableAlert: false,
  buttonStyles: {},
  dialogStyles: '',
};

interface SubmitContext {
  trigger?: SubmitTrigger;
}

enum SubmitTrigger {
  SAVE_BUTTON_CLICK = 'SAVE_BUTTON_CLICK',
  ENTER_KEY_PRESSED = 'ENTER_KEY_PRESSED',
}

function GenericForm<T>(props: Props<T>) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const [confirmationShown, setConfirmationShown] = useState<boolean>();

  if (!!props.watchRef) {
    props.watchRef.current = watch();
  }

  useEffect(() => {
    if (props.instance) {
      Object.keys(props.instance!).forEach((property) => {
        setValue(property, (props.instance as any)[property]);
      });
    }

    return () => props.instance && doReset();
  }, [props.instance]);

  /* 
    This is used to force the correct instance properties population on the form fields
    after canceling the edition and reopening the form, preventing the creation of new instances.
  */
  useEffect(() => {
    if (props.instance && props.open) {
      Object.keys(props.instance!).forEach((property) => {
        setValue(property, (props.instance as any)[property]);
      });
    }
  }, [props.open]);

  useEffect(() => {
    if (props.validateBeforeClosing && props.validated) {
      if (props.onValidSave) props.onValidSave();
      doReset();
    }
  }, [props.validated]);

  useEffect(() => {
    if (props.submitRef) {
      props.submitRef.current = submitInlineForm;
    }
  }, []);

  const handleClose = () => {
    doReset();

    if (props.onCancel) {
      props.onCancel();
    }
  };

  const doReset = () => (props.resetValue ? reset(props.resetValue) : reset());

  // Submit Context gives you the chance to add additional functionality depending on the way you submit the form.
  // You can submit by clicking "Save"  (trigger = "SAVE_BUTTON_CLICK")
  //                or pressing "Enter" (trigger = "ENTER_KEY_PRESSED")
  const onSubmit = (data: any, submitContext?: SubmitContext) => {
    props.onSave(data, submitContext);
    if (!props.validateBeforeClosing) {
      doReset();
    }
  };

  const renderForm = () => (
    <form
      autoComplete="off"
      className={`${classes.root} ${props.dialogStyles}`}
      onSubmit={(event) => {
        handleSubmit((data) => onSubmit(data, { trigger: SubmitTrigger.ENTER_KEY_PRESSED }))(event);
        event.preventDefault();
      }}
    >
      <ValidationContext.Provider value={{ register, errors, control }}>
        {props.renderContent(register, errors, control, props.instance)}
      </ValidationContext.Provider>
      {/*
        input is hidden, but allows to submit the form pressing "Enter"
      */}
      <input type="submit" hidden />
    </form>
  );

  const getWidthForDrawer = (anchor: DrawerProps['anchor']) => {
    return anchor === DialogPosition.Top || anchor === DialogPosition.Bottom ? 'auto' : MAX_WIDTH_DRAWER;
  };

  const renderDrawer = (anchor: DrawerProps['anchor']): JSX.Element => {
    let width = getWidthForDrawer(anchor);

    const initialActionContainerStyle: CSSProperties = {
      position: 'fixed',
      bottom: 0,
      width,
    };

    const getActionContainerStyle = (): CSSProperties => {
      if (anchor === DialogPosition.Right) {
        return {
          ...initialActionContainerStyle,
          right: 0,
        };
      }

      return initialActionContainerStyle;
    };

    return (
      <Drawer anchor={anchor} open={!!props.open} onClose={handleClose}>
        <Box sx={{ width: width }} role="presentation">
          <Grid container spacing={0}>
            {props.title && renderDialogHeader(props.title)}
            {renderDialogContent(() => renderForm(), props.text)}
            <Box className={`${getActionContainerStyle()} ${props.actionContainerStyles ?? ''}`}>{renderDialogActions()}</Box>
          </Grid>
        </Box>
      </Drawer>
    );
  };

  const renderDialogHeader = (title: string): JSX.Element => {
    return <DialogTitle id="form-dialog-title">{title}</DialogTitle>;
  };

  const renderDialogContent = (fetchContent: () => {}, titleContent?: string): JSX.Element => {
    return (
      <DialogContent>
        {titleContent && <DialogContentText>{titleContent}</DialogContentText>}
        {fetchContent()}
      </DialogContent>
    );
  };

  const renderDialogActions = (): JSX.Element => {
    return (
      <DialogActions>
        {!props.hideCancel && <Button onClick={handleClose}>Cancel</Button>}
        {props.additionalButtons && props.additionalButtons.map((btn) => <Button onClick={btn.onAction}>{btn.label}</Button>)}

        <DebounceButton
          type="submit"
          style={props.buttonStyles}
          color="primary"
          onClick={handleSubmit((data) => onSubmit(data, { trigger: SubmitTrigger.SAVE_BUTTON_CLICK }))}
          text={props.dialogCustomSubmitMessage || 'Save'}
        />
      </DialogActions>
    );
  };

  const renderDialog = (): JSX.Element => {
    return (
      <Dialog
        open={!!props.open}
        onClose={handleClose}
        fullWidth={true}
        disableBackdropClick={!props.disableBackdropClick ? false : props.disableBackdropClick}
      >
        {props.title && renderDialogHeader(props.title)}
        {renderDialogContent(() => renderForm(), props.text)}
        {renderDialogActions()}
      </Dialog>
    );
  };

  function submitInlineForm() {
    handleSubmit((data) => onSubmit(data, { trigger: SubmitTrigger.SAVE_BUTTON_CLICK }))();
    if (!props.disableAlert) {
      setConfirmationShown(true);
    }
  }

  const handleDialogPosition = (position: DialogPosition): JSX.Element => {
    return position === DialogPosition.Center ? renderDialog() : renderDrawer(position);
  };

  if (props.dialog) {
    return handleDialogPosition(props.dialogPosition);
  } else {
    return (
      <Grid xs={12}>
        {props.title && <Typography variant="h5">{props.title}</Typography>}

        {renderForm()}

        {!props.disableAlert && (
          <Snackbar
            open={confirmationShown}
            autoHideDuration={3000}
            onClose={() => setConfirmationShown(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert onClose={() => setConfirmationShown(false)} severity="success">
              Saved
            </Alert>
          </Snackbar>
        )}
        <Grid className={props.buttonsContainerClass ?? ''}>
          {props.saveButtonBuilder(submitInlineForm)}
          {props.additionalButtonsBuilder()}
        </Grid>
      </Grid>
    );
  }
}

export default GenericForm;
