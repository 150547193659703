import React, { CSSProperties } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { debounce } from '../../utils/debounce';

export interface DropdownMenuItem {
  /**
   * Label of the item
   */
  title: string;
  /**
   * Callback fired when the item is clicked
   */
  action: () => void;
}

interface Props {
  /**
   * Items to list inside the dropdown menu
   */
  entries: (DropdownMenuItem | undefined)[];
  /**
   * Styles for the container component (div)
   */
  containerStyles?: CSSProperties;
}

export default function SimpleMenu(props: Props) {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // if this element is contained inside another, just avoid bubbling the click event
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    // if this element is contained inside another, just avoid bubbling the click event
    event.stopPropagation();
    setAnchorElement(null);
  };

  const handleMenuItemClick = (action: () => void) => (event: React.MouseEvent<HTMLLIElement>) => {
    // if this element is contained inside another, just avoid bubbling the click event
    event.stopPropagation();
    action();
    setAnchorElement(null);
  };

  return (
    <div style={props.containerStyles}>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorElement} keepMounted open={Boolean(anchorElement)} onClose={handleClose}>
        {props.entries
          .filter((entry) => !!entry)
          .map((entry, index) => (
            <MenuItem key={index} onClick={debounce(handleMenuItemClick(entry!.action))}>
              {entry!.title}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
