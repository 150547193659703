import { Box, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import { AppTopBar } from '../common/AppTopBar';
import { InboxAction } from './common/InboxAction';
import { Container } from '@mui/material';
import { InboxFilter } from './common/InboxFilter';
import { PdlChatChannel } from '../../external/pdl-common/model/PdlChatChannel';
import { useState, useEffect } from 'react';
import InboxService from '../../services/InboxService';
import { Bike } from '../../external/pdl-common/model/Bike';
import { InboxChannelList } from './common/InboxChannelList';
import { InboxBikeList } from './common/InboxBikeList';
import { InboxChannelDetail } from './common/InboxChannelDetail';
import { InboxBikeDetail } from './common/InboxBikeDetail';
import BikeService from '../../services/BikeService';
import PdlAlertService from '../../services/PdlAlertService';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import { Spinner } from '../common/Spinner';
import { Debouncer } from '../../external/pdl-common/utils/Debouncer';
import { Pagination } from '../../models/Utils/Pagination';
import InboxUtils from '../../utils/InboxUtils';
import { SortType } from '../../types/SortType';
import { InboxView } from './utils/InboxView';
import { InboxType } from './utils/InboxType';
import { InboxAssignedType } from './utils/InboxAssignedType';
import { ChannelOptions } from './utils/ChannelOptions';
import { InboxStatusType } from '../../external/pdl-common/utils/InboxStatusType';
import { ReportIssueAssignedTypeCount } from '../../models/ReportIssueAssignedTypeCount';
import { AppRoutes } from '../../utils/enums/AppRoutes';
import { useHistory, useLocation } from 'react-router-dom';
import { ViewDetailState } from '../../models/location-states/GenericViewDetailState';
import Reservation from '../../external/pdl-common/model/Reservation';
import { Features } from '../../external/pdl-common/utils/enums/Features';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import RefreshIcon from '@mui/icons-material/Refresh';

const PAGE_SIZE = 10;

export const Inbox = () => {
  const [isPortalDetailViewAvailable] = useFeatureToggle(Features.IS_PORTAL_DETAIL_VIEW_AVAILABLE);

  const classes = useStyles();
  const history = useHistory();
  const state = useLocation().state as ViewDetailState;

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [inboxItemIndex, setInboxItemIndex] = useState<number>(state?.inboxItemIndex || 0);

  const [currentView, setCurrentView] = useState<InboxView>(
    state?.currentView || InboxView.MESSAGES
  );
  const [updateView, setUpdateView] = useState<number>(state?.updateView || Date.now()); // It is used to re-render the component after some update.
  const [pageData, setPageData] = useState<Pagination>(
    state?.pageData || { number: 0, totalPages: 0 }
  );
  const [totalElements, setTotalElements] = useState<number>(0);

  // Channels
  const [channels, setChannels] = useState<PdlChatChannel[]>(state?.channels || []);
  const [currentChannel, setCurrentChannel] = useState<PdlChatChannel | undefined>(
    state?.currentChannel || undefined
  );
  const [currentReservationChannel, setCurrentReservationChannel] = useState<
    PdlChatChannel | undefined
  >(state?.currentReservationChannel || undefined);

  // Bikes
  const [bikesApproval, setBikesApproval] = useState<Bike[]>(state?.bikesApproval || []);
  const [currentBike, setCurrentBike] = useState<Bike | undefined>(state?.currentBike || undefined);

  /**
   * Filters:
   *  - Status: All, Open, Pending, Close, Assigned.
   *  - Sort: Newest (DESC), oldest (ASC). Last Updated (LU).
   *  - Assigned: All, for you, assigned, unassigned.
   *  - Inbox type: Issues, bikes, reservations... (Verify with backend)
   */
  const [statusFilter, setStatusFilter] = useState<InboxStatusType>(
    state?.statusFilter || InboxStatusType.OPEN
  );
  const [sortFilter, setSortFilter] = useState<SortType>(state?.sortFilter || SortType.DESC);
  const [assignedFilter, setAssignedFilter] = useState<keyof typeof InboxAssignedType>(
    state?.assignedFilter || 'ALL'
  );
  const [inboxTypeFilter, setInboxTypeFilter] = useState<InboxType>(
    state?.inboxTypeFilter || InboxType.ALL
  );
  const [channelOptions, setChannelOptions] = useState<string>(ChannelOptions.MORE);

  const [countOfReportIssuesByAssignedTypes, setCountOfReportIssuesByAssignedTypes] = useState<
    ReportIssueAssignedTypeCount | undefined
  >(state?.countOfReportIssuesByAssignedTypes || undefined);

  useEffect(() => {
    load();
  }, [updateView, currentView, statusFilter, sortFilter, assignedFilter, inboxTypeFilter]);

  useEffect(() => {
    const reservation =
      currentChannel?.reportIssue?.reservation || currentReservationChannel?.reservation;

    switch (channelOptions) {
      case ChannelOptions.BIKE_DETAIL:
        viewBikeDetail(reservation?.bike!);
        break;
      case ChannelOptions.RESERVATION_DETAIL:
        if (reservation) {
          viewTripDetail(reservation);
        }
        break;
      case ChannelOptions.RESERVATION:
        loadReservationChannel();
        break;
      default:
        break;
    }
  }, [channelOptions]);

  useEffect(() => {
    loadReportIssueAssignedTypeCounts();
  }, [statusFilter]);

  const loadReservationChannel = async () => {
    const response = await InboxService.getChannelByReservationId(
      currentChannel?.reportIssue?.reservation.externalId!
    );
    response
      .onSuccess((res) => {
        setCurrentReservationChannel(res.getContent());
      })
      .onError((error) => {
        PdlAlertService.showSnackCustomError(error.getContent());
      });
  };

  const load = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      // Depending on the inner view, we search for all items, and also the first item will be selected
      if (currentView === InboxView.MESSAGES) {
        await loadChannels();
      } else {
        await loadBikes();
      }

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      PdlAlertService.showSnackInternalError();
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const loadChannels = async () => {
    const response = await InboxService.getMessagesChannels(
      0,
      PAGE_SIZE * (inboxItemIndex / PAGE_SIZE + 1),
      statusFilter,
      sortFilter,
      assignedFilter
    );

    const { content } = response.getContent();

    setChannels(content);
    setTotalElements(response.getContent().totalElements);

    setPageData({
      number: response.getContent().number,
      totalPages: response.getContent().totalPages,
    });
  };

  const loadMoreChannels = async () => {
    Debouncer.debounce(
      'loadMoreChannels',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        const response = await InboxService.getMessagesChannels(
          pageData.number + 1,
          PAGE_SIZE,
          statusFilter,
          sortFilter,
          assignedFilter
        );

        response
          .onSuccess((response) => {
            const newChannels = response.getContent().content;

            setChannels([...channels, ...newChannels]);
            setPageData({ ...pageData, number: pageData.number + 1 });
          })
          .onError((response) => {
            PdlAlertService.showSnackCustomError(response.getContent());
          });
      },
      250
    );
  };

  // TODO
  const loadBikes = async () => {
    const response = await BikeService.getBikesPage({
      page: 0,
      pageSize: PAGE_SIZE,
      includePending: true,
      includeActive: false,
      includeApproved: false,
      includeDenied: false,
      includeDisabled: false,
    });

    setBikesApproval(response.content);
    setCurrentBike(response.content[0] || undefined);
    setPageData({
      number: response.pageNumber,
      totalPages: response.totalElements,
    });
  };

  // TODO
  const loadMoreBikes = async () => {
    Debouncer.debounce(
      'loadMoreBikes',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        try {
          const response = await BikeService.getBikesPage({
            page: pageData.number + 1,
            pageSize: PAGE_SIZE,
            includePending: true,
            includeActive: false,
            includeApproved: false,
            includeDenied: false,
            includeDisabled: false,
          });

          setBikesApproval([...bikesApproval, ...response.content]);
        } catch (error) {
          PdlAlertService.showSnackInternalError();
        }
      },
      250
    );
  };

  const loadReportIssueAssignedTypeCounts = async () => {
    try {
      const reportIssueAssignedTypeCounts = await InboxService.getReportIssueAssignedTypeCounts(
        statusFilter
      );
      const counts = reportIssueAssignedTypeCounts.getContent();
      setCountOfReportIssuesByAssignedTypes(counts);
    } catch (error: any) {
      PdlAlertService.showSnackInternalError();
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const cleanFilters = () => {
    setStatusFilter(InboxStatusType.OPEN);
    setSortFilter(SortType.DESC);
    setAssignedFilter('ALL');
    setInboxTypeFilter(InboxType.ALL);
    setPageData({ number: 0, totalPages: 0 });
  };

  const viewTripDetail = (reservation: Reservation) => {
    if (isPortalDetailViewAvailable) {
      history.push(`${AppRoutes.TRIP_DETAILS}/${reservation?.externalId}`, {
        previousPage: AppRoutes.INBOX,
        currentView: currentView,
        updateView: updateView,
        pageData: pageData,
        channels: channels,
        currentChannel: currentChannel,
        currentReservationChannel: currentReservationChannel,
        bikesApproval: bikesApproval,
        currentBike: currentBike,
        statusFilter: statusFilter,
        sortFilter: sortFilter,
        assignedFilter: assignedFilter,
        inboxTypeFilter: inboxTypeFilter,
        channelOptions: ChannelOptions.MORE,
        countOfReportIssuesByAssignedTypes: countOfReportIssuesByAssignedTypes,
        inboxItemIndex: inboxItemIndex,
      });
    }
  };

  const viewBikeDetail = (bike: Bike) => {
    if (isPortalDetailViewAvailable) {
      history.push(`${AppRoutes.BIKE_DETAILS}/${bike?.externalId}`, {
        previousPage: AppRoutes.INBOX,
        currentView: currentView,
        updateView: updateView,
        pageData: pageData,
        channels: channels,
        currentChannel: currentChannel,
        currentReservationChannel: currentReservationChannel,
        bikesApproval: bikesApproval,
        currentBike: currentBike,
        statusFilter: statusFilter,
        sortFilter: sortFilter,
        assignedFilter: assignedFilter,
        inboxTypeFilter: inboxTypeFilter,
        channelOptions: ChannelOptions.MORE,
        countOfReportIssuesByAssignedTypes: countOfReportIssuesByAssignedTypes,
        inboxItemIndex: inboxItemIndex,
      });
    }
  };

  return (
    <>
      <AppTopBar title={'Inbox'}
        content={
          <Box>
            <IconButton
              onClick={() => load()}
              disabled={requestStatus === RequestStatus.LOADING}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        }
      />
      <Grid container className={classes.inboxContainer}>
        <Grid item xs={3} className={classes.inboxSidebarContainer}>
          <Container>
            {/* Messages / Bikes approvals */}
            <Grid container className={classes.actionsContainer}>
              <Grid item>
                <InboxAction
                  text={'Messages'}
                  //badge={InboxUtils.getChannelMessagesWithoutRead(channels)}
                  isActive={currentView === InboxView.MESSAGES}
                  onClick={() => {
                    setCurrentView(InboxView.MESSAGES);
                    setCurrentBike(undefined);
                    cleanFilters();
                  }}
                />
              </Grid>

              {/* To uncomment in the future
              <Divider orientation="vertical" className={classes.actionDivider} />
              <Grid item>
                <InboxAction
                  text={'Bike Approval'}
                  badge={InboxUtils.getChannelBikesWithoutRead(bikesApproval)}
                  isActive={currentView === InboxView.BIKE_APPROVAL}
                  onClick={() => {
                    setCurrentView(InboxView.BIKE_APPROVAL);
                    setCurrentChannel(undefined);
                    cleanFilters();
                  }}
                />
              </Grid>
                */}
            </Grid>
            <InboxFilter
              // Status filters
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              // Assigned filters
              assignedFilter={assignedFilter}
              setAssignedFilter={setAssignedFilter}
              // Inbox types
              inboxTypeFilter={inboxTypeFilter}
              setInboxTypeFilter={setInboxTypeFilter}
              // Sort
              sortFilter={sortFilter}
              setSortFilter={setSortFilter}
              //Count ReportIssues by type assigned
              countOfReportIssuesByAssignedTypes={countOfReportIssuesByAssignedTypes}
              setCountOfReportIssuesByAssignedTypes={setCountOfReportIssuesByAssignedTypes}
            />

            {/* Channels / Bikes */}
            <Box className={classes.inboxSidebarItemsContainer}>
              {requestStatus === RequestStatus.LOADING ? (
                <Spinner />
              ) : currentView === InboxView.MESSAGES ? (
                <InboxChannelList
                  channels={channels}
                  loadMore={loadMoreChannels}
                  currentChannel={currentChannel}
                  onClick={(selectedChannel) => {
                    setCurrentChannel(selectedChannel);
                    setChannelOptions(ChannelOptions.MORE);
                  }}
                  itemIndex={inboxItemIndex}
                  setItemIndex={setInboxItemIndex}
                  totalElements={totalElements}
                />
              ) : (
                <InboxBikeList
                  bikes={bikesApproval}
                  loadMore={loadMoreBikes}
                  currentBike={currentBike}
                  onClick={(selectedBike) => setCurrentBike(selectedBike)}
                />
              )}
            </Box>
          </Container>
        </Grid>

        {/* Inbox detail */}
        <Grid item xs={9}>
          <Container>
            {currentView === InboxView.MESSAGES && currentChannel ? (
              <InboxChannelDetail
                channel={currentChannel}
                reservationChannel={currentReservationChannel}
                onUpdate={() => setUpdateView(Date.now())}
                setCurrentChannel={(channel) => {
                  setCurrentChannel(channel);

                  const auxChannels = [...channels].map((c) =>
                    c.externalId === channel.externalId ? channel : c
                  );

                  setChannels(auxChannels);
                }}
                channelOptions={channelOptions}
                setChannelOptions={(option) => setChannelOptions(option)}
                onUpdateTicket={load}
                updateCounts={loadReportIssueAssignedTypeCounts}
              />
            ) : (
              <InboxBikeDetail />
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inboxContainer: {
    backgroundColor: '#F9F9F9',
    height: '85vh',
    borderRadius: '2em',
    padding: '1.25em 0 1.875em 0',
  },
  inboxSidebarContainer: {
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    marginBottom: '3em',
    marginTop: '.5em',
  },
  actionDivider: {
    backgroundColor: '#C1C1C1;',
    width: '1px',
    height: '1.5em',
  },
  inboxSidebarItemsContainer: {
    height: '60vh',
    overflowY: 'auto',
  },
}));
