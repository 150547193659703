export enum AppRoutes {
  DASHBOARD = '/home',
  USERS = '/users',
  REVENUE = '/revenue',
  INBOX = '/inbox',
  CHAT = '/chat',
  ANALYTICS = '/analytics',
  BIKES = '/bikes',
  BIKE_DETAILS = '/bike',
  TRIPS = '/trips',
  TRIP_DETAILS = '/trip',
  USER_DETAILS = '/user',
  PAYOUTS = '/payouts',
  UTILS = '/utils',
}
