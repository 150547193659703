import { useEffect, useState } from 'react';
import { Box, Divider, Drawer, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AppAssets } from '../../assets/index';
import AuthService from '../../external/fox-typescript/services/AuthService';
import { useHistory } from 'react-router-dom';
import { ItemType } from '../../external/ensolvers-core-frontend-mui/components/Main/AppContainer';
import { SidebarItem } from './SidebarItem';
import { SidebarTabs } from '../../utils/SidebarTabs';
import { AccesRole } from '../../external/pdl-common/utils/enums/AccesRole';
import PdlCommandAction from '../../utils/PdlCommandAction';

interface Props {
  links: PdlCommandAction[];
  staticIconsActions: ItemType;
}

export const AppSidebar = (props: Props) => {
  const classes = useStyles(props);
  const navigate = useHistory();

  // TODO: Add toggle button on small screens
  const [open, setOpen] = useState(true);
  const [sidebarOptions, setSidebarOptions] = useState<PdlCommandAction[]>(props.links);

  const getKeyIcon = (key: string): JSX.Element => {
    return props.staticIconsActions[key];
  };

  return (
    <Drawer
      variant="persistent"
      classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
      open={open}
    >
      <Box className={classes.drawerContainer}>
        <Box className={classes.toolbarIcon}>
          <AppAssets.PDLLogoSVG />
        </Box>

        <List className={classes.listContainer}>
          <Box style={{ flexGrow: 'inherit' }}>
            {sidebarOptions.map(
              (opt: PdlCommandAction, index) =>
                !opt.disabled && (
                  <SidebarItem
                    key={index}
                    path={opt.actionPath}
                    label={opt.label}
                    icon={getKeyIcon(opt.iconKey!)}
                  />
                )
            )}
          </Box>

          <Box>
            <Divider className={classes.divider} />

            <ListItem
              button
              style={{ padding: 0, marginBottom: '2.0625em' }}
              onClick={() => {
                AuthService.logOut();
                navigate.push('/');
              }}
            >
              <SidebarItem path="/logout" label="Log out" icon={<AppAssets.LogOut />} />
            </ListItem>
          </Box>
        </List>
      </Box>
    </Drawer>
  );
};

const drawerWidth = 255;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.6em',
    marginTop: '2.5em',
    marginLeft: '.4em',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#01000E',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7.5),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  drawerContainer: {
    paddingLeft: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  divider: {
    backgroundColor: '#FFFFFF',
    opacity: '.25',
    width: '85%',
    marginBottom: '1.6875em',
  },
}));
