import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    children?: React.ReactNode;
}
  
export function Title(props: Props) {
    const classes = useStyles();

    return (
        <Typography variant="h5" className={classes.title}>{props.children}</Typography>
    );
}

export function Subtitle(props: Props) {
    const classes = useStyles();

    return (
        <Typography variant="h6" className={classes.title}>{props.children}</Typography>
    );
}