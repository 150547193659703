import { AppTopBar } from '../components/common/AppTopBar';
import AuthService from '../external/fox-typescript/services/AuthService';
import { AccesRole } from '../external/pdl-common/utils/enums/AccesRole';
import { Forbidden } from './Forbidden';
import PdlUsers from './PdlUsers';
import WaitListUsers from './WaitListUsers';

interface Props {
  hideTopBar?: boolean;
}

export default function Users({ hideTopBar = false }: Props) {
  return (
    <>
      {!hideTopBar && <AppTopBar title={'Users'} />}


      {AuthService.hasAnyRole([AccesRole.ROLE_ADMIN, AccesRole.ROLE_SUPERADMIN, AccesRole.ROLE_SUPPORT]) && (<WaitListUsers />)}

      <PdlUsers />
    </>
  );
}
