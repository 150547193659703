import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button, Container, Dialog } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink, useHistory } from 'react-router-dom';
import AuthService from '../../../fox-typescript/services/AuthService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';
import CommandAction from '../../types/CommandAction';
import MainBarService from '../../MainBarService';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import AsyncAuthService from '../../../fox-typescript/services/AsyncAuthService';
import EnvironmentService from '../../../fox-typescript/services/EnvironmentService';
import CommandActionSection from '../../types/CommandActionSection';
import { Accordion, AccordionDetails, AccordionSummary } from './MenuListAccordion';
import UserAvatarComponent from '../Common/UserAvatarComponent';
import { SxProps } from '@mui/material';
import VersionUpdateChecker from '../../utils/VersionUpdateChecker';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',

    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.dark,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.dark,
  },
  menuButton: {
    marginLeft: -19,
    marginRight: 32,
  },
  menuButtonHidden: {
    display: 'none',
  },
  userFullNameContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '63px',
    overflow: 'hidden',
    paddingLeft: '10px',
    whiteSpace: 'normal',
  },
  toolbarTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  greetContainer: {
    marginRight: '2rem',
  },
  title: {
    flexGrow: 1,
  },
  displayedName: {
    display: 'inline',
    paddingBottom: '.15rem',
    textTransform: 'uppercase',
  },
  topImageHeader: {
    maxWidth: 100,
    maxHeight: 100,
  },
  nameGreet: {
    fontWeight: 400,
    display: 'inline',
  },
  drawerPaper: {
    position: 'relative',
    height: '100vh',
    overflow: 'auto',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  responsiveMenuDialog: { marginTop: theme.mixins.toolbar.minHeight },
  content: {
    backgroundColor: (props: Props) => props.backgroundColor(theme),
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  signOutButton: {
    color: '#fff',
    fontWeight: 'bold',
    background: theme.palette.secondary.dark,
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
}));

interface Props {
  /**
   * Boolean indicating if the user is logged. Used to display one or another component depending on the state.
   */
  isLogged: boolean;
  /**
   * Content wraped by the container
   */
  children: JSX.Element[];
  /**
   * JSON with the icons of the options/sections
   */
  staticIconsActions: ItemType;
  /**
   * Custom top bar to render
   */
  customTopBar?: any; // TODO: define an appropriate type
  /**
   * Options to show in the nav bar
   */
  customOptions?: CommandAction[];
  /**
   * Sections to show in the nav bar
   */
  customSections?: CommandActionSection[];
  /**
   * Title of the nav bar
   * Default value is "[Configure me by setting barName property on AppContainer.tsx]"
   */
  barName: string;
  /**
   * Image of the nav bar
   */
  barImage?: any;
  /**
   * Content of the default top bar
   */
  headerTop?: JSX.Element;
  /**
   * Boolean indicating if the top bar should be rendered
   */
  hideTopBar?: boolean;
  /**
   * Custom drawer to render
   */
  customDrawer?: JSX.Element;
  /**
   * Boolean indicating if the drawer should by open or not (initial state)
   */
  openDrawer?: boolean;
  /**
   * Boolean indicating if the user's avatar image should be displayed at the top of the navigation bar
   */
  showAvatarInAppDrawer?: boolean;
  /**
   * If responsive is true, and if the viewport width is less than (responsiveMaxEm)em menu will hide and display full screen when opened
   */
  responsive?: boolean;
  /**
   * If responsive is true, and if the viewport width is less than (responsiveMaxEm)em menu will hide and display full screen when opened
   */
  responsiveMaxEm?: number;
  /**
   * Size of the container, default value is "lg". False to disable
   */
  containerSize?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Style of the <main> tag (wrapper of the container)
   */
  mainStyle?: CSSProperties;
  /**
   * SxProps to apply to sections (Accordion component)
   */
  accordionSx?: SxProps;
  /**
   * Styles to apply to the option if is active
   */
  stylesForActiveRoute?: CSSProperties;
  /**
   * Background color of the <main> tag (wrapper of the container). Default value is `theme.palette.grey[100]theme.palette.grey[100]`
   */
  backgroundColor: (theme: Theme) => string;
  /**
   * Listener for logout event
   */
  onLogout?: () => void;
}

export interface ItemType {
  [key: string]: JSX.Element;
}

AppContainer.defaultProps = {
  barName: '[Configure me by setting barName property on AppContainer.tsx]',
  backgroundColor: (theme: Theme) => theme.palette.grey[100],
};
export default function AppContainer(props: Props) {
  const {
    isLogged,
    children,
    customTopBar,
    staticIconsActions,
    customOptions,
    customSections,
    barName,
    barImage,
    headerTop,
    customDrawer,
    hideTopBar = false,
    openDrawer = false,
    responsive = false,
    responsiveMaxEm = 45,
    containerSize = 'lg',
    mainStyle,
    onLogout,
    showAvatarInAppDrawer = false,
    accordionSx,
    stylesForActiveRoute,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  //TODO is for compile
  const navigate = useHistory();
  const [menuOptions, setMenuOptions] = useState<CommandAction[]>([]);
  const [menuSections, setMenuSections] = useState<CommandActionSection[]>([]);
  const [staticCommandActions, setStaticCommandActions] = useState<ItemType>(staticIconsActions);

  const smallViewPortQuery = `screen and (max-width: ${responsiveMaxEm}em)`;
  const [open, setOpen] = useState(openDrawer && !window.matchMedia(smallViewPortQuery).matches);
  const [smallViewport, setSmallViewport] = useState(window.matchMedia(smallViewPortQuery).matches);
  const smallViewPortQueryCb = (e: MediaQueryListEvent) => {
    setSmallViewport(e.matches);
    if (e.matches) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    return VersionUpdateChecker.init();
  }, []);

  useEffect(() => {
    window.matchMedia(smallViewPortQuery).addEventListener('change', smallViewPortQueryCb);

    return () => {
      window.matchMedia(smallViewPortQuery).removeEventListener('change', smallViewPortQueryCb);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customSections) {
      setMenuSections(customSections);
    } else if (customOptions) {
      setMenuOptions(customOptions);
    } else if (EnvironmentService.isMainBarServiceEnabled()) {
      MainBarService.read().then((res: { getContent: () => React.SetStateAction<CommandAction[]> }) => setMenuOptions(res.getContent()));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customSections) {
      setMenuSections(customSections);
    } else if (customOptions) {
      setMenuOptions(customOptions);
    } else {
      setMenuOptions([]);
    }
  }, [customOptions, customSections]);

  const handleSignOut = () => {
    onLogout && onLogout();

    if (EnvironmentService.usesAsyncStorageStrategy()) {
      AsyncAuthService.logOut();
    } else {
      AuthService.logOut();
    }

    //TODO pass the route to the login page
    navigate.push('/');
  };

  const getKeyIcon = (key: string): JSX.Element => {
    return staticCommandActions[key];
  };

  const getMenuList = () => {
    return (
      <>
        {menuOptions.map((opt: CommandAction, index) => (
          <ListItem
            key={index}
            button
            onClick={() => {
              navigate.push(opt.actionPath);
              if (responsive && smallViewport) {
                setOpen(false);
              }
            }}
          >
            <ListItemIcon>{opt.icon || getKeyIcon(opt.iconKey!)}</ListItemIcon>
            <ListItemText primary={opt.label} />
          </ListItem>
        ))}
        <ListItem button onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('Log Out')} />
        </ListItem>
      </>
    );
  };

  const getMenuSectionList = () => (
    <>
      {menuSections.map((section, index) => {
        return (
          <Accordion expanded={responsive && smallViewport ? true : undefined} key={index}>
            <AccordionSummary
              closable={responsive && smallViewport ? false : undefined}
              title={section.label}
              icon={section.icon}
              sx={{ whiteSpace: 'pre-wrap' }}
            />
            <AccordionDetails sx={accordionSx} style={!open ? { paddingLeft: 0 } : {}}>
              <List disablePadding>
                {section.items.map((opt: CommandAction, index) => (
                  <NavLink
                    to={opt.actionPath}
                    activeStyle={stylesForActiveRoute}
                    style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}
                  >
                    <ListItem
                      button
                      onClick={() => {
                        if (responsive && smallViewport) {
                          setOpen(false);
                        }
                      }}
                      key={index}
                    >
                      <ListItemIcon>{opt.icon || getKeyIcon(opt.iconKey!)}</ListItemIcon>
                      <ListItemText primary={opt.label} />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div style={{ marginTop: 'auto' }}>
        <ListItem button onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('Log Out')} />
        </ListItem>
      </div>
    </>
  );

  const getTopBar = () => (
    <AppBar
      position="absolute"
      className={responsive && smallViewport ? classes.appBar : clsx(classes.appBar, open && classes.appBarShift)}
      style={responsive && smallViewport ? { zIndex: 1301 } : undefined}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen((p) => !p)}
          className={responsive && smallViewport ? classes.menuButton : clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.toolbarTextContainer}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {barImage && <img src={barImage} className={classes.topImageHeader} />}
            {barName}
          </Typography>
        </div>

        {headerTop}

        {/** TODO enable when model is ready to return also the username from backend  */}
        {isLogged && false && (
          <>
            <div className={classes.toolbarTextContainer}>
              <div className={classes.greetContainer}>
                <Typography component="h3" variant="h6" color="inherit" noWrap className={classes.nameGreet}>
                  Welcome,{' '}
                </Typography>
                <Typography component="h3" variant="h6" color="inherit" noWrap className={classes.displayedName}>
                  User
                </Typography>
              </div>
            </div>

            <Button className={classes.signOutButton} onClick={handleSignOut}>
              SIGN OUT
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );

  const getAppDrawer = () => {
    const user = AuthService.getUser();
    return responsive && smallViewport ? (
      <Dialog className={classes.responsiveMenuDialog} open={open} onClose={() => setOpen(false)} fullWidth={true}>
        {customSections ? (
          getMenuSectionList()
        ) : (
          <List>
            <div>{getMenuList()}</div>
          </List>
        )}
      </Dialog>
    ) : (
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {showAvatarInAppDrawer && <UserAvatarComponent user={user} sx={{ marginRight: '5px' }} />}
          <div className={classes.userFullNameContainer}>{`${user.firstName} ${user.lastName}`}</div>
          <IconButton onClick={() => setOpen((p) => !p)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {customSections ? (
          getMenuSectionList()
        ) : (
          <List>
            <div>{getMenuList()}</div>
          </List>
        )}
      </Drawer>
    );
  };

  return (
    <div>
      <CssBaseline />
      {customTopBar && customTopBar(() => setOpen((p) => !p))}
      <div className={classes.root}>
        {isLogged && !hideTopBar && !customTopBar && getTopBar()}
        {isLogged && (customDrawer ? customDrawer : getAppDrawer())}

        <main id="app-container-main" className={classes.content} style={mainStyle}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={containerSize} className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
    </div>
  );
}
