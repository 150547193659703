import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import { Autocomplete } from '@mui/material';
import { Controller, ControllerFieldState, ControllerProps, ControllerRenderProps, UseFormStateReturn } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker, DatePickerView } from '@material-ui/pickers';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Debouncer } from '../../../fox-typescript/utils/Debouncer';

type Optional<T> = T | null;
interface ValidationProps {
  /**
   * React hook form property
   */
  register?: any; // TODO type
  /**
   * React hook form property
   */
  control?: any; // TODO type
  /**
   * React hook form property
   */
  errors?: any; // TODO type
  /**
   * React hook form property
   */
  customHelperText?: any; // TODO type
}
interface ComponentProps<T> extends ValidationProps {
  /**
   * Name of the property/variable that will be managed by this input
   */
  propertyName: string;
  /**
   * Label of this input
   */
  label: string;
  /**
   * Input default value
   */
  defaultValue?: T;
}

export interface InputFieldProps<T> extends ComponentProps<T> {
  /**
   * Type of the input. e.g: number, text, etc
   */
  inputType?: string;
  /**
   * `true` if the input is required
   */
  required?: boolean;
  /**
   * `true` if the input is read only
   */
  readOnly?: boolean;
  /**
   * MUI Texfield props
   */
  internalProps?: TextFieldProps;
  /**
   * Rex expression that the input must satisfy to be valid
   */
  pattern?: RegExp;
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium';
  /**
   * Input style variant
   */
  variant?: FormControlProps['variant'];
}

interface InputNumberFieldProps<T> extends InputFieldProps<T> {
  /**
   * If `true`, the min and max properties will not be ignored
   */
  enableEditInputProps?: boolean;
  /**
   * Custom helper text to show if there was an error
   */
  customHelperText?: string;
  /**
   * Min accepted value
   */
  min?: number;
  /**
   * Max accepted value
   */
  max?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '1000px',
    },
  })
);

export const ValidationContext = React.createContext<Optional<ValidationProps>>(null);

interface DateFieldProps extends InputFieldProps<Date> {
  /**
   * Show clear action in picker dialog
   * @default
   * false
   */
  clearable?: boolean;
  /**
   * If `true`, includes time input
   */
  time?: boolean;
  /**
   * Min accepted date
   */
  minDate?: Date;
  /**
   * Max accepted date
   */
  maxDate?: Date;
  /**
   * Array of views to show
   * @type {Array<"year" | "date" | "month">}
   */
  views?: Array<DatePickerView>;
  /**
   * First view to show in DatePicker
   */
  openTo?: DatePickerView;

  utils?: any;
  /**
   * If `true`, shows dates in UTC
   */
  toUTC?: boolean;
}

export function DateField(props: DateFieldProps) {
  return (
    <div>
      <Controller
        name={props.propertyName}
        control={props.control}
        rules={{ required: props.required }}
        defaultValue={props.required ? props.defaultValue || new Date() : null}
        render={({ field }) => (
          <MuiPickersUtilsProvider utils={props.utils ?? DateFnsUtils}>
            <DatePicker
              minDate={props.minDate}
              maxDate={props.maxDate}
              views={props.views}
              openTo={props.openTo}
              label={props.label}
              {...field}
              inputVariant={props.variant ?? 'filled'}
              error={!!props.errors[props.propertyName]}
              helperText={props.errors[props.propertyName] && props.label + ' should be provided'}
              clearable={props.clearable}
              readOnly={props.readOnly}
              disabled={props.readOnly}
            />
            {props.time && (
              <TimePicker
                label={props.label + ' hour'}
                {...field}
                inputVariant={props.variant ?? 'filled'}
                error={!!props.errors[props.propertyName]}
                helperText={props.errors[props.propertyName] && props.label + ' should be provided'}
                clearable={props.clearable}
                readOnly={props.readOnly}
                disabled={props.readOnly}
              ></TimePicker>
            )}
          </MuiPickersUtilsProvider>
        )}
      />
    </div>
  );
}

export function CheckboxComponent(props: InputFieldProps<string>) {
  return (
    <div>
      <Controller
        name={props.propertyName}
        control={props.control}
        rules={{ required: props.required }}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label={props.label}
            control={
              <Checkbox
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 20 },
                  marginLeft: '10px',
                }}
                disabled={props.readOnly}
              />
            }
          />
        )}
      />
    </div>
  );
}

export function TextFieldComponent(props: InputFieldProps<string>) {
  const classes = useStyles();
  const context = React.useContext(ValidationContext);
  const finalProps = context ? { ...props, ...context } : props;

  return (
    <div>
      <Controller
        name={finalProps.propertyName}
        control={finalProps.control}
        rules={{ required: finalProps.required, pattern: finalProps.pattern }}
        defaultValue=""
        render={({ field }) => (
          <TextField
            size={finalProps.size}
            label={finalProps.label}
            {...field}
            {...finalProps.internalProps}
            className={classes.fullWidth}
            disabled={!!finalProps.readOnly}
            type={finalProps.inputType || 'text'}
            variant={finalProps.variant ?? 'filled'}
            error={!!finalProps.errors[finalProps.propertyName]}
            helperText={finalProps.errors[finalProps.propertyName] && (finalProps.customHelperText || finalProps.label + ' should be provided')}
          />
        )}
      />
    </div>
  );
}

NumberField.defaultProps = {
  enableEditInputProps: false,
};

export function NumberField(props: InputNumberFieldProps<string>) {
  const context = React.useContext(ValidationContext);
  const finalProps = context ? { ...props, ...context } : props;

  return (
    <div>
      <Controller
        name={finalProps.propertyName}
        control={finalProps.control}
        rules={{ required: finalProps.required, pattern: finalProps.pattern }}
        defaultValue={finalProps.defaultValue}
        render={({ field }) => (
          <TextField
            size={finalProps.size}
            label={finalProps.label}
            {...field}
            variant={finalProps.variant ?? 'filled'}
            type="number"
            disabled={!!finalProps.readOnly}
            InputProps={props.enableEditInputProps ? { inputProps: { min: props.min, max: props.max || Number.MAX_VALUE } } : {}}
            error={!!finalProps.errors[finalProps.propertyName]}
            helperText={
              finalProps.errors[finalProps.propertyName] && (finalProps.customHelperText || finalProps.label + ' should be provided')
            }
            defaultValue={finalProps.defaultValue}
          />
        )}
      />
    </div>
  );
}

const useAutoCompleteStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
  },
}));

interface AutoCompleteProps extends InputFieldProps<string> {
  label: string;
  options: AutoCompleteOption[];
  name?: string;
  id?: string;
  error?: boolean;
  customClassName?: string;
  required?: boolean;
  disabled?: boolean;
  inputSize?: 'small' | 'medium';
}

export const AutoCompleteField = (props: AutoCompleteProps) => {
  const context = React.useContext(ValidationContext);
  const classes = useAutoCompleteStyles();
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);

  const { required, disabled, inputSize, customClassName } = props;
  const finalProps = context ? { ...props, ...context } : props;

  useEffect(() => {
    if (!!finalProps.options) {
      const data = finalProps.options;
      setOptions(data);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  const getDefaultValue = (field: ControllerRenderProps): AutoCompleteOption | undefined => {
    const defaultValue: AutoCompleteOption = {
      label: field.value,
      externalId: `${options.findIndex((opt) => opt.label === field.value)}`,
    };

    return defaultValue;
  };

  return (
    <Controller
      name={finalProps.propertyName}
      control={finalProps.control}
      rules={{ required: finalProps.required }}
      defaultValue=""
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            freeSolo
            className={customClassName ?? classes.container}
            options={options}
            fullWidth
            disabled={disabled ?? false}
            size="medium"
            getOptionLabel={(option) => {
              if (!option) {
                return '';
              }

              if (typeof option === 'string') {
                return option;
              } else {
                return option?.label;
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (!Array.isArray(finalProps.options)) {
                setInputValue(newInputValue);
              } else {
                field.onChange(newInputValue.trim());
              }
            }}
            onChange={(_, data) => {
              field.onChange(data?.label);
            }}
            defaultValue={getDefaultValue(field)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  {...finalProps.internalProps}
                  label={finalProps.label}
                  variant={finalProps.variant ?? 'filled'}
                  error={!!finalProps.errors[finalProps.propertyName]}
                  helperText={finalProps.errors[finalProps.propertyName] && finalProps.label + ' should be provided'}
                  required={required ?? true}
                  size={inputSize ?? 'small'}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

interface SelectOption {
  value: string;
  label: string;
}

const selectFieldStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface SelectFieldProps extends InputFieldProps<string> {
  /**
   * Options that the field must display
   */
  options: SelectOption[];
  /**
   * MUI Select props
   */
  internalSelectProps?: SelectProps;
}

export function SelectFieldComponent(props: SelectFieldProps) {
  const classes = selectFieldStyle();
  const context = React.useContext(ValidationContext);
  const finalProps = context ? { ...props, ...context } : props;

  return (
    <div>
      <Controller
        name={finalProps.propertyName}
        control={finalProps.control}
        rules={{ required: finalProps.required }}
        defaultValue={finalProps.defaultValue}
        render={({ field }) => (
          <FormControl
            className={classes.formControl}
            variant={finalProps.variant ?? 'filled'}
            error={!!finalProps.errors[finalProps.propertyName]}
            disabled={finalProps.readOnly}
          >
            <InputLabel>{finalProps.label}</InputLabel>
            <Select
              {...field}
              {...finalProps.internalSelectProps}
              onChange={(e) => {
                //We need to do this because otherwise the field itself is not refreshed.This is related to react-hook-form
                field.onChange(e);

                // Extra logic
                finalProps.internalSelectProps?.onChange && finalProps.internalSelectProps.onChange(e, field);
              }}
            >
              {finalProps.options.map((o) => (
                <MenuItem value={o.value}>{o.label}</MenuItem>
              ))}
            </Select>
            {!!finalProps.errors[finalProps.propertyName] && (
              <FormHelperText>{finalProps.errors[finalProps.propertyName] && finalProps.label + ' must be provided'}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}

interface AutoCompleteFieldProps extends InputFieldProps<string> {
  /**
   * options can be an Array of AutoCompleteOption or an async function that will requests the values to backend using the input text
   */
  options: AutoCompleteOption[] | ((text: string) => Promise<AutoCompleteOption[]>);
  /**
   * if a function is passed in options property, we can ajust the debounce ms applied in each call (default to 800ms)
   */
  debounceMs?: number;
  /**
   * Allow to display the previous value in the textfield
   */
  editWithPreviousValue?: boolean;
}

export interface AutoCompleteOption {
  label: string;
  externalId: string;
}

const debouncer = new Debouncer(800);

export function AutoCompleteComponent(props: AutoCompleteFieldProps) {
  const classes = useStyles();
  const context = React.useContext(ValidationContext);
  const finalProps = context ? { ...props, ...context } : props;

  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const getDefaultValue = (field: ControllerRenderProps): AutoCompleteOption | undefined => {
    if (finalProps.editWithPreviousValue) {
      const defaultValue: AutoCompleteOption = {
        label: field.value,
        externalId: `${options.findIndex((opt) => opt.label === field.value)}`,
      };

      return defaultValue;
    }

    return undefined;
  };

  useEffect(() => {
    props.debounceMs && debouncer.setLimit(props.debounceMs);
  }, []);

  useEffect(() => {
    if (!Array.isArray(finalProps.options)) {
      if (!inputValue) {
        setOptions([]);
      } else {
        setLoading(true);
        debouncer.debounceKeepingLastCall(async () => {
          try {
            const data = await (finalProps.options as any)(inputValue);
            setOptions(data);
          } catch {
            console.error('[ERROR] Error geting options for autocomplete: ' + props.propertyName);
          } finally {
            setLoading(false);
          }
        });
      }
    }
  }, [inputValue]);

  return (
    <div>
      <Controller
        name={finalProps.propertyName}
        control={finalProps.control}
        rules={{ required: finalProps.required }}
        defaultValue=""
        render={(props) => (
          <Autocomplete
            onInputChange={(event, newInputValue) => {
              if (!Array.isArray(finalProps.options)) {
                setInputValue(newInputValue);
              }
            }}
            defaultValue={getDefaultValue(props.field)}
            loading={loading}
            options={loading ? [] : Array.isArray(finalProps.options) ? finalProps.options : options}
            getOptionLabel={(option) => option.label}
            onChange={(_, data) => {
              props.field.onChange(data?.externalId);
            }}
            disabled={!!finalProps.readOnly}
            filterOptions={Array.isArray(finalProps.options) ? undefined : (x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                {...finalProps.internalProps}
                label={finalProps.label}
                variant={finalProps.variant ?? 'filled'}
                className={classes.fullWidth}
                error={!!finalProps.errors[finalProps.propertyName]}
                helperText={finalProps.errors[finalProps.propertyName] && finalProps.label + ' should be provided'}
              />
            )}
          />
        )}
      />
    </div>
  );
}

export function UploadFileComponent(props: InputFieldProps<string>) {
  const classes = fileStyles();
  const context = React.useContext(ValidationContext);
  const finalProps = context ? { ...props, ...context } : props;

  return (
    <Controller
      name={finalProps.propertyName}
      control={finalProps.control}
      rules={{ required: finalProps.required }}
      render={(field) => (
        <>
          <Typography className={classes.title}>{finalProps.label}</Typography>
          <Box className={classes.inputBox}>
            <Button
              startIcon={<FileUploadIcon />}
              variant="contained"
              component="label"
              className={classes.inputButton}
              // on error, button text color changes to red
              style={!!finalProps.errors[finalProps.propertyName] ? { color: '#f44336' } : {}}
            >
              <input
                type="file"
                onChange={(e) => {
                  field.field.onChange(e.target.files?.item(0));
                }}
                hidden
              />
              Choose File
            </Button>
            <Box className={classes.inputTextBox}>
              {!!finalProps.errors[finalProps.propertyName] ? (
                <span className={classes.errorMessage}>File should be provided</span>
              ) : !field.field.value ? (
                <Typography>No file chosen</Typography>
              ) : (
                <Typography>{field.field.value.name}</Typography>
              )}
            </Box>
          </Box>
        </>
      )}
    />
  );
}

const fileStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBox: {
      display: 'flex',
      alignItems: 'center',
    },
    inputButton: {
      marginLeft: '10px',
      marginTop: '5px',
      width: '30%',
    },
    inputTextBox: {
      width: '70%',
      marginLeft: '10px',
    },
    title: {
      marginLeft: '10px',
      fontSize: '16px',
    },
    errorMessage: {
      color: '#f44336',
      marginTop: '3px',
      fontFamily: 'Helvetica',
      letterSpacing: '0.03333rem',
    },
  })
);
