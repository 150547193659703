import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import { AccountWaitList } from '../models/AccountWaitList';
import environment from '../environment.json';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import { WaitlistAccountsPageRequestDTO } from '../models/WaitlistAccountsPageRequestDTO';
import { IPage } from '../external/fox-typescript/core/IPage';

class AccountWaitListService extends GenericCrudService<AccountWaitList> {
  async getAll(page: number, pageSize: number): Promise<ApiResponse<PagedResult<AccountWaitList>>> {
    throw new Error('Not implemented');
  }

  async create(instance: AccountWaitList): Promise<ApiResponse<AccountWaitList>> {
    throw new Error('Not implemented');
  }

  async update(instance: AccountWaitList): Promise<ApiResponse<AccountWaitList>> {
    throw new Error('Not implemented');
  }

  async delete(instance: AccountWaitList): Promise<ApiResponse<AccountWaitList>> {
    throw new Error('Not implemented');
  }

  async getAccountsPage(
    filters: WaitlistAccountsPageRequestDTO
  ): Promise<PagedResult<AccountWaitList>> {
    const res = await httpClient.getWithParams<IPage<AccountWaitList>>(
      `${this.basePath}/paginated`,
      filters
    );

    return PagedResult.fromIPage(res.getContent());
  }

  async approve(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.post(`${this.basePath}/${externalId}/approve`, {});
  }

  async resendAccountCreationEmail(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.post(`${this.basePath}/${externalId}/resend-account-creation-email`, {});
  }

  async disable(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.post(`${this.basePath}/${externalId}/disable`, {});
  }
}

export default new AccountWaitListService(`${environment.baseURL}/portal/account-wait-list`);
