import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../../../fox-typescript/services/AuthService";
import { useHistory } from "react-router-dom";
import { TextField, Typography } from "@material-ui/core";
import customAuthService from "../../../fox-typescript/services/CustomAuthService";
import { preProcessFile, VoidExpression } from "typescript";
import GenericResponse from "../../../fox-typescript/core/GenericResponse";
import GoogleIcon from "@mui/icons-material/Google";
import environment from "../../../../environment.json";
import { Theme } from "@material-ui/core";
import { propsToClassKey } from "@mui/styles";
import { trace } from "../../../fox-typescript/utils/Logging";
import AsyncAuthService from "../../../fox-typescript/services/AsyncAuthService";
import EnvironmentService from "../../../fox-typescript/services/EnvironmentService";

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    height: "100vh",
  },
  image: (props: Props) => ({
    backgroundImage: `url(${
      props.backgroundImage ||
      "https://source.unsplash.com/featured/?" + props.topic
    })`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  }),
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.contrastText,
  },
  topImageHeader: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  loginColumn: (props: Props) => ({
    backgroundColor: props.backgroundColor,
  }),
}));

interface Props {
  /**
    Boolean idicating if the view should show register form instead of login form
    Default value is false
  */
  register?: boolean;
  /**
   * Renders email input in login/register form
   * Default value is true
   */
  useEmail?: boolean;
  /**
   * Boolean indicating if the view should render register button that will navigate to /register route
   * Default value is true
   */
  allowToRegister?: boolean;
  /**
   * Topic that will be use to query images from unsplash API.
   * These images will be set as backgrund images of this component.
   * Default value is "sky"
   */
  topic?: string;
  /**
   * Title to place above the form (optional)
   */
  title?: string;
  /**
   * Boolean idicating if the email should converted to lower case in onChange event handler (optional)
   */
  convertEmailToLowercase?: boolean;

  // OAuth props
  /**
   * Enables sign in with google (OAuth) button
   * Default value is false
   */
  loginWithGoogle?: boolean;
  /**
   * Url to which the "sign in with google" button will navigate when clicked
   * Default value is `environment.baseURL + "/oauth/connect/google"`
   */
  oAuthRedirectUrl?: string;
  /**
   * Handler that is called when the user successfully logs in (via oauth) (optional)
   */
  onOAuthSuccess?: (queryParams: URLSearchParams) => void;

  /**
   * Handler that is called when the user successfully logs in
   */
  onLoginSuccess: () => void;
  /**
   * Url to the image logo to place above the form (optional)
   */
  imageLogo?: any;
  /**
   * Background color of the component
   * Default value is "white"
   */
  backgroundColor: string;
  /**
   * Background image of the component
   * Default value is `"https://source.unsplash.com/featured/?" + props.topic`
   */
  backgroundImage?: string;
  /**
   * Style customization for the component (optional)
   */
  customStyle?: React.CSSProperties;
  /**
   * If user logs in sucessfully, we verify that the user has at least one of these roles.
   */
  allowedRoles?: string[];
  /**
   * @deprecated TODO: remove this prop that is not used at all
   */
  showTitle: boolean;
  /**
   * @deprecated TODO: remove this prop that is not used at all
   */
  loginWithCode?: boolean;
}

const defaultProps: Props = {
  register: false,
  useEmail: true,
  loginWithCode: false,

  loginWithGoogle: false,
  oAuthRedirectUrl: environment.baseURL + "/oauth/connect/google",

  allowToRegister: true,
  topic: "sky",
  showTitle: true,
  backgroundColor: "white", // TODO use white by default
  onLoginSuccess: () => {},
};

Login.defaultProps = defaultProps;

export default function Login(props: Props) {
  const classes = useStyles(props);
  const history = useHistory();
  const label = props.register ? "Register" : "Login";
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ value: false, text: "" });
  const [accessKey, setAccessKey] = useState("");
  const [accessKeyName, setAccessKeyName] = useState("Access Key Name");
  const [accessKeyError, setAccessKeyError] = useState({
    value: false,
    text: "",
  });
  const [accessKeyConfirmation, setAccessKeyConfirmation] = useState("");
  const [accessKeyConfirmationError, setAccessKeyConfirmationError] = useState({
    value: false,
    text: "",
  });
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState({
    value: false,
    text: "",
  });
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState({
    value: false,
    text: "",
  });
  const [buttonLabel, setButtonLabel] = useState("Login");
  const [loginUrl, setLoginUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForm = (e: any) => {
    e.preventDefault();
    if (props.register) {
      handleRegister();
    } else {
      handleLogin();
    }
  };

  /* User for external auth (SSO mostly)*/
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("_t");
    const expiresAt = queryParams.get("_t_expiration");

    if (token) {
      props.onOAuthSuccess && props.onOAuthSuccess(queryParams);

      if (expiresAt) {
        onLoginSuccess(token, new Date(expiresAt));
      } else {
        onLoginSuccess(token);
      }
    }
  }, []);

  const handleLogin = () => {
    if (!loginUrl) {
      getLogin();
    } else {
      login();
    }
  };

  const handleRegister = () => {
    // TODO validate all fields
    if (true) {
      register();
    } else {
      alert("Please check your information");
    }
  };

  async function getLogin() {
    if (validateEmail()) {
      setLoading(true);
      try {
        const result = await customAuthService.getLoginMethod(encodeURIComponent(email));
        result
          .onSuccess(() => onGetLoginSuccess(result.getContent()))
          .onError(() => alert("Error getting login method"));
      } catch (e) {
        alert("Get login failed");
        setLoading(false);
      }
    }
  }

  function onGetLoginSuccess(result: string) {
    let resultAsJSON = JSON.parse(result);
    setLoginUrl(resultAsJSON.loginUrl);
    setAccessKeyName(resultAsJSON.label);
    setAccessKeyError((state) => ({ ...state, text: resultAsJSON.helper }));
    setButtonLabel("Login");
    setLoading(false);
  }

  async function login() {
    if (validateAccessKey()) {
      setLoading(true);
      try {
        const result = await customAuthService.validateLogin(
          loginUrl,
          email,
          accessKey
        );
        result
          .onSuccess(() => {
            const { token, expiresAt, user } = result.getContent();
            const { allowedRoles } = props;

            if (allowedRoles) {
              // We verify that the user has any of the specified roles.
              const isAllowed = AuthService.hasAnyRole(allowedRoles, user);

              if (!isAllowed) {
                alert("Not authorized");
                return;
              }
            }

            onLoginSuccess(token, expiresAt);

            if (EnvironmentService.usesAsyncStorageStrategy()) {
              AsyncAuthService.storeUser(user);
            } else {
              AuthService.storeUser(user);
            }
          })
          .onError(() => alert("Invalid Credentials"));
        setLoading(false);
      } catch (e) {
        alert("There was a problem logging you in");
        setLoading(false);
      }
    }
  }

  function onLoginSuccess(token: string, expiresAt?: Date) {
    if (token.length > 0) {
      if (EnvironmentService.usesAsyncStorageStrategy()) {
        AsyncAuthService.storeToken(token, expiresAt);
      } else {
        AuthService.storeToken(token, expiresAt);
      }

      props.onLoginSuccess();
      window.location.reload();
    } else {
      alert("An error ocurred, please try again");
    }
  }

  async function register() {
    if (validateRegister()) {
      setLoading(true);
      try {
        const result = await customAuthService.register(
          firstName,
          lastName,
          accessKey,
          accessKeyConfirmation,
          email
        );
        result
          .onSuccess(() => {
            onRegisterResponse(result.getContent());
          })
          .onError(() => alert("There was an error signing up"));
        setLoading(false);
      } catch (e) {
        // TODO create alert component (snackbar)
        alert("There was a problem trying to signin up");
        setLoading(false);
      }
    }
  }

  //TODO check the object response from backend
  function onRegisterResponse(response: any) {
    let resultAsJSON = JSON.parse(response);
    alert(resultAsJSON.helper);
  }

  function validateEmail() {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError({ value: false, text: "" });
      return true;
    } else {
      setEmailError({ value: true, text: "Invalid Email" });
      return false;
    }
  }

  function validateAccessKey() {
    const keyIsValid = accessKey.length > 0;
    setAccessKeyError((state) => ({ value: !keyIsValid, text: state.text }));
    return keyIsValid;
  }

  function validateAccessKeyConfirmation() {
    if (accessKey === accessKeyConfirmation) {
      setAccessKeyConfirmationError({ value: false, text: "" });
      return true;
    } else {
      setAccessKeyConfirmationError({
        value: true,
        text: "Passwords dont match",
      });
      return false;
    }
  }

  function validateString(
    string: string,
    setStateError: React.Dispatch<
      React.SetStateAction<{
        value: boolean;
        text: string;
      }>
    >
  ) {
    if (string.length > 3) {
      setStateError({ value: false, text: "" });
      return true;
    } else {
      setStateError({ value: true, text: "Invalid value" });
      return false;
    }
  }

  function validateRegister() {
    // validate all fields
    if (
      validateString(firstName, setFirstNameError) &&
      validateString(lastName, setLastNameError) &&
      validateEmail() &&
      validateAccessKey() &&
      validateAccessKeyConfirmation()
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (props.register) {
      setButtonLabel("Register");
    } else {
      setButtonLabel("Continue");
    }
  }, [props.register]);

  return (
    <Grid container component="main" className={classes.root} style={props.customStyle}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        className={classes.loginColumn}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          {props.register && (
            <Typography variant="h3" component="h3">
              {label}
            </Typography>
          )}
          {props.imageLogo && (
            <img src={props.imageLogo} className={classes.topImageHeader} />
          )}
          {props.title && (
            <Typography variant="h3" component="h3">
              {props.title}
            </Typography>
          )}
          <form
            id="login-form"
            name="login-form"
            className={classes.form}
            onSubmit={handleForm}
            noValidate
            autoComplete="off"
          >
            {props.register && (
              <>
                <TextField
                  id="first-name"
                  label="First Name"
                  variant="standard"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={firstNameError.value}
                  helperText={firstNameError.text}
                  required
                />

                <TextField
                  id="last-name"
                  label="Last Name"
                  variant="standard"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  error={lastNameError.value}
                  helperText={lastNameError.text}
                  required
                />
              </>
            )}
            {props.useEmail && (
              <TextField
                id="email"
                label="Email"
                variant="standard"
                value={email}
                onChange={(e) => props.convertEmailToLowercase? 
                  setEmail(e.target.value.toLowerCase()): 
                  setEmail(e.target.value)   
                }
                error={emailError.value}
                helperText={emailError.text}
                autoFocus
                required
              />
            )}
            {loginUrl && (
              <TextField
                id="access-key"
                label={accessKeyName}
                variant="standard"
                type="password"
                value={accessKey}
                onChange={(e) => setAccessKey(e.target.value)}
                error={accessKeyError.value}
                helperText={accessKeyError.text}
                autoFocus
                required
              />
            )}
            {props.register && (
              <>
                <TextField
                  id="access-key"
                  label="Password"
                  variant="standard"
                  type="password"
                  value={accessKey}
                  onChange={(e) => setAccessKey(e.target.value)}
                  error={accessKeyError.value}
                  helperText={accessKeyError.text}
                  required
                />
                <TextField
                  id="access-key-confirmation"
                  label="Confirm Password"
                  variant="standard"
                  type="password"
                  value={accessKeyConfirmation}
                  onChange={(e) => setAccessKeyConfirmation(e.target.value)}
                  error={accessKeyConfirmationError.value}
                  helperText={accessKeyConfirmationError.text}
                  required
                />
              </>
            )}
          </form>
          {props.useEmail && (
            <Button
              className={classes.submit}
              disabled={loading}
              type="submit"
              form="login-form"
            >
              <i hidden={loading} className="fas fa-spinner">
                {buttonLabel}
              </i>
            </Button>
          )}
          {!props.register && props.allowToRegister && (
            <Button
              style={{ textTransform: "none" }}
              onClick={() => history.push("/register")}
            >
              <p style={{ textDecorationLine: "underline" }}>Register</p>
            </Button>
          )}

          {/* Login with google (OAuth) */}
          {props.loginWithGoogle && props.oAuthRedirectUrl && (
            <Button
              variant="contained"
              className={classes.submit}
              href={props.oAuthRedirectUrl}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
