import { Avatar } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import User from '../../../fox-typescript/core/User';

interface Props {
  /**
   * User to display
   */
  user: User;
  /**
   * sx prop of the Avatar component
   */
  sx?: SxProps<Theme>;
  /**
   * if `true`, only displays a generic local image
   */
  forceGenericAvatar?: boolean;
}

export default function UserAvatarComponent(props: Props) {
  const date = new Date();
  return props.user.avatarUrl ? (
    <Avatar sx={props.sx} src={`${props.user.avatarUrl}?date=${date}`} />
  ) : props.forceGenericAvatar ? (
    <Avatar sx={props.sx} />
  ) : (
    <Avatar sx={props.sx}>{`${props.user.firstName[0]}${props.user.lastName[0]}`}</Avatar>
  );
}
