import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import environment from '../environment.json';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import { IPage } from '../external/fox-typescript/core/IPage';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import { BikesPageRequestDTO } from '../models/BikesPageRequestDTO';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { Bike } from '../external/pdl-common/model/Bike';

class BikeService extends GenericCrudService<Bike> {
  async getBikesPage(filters: BikesPageRequestDTO): Promise<PagedResult<Bike>> {
    const res = await httpClient.getWithParams<IPage<Bike>>(`${this.basePath}/paginated`, filters);
    return PagedResult.fromIPage(res.getContent());
  }

  async approve(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.put(`${this.basePath}/${externalId}/approve`, {}, true);
  }

  async deny(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.put(`${this.basePath}/${externalId}/deny`, {}, true);
  }

  async restoreStatus(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.put(`${this.basePath}/${externalId}/pending`, {}, true);
  }

  async disable(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.put(`${this.basePath}/${externalId}/disable`, {}, true);
  }

  async activate(externalId: string): Promise<ApiResponse<string>> {
    return await httpClient.put(`${this.basePath}/${externalId}/activate`, {}, true);
  }
}

export default new BikeService(`${environment.baseURL}/portal/bikes`);
