/**
 * This class performs a debouncing over function calls - i.e. it control the rate at which a function can be called in 
 * specific timespan
 * 
 */
export class Debouncer {
    private lock: boolean;
    private timeoutId?: number;

    /**
     * Creates a new debouncer
     * @param limit milliseconds that must pass until the function is effectively called again
     */
    public constructor(private limit: number) {
        this.lock = false;
    }

    public setLimit(limit: number) {
        this.limit = limit;
    }

    /**
     * Performs debouncing over a function. If this method has been called before the amount of milliseconds
     * that have been set up in `limit` passed, the call is discarded
     * @param fn the function to be called/debounced
     * 
     * @example
     * ```ts
     * const debouncer = new Debouncer(1000);
     * debouncer.debounce(console.log("Call #1"))
     * debouncer.debounce(console.log("Call #2"))      // this call to console.log should be discarded
     * debouncer.debounce(console.log("Call #3"))      // this call to console.log should be discarded
     * setTimeout(() => console.log("Call #4"), 1500)) // this call to console.log should pass since 1000ms passed
     * ```
     */
    public debounce(fn: () => void): void {
        if (this.lock) {
            return;
        }

        this.lock = true;
        fn();
        setTimeout(() => (this.lock = false), this.limit);
    }

    /**
     * Performs debouncing over a function. 
     * If this method is called and there is a function pending to be called, this function is canceled 
     * and replaced with the new function and the timeout is reset.
     * @param fn the function to be called/debounced
     * 
     * @example
     * ```ts
     * const debouncer = new Debouncer(1000);
     * debouncer.debounce(console.log("Call #1"))
     * debouncer.debounce(console.log("Call #2"))      // this call will replace Call #1
     * debouncer.debounce(console.log("Call #3"))      // this call will replace Call #2
     * setTimeout(() => console.log("Call #4"), 1500)) // Call #3 will execute and this call to console.log should pass since 1000ms passed after last call
     * ```
     */
    public debounceKeepingLastCall(fn: () => void): void {
        if (this.timeoutId != undefined) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => (this.timeoutId = undefined, fn()), this.limit) as any;
        } else {
            fn();
            this.timeoutId = setTimeout(() => (this.timeoutId = undefined), this.limit) as any;
            return;
        }
    }

    on(fn: () => void) {
        return () => this.debounce(fn);
    }
}
