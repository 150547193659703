import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import environment from '../environment.json';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import ReportIssue from '../external/pdl-common/model/ReportIssue';

class ReportIssueService extends GenericCrudService<ReportIssue> {
  async closeTicket(externalId: string): Promise<ApiResponse<ReportIssue>> {
    return await httpClient.put(`${this.basePath}/close/${externalId}`, {}, true);
  }

  async reopenTicket(externalId: string): Promise<ApiResponse<ReportIssue>> {
    return await httpClient.put(`${this.basePath}/reopen/${externalId}`, {}, true);
  }

  async assignTicket(
    externalId: string,
    supportExternalId: string
  ): Promise<ApiResponse<ReportIssue>> {
    return await httpClient.post(
      `${this.basePath}/assign/${externalId}`,
      { supportExternalId },
      true
    );
  }

  async unassignTicket(externalId: string): Promise<ApiResponse<ReportIssue>> {
    return await httpClient.post(`${this.basePath}/unassign/${externalId}`, {}, true);
  }

  async checkOpenRenterIssuesByReservationId(externalId: string): Promise<ApiResponse<number>> {
    return await httpClient.get(`${this.basePath}/check-issues/${externalId}`, true);
  }

}

export default new ReportIssueService(`${environment.baseURL}/portal/report-issue`);
