import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  label?: string;
  description?: string | JSX.Element;
  open: boolean;
  setOpen: (state: boolean) => void;
  onConfirm: () => void;
}

export const ConfirmationModal = ({ label, description, open, setOpen, onConfirm }: Props) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{label || 'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText minWidth={'15em'}>
            {description || 'Are you sure you want to confirm this action?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
