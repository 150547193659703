import { debounce } from "./debounce";

class VersionUpdateChecker {
    // Additional control just in case
    debouncedFunction = debounce(() => this.checkForUpdates(), 55000);

    /**
     * Creates an interval that will run each 1 minute and check if the web app has a new version.
     * @returns function to clear the interval
     */
    public init() {
        var id = setInterval(() => {
            this.debouncedFunction();
        }, 60000);

        return () => { clearInterval(id); }
    }
    
    /**
     * If the web app is not in development mode, it makes a request to /version.json and check if the web app has a new version
     * If has a new version then reloads the page.
     */
    private async checkForUpdates() {
        try {
            // Check env
            if (process.env.NODE_ENV !== 'development') {
                // Get the version.json
                const response = await fetch("version.json", {headers: {"Cache-Control": "no-cache"}});
                const contentType = response.headers.get("content-type")

                // Check if version.json is present
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const json: {version: string} = await response.json();
                    const localVersion = localStorage.getItem("_core_version");
                    
                    if (!localVersion) {
                        // if local version is not present, set the json version as the local version.
                        localStorage.setItem("_core_version", json.version);
                    } else if (localVersion != json.version) {
                        // if the local version is not equal to the json version update it and reload the browser
                        localStorage.setItem("_core_version", json.version);
                        console.log("New app version detected. Reloading the page...")
                        window.location.reload();
                    }
                }
            }
        } catch (error) {
            console.log("[ERROR] Error trying to get app version", error)
        }     
    }
}

export default new VersionUpdateChecker();