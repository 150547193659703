import { Grid } from '@mui/material';
import { Bike } from '../../external/pdl-common/model/Bike';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../../external/pdl-common/utils/AppTheme';
import { Box } from '@material-ui/core';
import { BikeInformation } from './BikeInformation';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import BikeService from '../../services/BikeService';
import PdlAlertService from '../../services/PdlAlertService';
import { UserDetails } from './UserDetails';
import { AppTopBar } from '../common/AppTopBar';
import BikeParts from './BikeParts';
import { BackButton } from '../BackButton';
import { BikeState } from '../../models/location-states/BikeState';
import { SectionDetail } from '../common/SectionDetail';
import { StatusBar } from '../common/StatusBar';
import { AppButton } from '../common/AppButton';
import { BikeStatus } from '../../external/pdl-common/utils/enums/BikeStatus';
import { CalendarModal } from './CalendarModal';

const BikeOverview = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation().state as BikeState;

  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [bikeStatus, setBikeStatus] = useState<BikeStatus | undefined>(undefined);

  useEffect(() => {
    load();
  }, [externalId]);

  const load = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.getByExternalId(externalId))
      .onSuccess((response) => {
        setBike(response.getContent());
        setBikeStatus(response.getContent().status);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const approveBike = async () => {
    (await BikeService.approve(bike?.externalId!))
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('Bike approved successfully');
        setBikeStatus(BikeStatus.APPROVED);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const denyBike = async () => {
    (await BikeService.deny(bike?.externalId!))
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('Bike denied successfully');
        setBikeStatus(BikeStatus.DENIED);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  return requestStatus === RequestStatus.LOADING ? (
    <></>
  ) : (
    <>
      <AppTopBar title={'Bikes'} />

      {/* Back button */}
      <BackButton
        title={location.previousPage === '/inbox' ? 'Back to Inbox' : 'Back to all bikes'}
        onClick={() => history.push(`${location.previousPage}`, { ...location })}
        style={{ width: '10em' }}
      />

      <StatusBar
        leftButton={<AppButton text={'Reserved / Blockout dates'} onClick={() => setShowCalendar(!showCalendar)} />}
        label="Bike status"
        status={bikeStatus}
        button={
          bikeStatus === BikeStatus.PENDING && <AppButton text={'Approve'} onClick={approveBike} />
        }
        extraButton={
          bikeStatus === BikeStatus.PENDING && <AppButton text={'Deny'} onClick={denyBike} />
        }
      />

      {bike && (
        <>
          {/* BIKE DETAILS */}
          <Grid container className={classes.container}>
            <Grid item>
              <Box style={{ width: '30em', height: '30em' }}>
                <Carousel
                  bike={bike}
                  showIndexIndicator
                  slidesToShow={{ lg: 1, md: 1, sm: 1 }}
                  data={bike?.images ?? []}
                  renderItem={(img) => (
                    <AppImage
                      key={img.externalId}
                      src={img.url}
                      alt={bike?.title}
                      style={{
                        width: '30em',
                        height: '30em',
                        borderRadius: '2em 0 0 2em',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item sx={{ marginLeft: '2em' }}>
              <BikeInformation bike={bike} />
            </Grid>
          </Grid>

          {/* BIKE OWNER */}
          <SectionDetail component={<UserDetails user={bike.owner} />} />

          {/* BIKE PARTS */}
          <SectionDetail title="Part Details" component={<BikeParts bike={bike} />} hideDivider />
        </>
      )}

      <CalendarModal
        open={showCalendar}
        onClose={() => setShowCalendar(false)}
        bike={bike}
      />
    </>
  );
};

export default BikeOverview;

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    borderRadius: '2em',
    backgroundColor: COLORS.gray_F9F9F9,
    marginTop: '.8em',
  },
}));
