import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import PagedResult from "../../fox-typescript/core/PagedResult";

export interface CardItemSpec<T> {
  header: Spec<T>[];
  body: Spec<T>[];
  footer: Spec<T>[];
}

interface Spec<T> {
  content: (instance: T) => JSX.Element;
}

interface Props<T> {
  /**
   * Function that will be triggered every time that data has to be fetched
   * e.g. when the UI loads, when a page changes, etc.
   */
  fetchData: (page: number, rowsPerPage: number) => Promise<PagedResult<T>>;
  /**
   * Specs for each Item of the card.
   */
  cardItemSpec?: CardItemSpec<T>;
  /**
   * This prop should be updated every time the list of T changed, thus triggering a potential update.
   */
  lastUpdateTimestamp: number;
  /**
   * Text or component to render if list data is empty.
   * Default value is "No data to display"
   */
  listEmptyComponent?: JSX.Element;
}

function DetailListCrud<T>(props: Props<T>) {
  const [content, setContent] = useState<T[]>([]);
  const spec = props.cardItemSpec;

  useEffect(() => {
    props.fetchData(0, 20).then((result) => {
      setContent(result.content);
    });
  }, [props.lastUpdateTimestamp, props.fetchData]);

  const emptyComponent = props.listEmptyComponent || <div style={{textAlign: "center"}}>No data to display</div>;

  return (
    <>
      {content.length == 0 && (
        <Card variant="outlined" style={{ marginTop: '15px' }}>
          <CardContent style={{ padding: '1em' }}>
            {emptyComponent}
          </CardContent>
        </Card>
      )}
      {content.map((event) => (
        <>
          <Card variant="outlined" style={{ marginTop: "15px" }}>
            <CardContent>
              {/* Header */}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {spec?.header.map((item) => (
                  <Typography>{item.content(event)}</Typography>
                ))}
              </Box>

              {/* Body */}
              <Box>
                {spec?.body.map((item) => (
                  <Typography>{item.content(event)}</Typography>
                ))}
              </Box>

              {/* Footer */}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {spec?.footer.map((item) => (
                  <Typography>{item.content(event)}</Typography>
                ))}
              </Box>
            </CardContent>
          </Card>
        </>
      ))}
    </>
  );
}

export default DetailListCrud;
