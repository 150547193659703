import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import { Bike } from '../../external/pdl-common/model/Bike';
import BikePartDetail from './BikePart';

interface Props {
  bike: Bike;
}

const BikePartDetailList = ({ bike }: Props) => {
  return (
    <>
      <Grid container flexWrap="wrap" rowGap="2em" mb={3}>
        {bike.parts?.map((part, index) => (
          <Box key={`${bike.externalId}-${index}`}>
            <BikePartDetail key={`${bike.externalId}-${index}`} part={part} />
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default BikePartDetailList;
