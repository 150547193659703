import { Grid } from '@mui/material';
import { makeStyles, Box, Divider } from '@material-ui/core';
import { SimpleCellText } from '../../external/pdl-common/components/SimpleCellText';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import Reservation from '../../external/pdl-common/model/Reservation';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { AppItem } from '../common/AppItem';

interface Props {
  reservation: Reservation;
}

const ReservationRentalDates = ({ reservation }: Props) => {
  const { location } = reservation.bike;
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <AppItem
          title={'Rental Dates'}
          value={DateUtils.formattedRangeDates(
            new Date(reservation!.startDate),
            new Date(reservation!.endDate)
          )}
        />
        <AppItem
          title={'Pick-up and Return'}
          value={[location.city, location.state, location.zipCode].filter(Boolean).join(', ')}
        />
      </Box>
    </>
  );
};

export default ReservationRentalDates;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '40vw',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1em',
    paddingTop: '.3em',
    paddingBottom: '.3em',
  },
}));
