import { InboxType } from '../components/Inbox/utils/InboxType';
import { InboxStatusType } from '../external/pdl-common/utils/InboxStatusType';
import { SortType } from '../types/SortType';

class FilterUtils {
  public getSortType = (sortBy: SortType): string => {
    return Object.keys(SortType)[Object.values(SortType).indexOf(sortBy)];
  };

  public getInboxType = (type: InboxType): string => {
    return Object.keys(InboxType)[Object.values(InboxType).indexOf(type)];
  };

  public getStatusType = (type: InboxStatusType): string => {
    if (type === InboxStatusType.OPEN) {
      return `${InboxStatusType.PENDING},${InboxStatusType.ASSIGNED}`;
    } else if (type === InboxStatusType.ALL) {
      return `${InboxStatusType.PENDING},${InboxStatusType.ASSIGNED},${InboxStatusType.CLOSE}`;
    }
    return InboxStatusType[type];
  };
}

export default new FilterUtils();
