import { IPage } from '../external/fox-typescript/core/IPage';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import { PedalUser } from '../external/pdl-common/model/PedalUser';
import { UsersPageRequestDTO } from '../models/UsersPageRequestDTO';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import environment from '../environment.json';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { AccessRoleUpdateDTO } from '../external/pdl-common/model/AccessRoleUpdate';
import { FullUser } from '../external/pdl-common/model/FullUser';

class UserService extends GenericCrudService<FullUser> {
  async getUsersPage(filters: UsersPageRequestDTO): Promise<PagedResult<PedalUser>> {
    const res = await httpClient.getWithParams<IPage<PedalUser>>(
      `${this.basePath}/paginated`,
      filters,
      true
    );
    return PagedResult.fromIPage(res.getContent());
  }

  async enable(externalId: string): Promise<ApiResponse<String>> {
    return await httpClient.put(`${this.basePath}/${externalId}/enable`, {}, true);
  }

  async disable(externalId: string): Promise<ApiResponse<String>> {
    return await httpClient.put(`${this.basePath}/${externalId}/disable`, {}, true);
  }

  async getSupportUsers(): Promise<ApiResponse<PedalUser[]>> {
    return await httpClient.get(`${this.basePath}/support`, true);
  }

  async addRole(externalId: string, dto: AccessRoleUpdateDTO): Promise<ApiResponse<String>> {
    return await httpClient.put(`${this.basePath}/${externalId}/add-role`, dto, true);
  }

  async removeRole(externalId: string, dto: AccessRoleUpdateDTO): Promise<ApiResponse<String>> {
    return await httpClient.put(`${this.basePath}/${externalId}/remove-role`, dto, true);
  }

  async reSendActivationMail(externalId: string): Promise<ApiResponse<String>> {
    return await httpClient.post(`${this.basePath}/${externalId}/resend-activation-mail`, {}, true);
  }

}

export default new UserService(`${environment.baseURL}/portal/pdl-user`);
