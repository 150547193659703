import { Grid, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { Bike } from '../../external/pdl-common/model/Bike';
import AppTheme from '../../external/pdl-common/utils/AppTheme';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import ReservationService from '../../services/ReservationService';
import {ReservedBlockoutDatesDTO} from "../../models/ReservedBlockoutDatesDTO";

interface Props {
  open: boolean;
  onClose: () => void;
  bike: Bike | undefined;
}

export const CalendarModal = ({ open, onClose, bike }: Props) => {
  const classes = useStyles();

  const [reservedDates, setReservedDates] = useState<DateObject[]>([]);
  const [blockoutDates, setBlockoutDates] = useState<DateObject[]>([]);

  useEffect(() => {
    getReservationDates();
  }, []);

  const getReservationDates = async () => {
    (await ReservationService.getReservationAndBlockoutDates(bike?.externalId!))
      .onSuccess((content: ReservedBlockoutDatesDTO) => {
        setReservedDates(content.reservedDates.map((d) => new DateObject(d)));
        setBlockoutDates(content.blockoutDates.map((d) => new DateObject(d)));
      })
      .onError((content, response) => {
        console.log("Couldn't get reserved dates", response.getMessage());
      });
  };

  const mapDisabledDates = (date: DateObject) => {
    const dateInDateFormat = new Date(date.year, date.month.number - 1, date.day);
    const today = new Date();
    const todayEqualDate = dateInDateFormat.getFullYear() == today.getFullYear() &&
      dateInDateFormat.getMonth() == today.getMonth() && dateInDateFormat.getDate() == today.getDate();

    if (DateUtils.greaterThanTodayInTimezone(dateInDateFormat, bike?.location.timezoneId!)
      && !todayEqualDate) {
      return {
      };
    }

    let isReserved = reservedDates.some(
      (e) => e.day === date.day && e.month.number === date.month.number && e.year === date.year
    );

    if (isReserved) {
      return {
        disabled: true,
        style: { color: 'white', backgroundColor: 'red', borderRadius: '1em' },
      };
    }
    let isBlockout = blockoutDates.some(
      (e) => e.day === date.day && e.month.number === date.month.number && e.year === date.year
    );

    if (isBlockout) {
      return {
        disabled: true,
        style: { color: 'white', backgroundColor: 'blue', borderRadius: '1em' },
      };
    }

  };

  return (
    <Modal open={open} onClose={onClose}>
      <Grid className={classes.modal}>
        <Calendar
          className={classes.calendar}
          multiple={true}
          value={new Date()}
          onChange={(value: DateObject[]) => {
            return;
          }}
          format={'YYYY/MM/DD'}
          minDate={new Date()}
          disableMonthPicker={true}
          disableYearPicker={true}
          weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          months={[
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ]}
          fullYear={false}
          numberOfMonths={1}
          mapDays={({ date }) => mapDisabledDates(date)}
        />
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '1em',
    padding: '2em',
  },
  calendar: {
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    marginTop: '.5em',
    boxShadow: 'none',
    '& div': {
      color: 'black',
      width: '100%',
      fontSize: '0.97em',
      '& .rmdp-day': {
        fontWeight: '400',
        width: '2.5em',
        height: '2.5em',
      },
      '& .rmdp-day.rmdp-selected': {
        '& .sd': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
          borderBottom: `solid 1px white`,
          borderRadius: '5em',
          boxShadow: 'none',
        },
      },
      '& .rmdp-day.rmdp-disabled': {
        color: '#aeb9bf',
        '& :hover': {
          color: '#aeb9bf',
        },
      },
      '& .rmdp-day.rmdp-today': {
        '& .sd': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
          borderBottom: `solid 0px white`,
          borderRadius: '0em',
          boxShadow: 'none',
        },
      },
      '& .rmdp-day:hover': {
        '& .sd': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
        },
        '& :hover': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
        },
      },
    },
    '& .rmdp-arrow-container:hover': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
      boxShadow: 'none',
      borderColor: 'red',
      '& i': {
        borderColor: AppTheme.colors.black_000000,
      },
    },
    '& .rmdp-arrow-container': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
      boxShadow: 'none',
      '& i': {
        borderColor: AppTheme.colors.black_000000,
      },
    },
    '& .rmdp-arrow-container.rmdp-left': {
      marginLeft: '0.9em',
    },
    '& .rmdp-header-values': {
      fontSize: '1em',
      fontWeight: '700',
    },
    '& .rmdp-week': {
      marginBottom: '0.25em',
      marginRight: '1em',
      padding: '0.2em',
      paddingLeft: '1em',
    },
    '& .rmdp-week-day': {
      width: '2.5em',
      height: '2.5em',
    },
  },
});
