import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BackButton } from '../components/BackButton';
import { AppTopBar } from '../components/common/AppTopBar';
import Reservation from '../external/pdl-common/model/Reservation';
import { RequestStatus } from '../external/pdl-common/utils/RequestStatus';
import { ViewDetailState } from '../models/location-states/GenericViewDetailState';
import PdlAlertService from '../services/PdlAlertService';
import reservationService from '../services/ReservationService';
import { Box, Grid } from '@mui/material';
import { Carousel } from '../external/pdl-common/components/commons/Carousel';
import AppImage from '../external/pdl-common/components/commons/AppImage';
import { BikeInformation } from '../components/Bike/BikeInformation';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../external/pdl-common/utils/AppTheme';
import { UserDetails } from '../components/Bike/UserDetails';
import ReservationRentalDates from '../components/Reservation/ReservationRentalDates';
import ReservationInsurance from '../components/Reservation/ReservationInsurance';
import ReservationPayment from '../components/Reservation/ReservationPayment';
import PickUpDropOff from '../components/Reservation/PickUpDropOff';
import { SectionDetail } from '../components/common/SectionDetail';
import { StatusBar } from '../components/common/StatusBar';
import { Features } from '../external/pdl-common/utils/enums/Features';
import { useFeatureToggle } from '../external/pdl-common/hooks/useFeatureToggle';
import { AppButton } from '../components/common/AppButton';
import { ConfirmationModal } from '../components/common/ConfirmationModal';

export function TripOverview() {
  const classes = useStyles();

  const history = useHistory();
  const state = useLocation().state as ViewDetailState;
  const { externalId } = useParams<{ externalId: string }>();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [isInuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);
  const [temporaryLocation, setTemporaryLocation] = useState<string>('');
  const [confirmForceDropOffDialog, setConfirmForceDropOffDialog] = useState<boolean>(false);

  useEffect(() => {
    if (externalId) {
      loadTrip();
    }
  }, [externalId]);

  useEffect(() => {
    setTemporaryLocation([ // TODO refactor
          reservation?.temporaryAdditionalPersonalInformation?.streetAddress || '',
          reservation?.temporaryAdditionalPersonalInformation?.city || '',
          reservation?.temporaryAdditionalPersonalInformation?.stateCode! || '',
          reservation?.temporaryAdditionalPersonalInformation?.zipCode || '',
          reservation?.temporaryAdditionalPersonalInformation?.countryCode || '',
        ]
            .filter(Boolean)
            .join(', ')
    )
  }, [reservation]);

  const loadTrip = async () => {
    setRequestStatus(RequestStatus.LOADING);
    (await reservationService.getSemiPublicReservation(externalId))
      .onSuccess((response) => {
        setReservation(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getRideStatus = (): string => {
    if (!reservation) {
      return '';
    }

    if (reservation.cancelledAt) {
      return 'CANCELLED (' + reservation.cancelledBy + ')';
    }
    if (reservation.droppedOffAt) {
      return 'COMPLETED';
    }
    if (reservation.pickedUpAt) {
      return 'IN PROGRESS';
    }
    return 'NOT STARTED';
  };

  const getLeftButton = () => {
    if (getRideStatus() == 'IN PROGRESS') {
      return <AppButton text={'Force Drop-off'} onClick={() => setConfirmForceDropOffDialog(!confirmForceDropOffDialog)}/>
    }
    return false;
  }


  return requestStatus === RequestStatus.ERROR ? (
    <></>
  ) : (
    <>
      <ConfirmationModal
        label={'Force Drop-off'}
        description={'Are you sure you want to force drop-off?'}
        open={confirmForceDropOffDialog}
        setOpen={setConfirmForceDropOffDialog}
        onConfirm={() => {
          (async () => {
            (await reservationService.forceDropOff(reservation?.externalId!))
              .onSuccess(() => {
                PdlAlertService.showSuccessMessage('Drop-off forced successfully');
                loadTrip();
              })
              .onError((response) => {
                PdlAlertService.showSnackCustomError(response.getContent());
              });
          })();
        }}
        
      />
      <AppTopBar title={'Rides'} />

      {/* Back button */}
      <BackButton
        title={state.previousPage === '/inbox' ? 'Back to Inbox' : 'Back to all rides'}
        onClick={() => history.push(`${state.previousPage}`, { ...state })}
      />

      <StatusBar 
        leftButton={getLeftButton()}
        label="Ride status"
        status={getRideStatus()}
      />

      {/* Reservation Details */}
      {reservation && reservation.bike && (
        <>
          <Grid container className={classes.mainContainer}>
            <Grid item>
              <Box style={{ width: '30em', height: '30em' }}>
                <Carousel
                  bike={reservation.bike}
                  showIndexIndicator
                  slidesToShow={{ lg: 1, md: 1, sm: 1 }}
                  data={reservation.bike?.images ?? []}
                  renderItem={(img) => (
                    <AppImage
                      key={img.externalId}
                      src={img.url}
                      alt={reservation.bike?.title}
                      style={{
                        width: '30em',
                        height: '30em',
                        borderRadius: '2em 0 0 2em',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item sx={{ marginLeft: '2em' }}>
              <BikeInformation bike={reservation.bike} />
            </Grid>
          </Grid>

          {/* HOST DETAILS */}
          <SectionDetail title="Host" component={<UserDetails user={reservation.bike.owner} />} />

          {/* RENTER DETAILS */}
          <SectionDetail title="Renter" component={<UserDetails user={reservation.renter} temporaryLocation={temporaryLocation}/>} />

          {/* RENTAL DATES */}
          <SectionDetail
            title="Rental dates and pick-up"
            component={<ReservationRentalDates reservation={reservation} />}
          />

          {/* PAYMENT */}
          <SectionDetail
            title="Payment"
            component={<ReservationPayment reservation={reservation} />}
          />

          {/* INSURANCE */}
          {isInuranceFeatureEnabled &&
              <SectionDetail
                title="Protection Plan"
                component={<ReservationInsurance reservation={reservation} />}
              />
          }

          {/* PICK UP */}
          <SectionDetail
            title="Pick up"
            component={
              reservation.pickUpCheckList && (
                <PickUpDropOff
                  checklist={reservation.pickUpCheckList}
                  bike={reservation.bike}
                  images={reservation.pickUpImages}
                  date={reservation.pickedUpAt!}
                  paymentIntentId={reservation.paymentIntentId!}
                  cancelHoldOnStatus={reservation.cancelHoldOnStatus!}
                />
              )
            }
          />

          {/* DROP OFF */}
          <SectionDetail
            title="Drop off"
            component={
              reservation.dropOffCheckList && (
                <PickUpDropOff
                  checklist={reservation.dropOffCheckList}
                  bike={reservation.bike}
                  images={reservation.dropOffImages}
                  date={reservation.droppedOffAt!}
                />
              )
            }
          />
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  mainContainer: {
    borderRadius: '2em',
    backgroundColor: COLORS.gray_F9F9F9,
    marginTop: '.8em',
  },
  container: {
    borderRadius: '2em',
    backgroundColor: COLORS.gray_F9F9F9,
    margin: '1em 1em 1em 0',
    padding: '1.5em 1.5em 1.5em 1.5em',
  },
}));
