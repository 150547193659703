import Bikes from './Bikes';
import { AppTopBar } from '../components/common/AppTopBar';
import WaitListUsers from './WaitListUsers';
import AuthService from '../external/fox-typescript/services/AuthService';
import { BikeState } from '../models/location-states/BikeState';
import { useLocation } from 'react-router-dom';

export const Dashboard = () => {
  const state = useLocation().state as BikeState;

  return (
    <>
      <AppTopBar title={'Dashboard'} />

      {(AuthService.hasRole('ROLE_ADMIN') || AuthService.hasRole('ROLE_SUPERADMIN')) && (
        <WaitListUsers defaultFilter="includeNotEvaluated" />
      )}

      <Bikes hideTopBar defaultFilter="includePending" />
    </>
  );
};
