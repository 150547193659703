import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Stack } from '@mui/material';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';

interface Props {
  title: string;
  value: string;
  titleStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  onValueClick?: () => void;
}

export const AppItem = ({ title, value, valueStyle, titleStyle, onValueClick }: Props) => {
  const classes = useStyles();

  return (
    <Stack spacing={1} sx={{ minWidth: '8em' }}>
      <AppTypography
        type={PDLTypography.portalItem}
        customClasses={classes.title}
        children={title}
        customStyles={{ ...titleStyle }}
      />

      <AppTypography
        type={PDLTypography.portalItem}
        children={value}
        customStyles={{ ...valueStyle }}
        onClick={onValueClick}
      />
    </Stack>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#01000E',
    opacity: 0.5,
  },
}));
