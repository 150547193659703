import { Box, IconButton } from '@material-ui/core';
import { useEffect, useState } from 'react';
import DropdownMenu from '../components/DropdownMenu';
import Panel from '../components/Panel';
import { Title } from '../components/Titles';
import TableBasedCrud from '../external/ensolvers-core-frontend-mui/components/TableBaseCrud';
import User from '../external/fox-typescript/core/User';
import AuthService from '../external/fox-typescript/services/AuthService';
import { PedalUser } from '../external/pdl-common/model/PedalUser';
import PdlAlertService from '../services/PdlAlertService';
import UserService from '../services/UserService';
import { AccesRole } from '../external/pdl-common/utils/enums/AccesRole';
import { AccessRoleUpdateDTO } from '../external/pdl-common/model/AccessRoleUpdate';
import PortalInput from '../components/PortalInput';
import RoundedImageWithText from '../external/pdl-common/components/RoundedImageWithText';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoutes } from '../utils/enums/AppRoutes';
import { ViewDetailState } from '../models/location-states/GenericViewDetailState';
import InboxService from '../services/InboxService';
import ChatChannelCreation from '../models/creation-interfaces/ChatChannelCreation';
import { TextField } from '@mui/material';
import { GenericModal } from '../components/common/GenericModal';
import RefreshIcon from '@mui/icons-material/Refresh';

type Filters = {
  includeEnabled: boolean;
  includeDisabled: boolean;
};

interface Props {
  hideTopBar?: boolean;
  customFilters?: Filters;
}

export default function PdlUsers({ hideTopBar = false, customFilters }: Props) {
  const history = useHistory();
  let state: ViewDetailState = useLocation().state as ViewDetailState;

  const [pageNumber, setPageNumber] = useState<number>(state?.pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(state?.pageSize || 5);

  const [selectedFilterPdlUser, setSelectedFilterPdlUser] = useState<string>(
    state?.selectedFilter || 'allUsers'
  );
  const [userNameOrEmail, setUserNameOrEmail] = useState<string>(state?.searchFilter || '');
  const [filtersPdlUser, setFiltersPdlUser] = useState<Filters>({
    includeEnabled: true,
    includeDisabled: true,
  });
  const [updateResultsOn, setUpdateResultsOn] = useState<number>(Date.now());

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [contactUserMessage, setContactUserMessage] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<PedalUser | undefined>(undefined);

  useEffect(() => {
    handleFilterChange(selectedFilterPdlUser);
  }, []);

  useEffect(() => {
    if (customFilters) {
      // @ts-ignore
      handleFilterChange(Object.keys(customFilters).find((key) => customFilters[key]));
    }
  }, [customFilters]);

  const handleOnDataFetchFromPdlUser = (pageNumberPdlUser: number, rowsPerPagePdlUser: number) => {
    setPageNumber(pageNumberPdlUser);
    setRowsPerPage(rowsPerPagePdlUser);

    return UserService.getUsersPage({
      page: pageNumberPdlUser,
      pageSize: rowsPerPagePdlUser,
      ...filtersPdlUser,
      nameOrEmailFilter: userNameOrEmail,
    });
  };

  const handleFilterChange = (selectedOption: string) => {
    let updatedFilters = { ...filtersPdlUser };
    if (selectedOption === 'allUsers') {
      updatedFilters.includeEnabled = true;
      updatedFilters.includeDisabled = true;
    } else {
      updatedFilters.includeEnabled = false;
      updatedFilters.includeDisabled = false;
      (updatedFilters as any)[selectedOption] = true;
    }
    setFiltersPdlUser(updatedFilters);
    setUpdateResultsOn(Date.now());
    setSelectedFilterPdlUser(selectedOption);
  };

  const enableUser = async (instance: PedalUser, refresh: () => void) => {
    const res = await UserService.enable(instance.externalId!);
    res
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('User enabled successfully');
        refresh();
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const disableUser = async (instance: PedalUser, refresh: () => void) => {
    const res = await UserService.disable(instance.externalId!);
    res
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('User disabled successfully');
        refresh();
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const addOrRemoveRole = async (
    instance: PedalUser,
    refresh: () => void,
    role: AccesRole,
    add?: boolean
  ) => {
    const dto: AccessRoleUpdateDTO = {
      role,
    };
    const response = add
      ? await UserService.addRole(instance.externalId!, dto)
      : await UserService.removeRole(instance.externalId!, dto);

    response
      .onSuccess((response) => {
        PdlAlertService.showSuccessMessage(response.getContent().toString());
        refresh();
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const getRoles = (user: User): string => {
    let roles: string = '';
    user.roles.every((r) => (roles = roles.concat(' ', r.name.replace('ROLE_', ''), ',')));
    return roles.replace(/,$/, '');
  };

  const viewDetail = (user: PedalUser) => {
    return (
      <>
        {history.push(`${AppRoutes.USER_DETAILS}/${user.externalId}`, {
          previousPage: AppRoutes.USERS,
          pageSize: rowsPerPage,
          pageNumber: pageNumber,
          selectedFilter: selectedFilterPdlUser,
          searchFilter: userNameOrEmail,
        })}
      </>
    );
  };

  const reSendActivationMail = async (userExternalId: string) => {
    const res = await UserService.reSendActivationMail(userExternalId);
    res
      .onSuccess(() => {
        PdlAlertService.showSuccessMessage('Activation mail resent successfully');
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response.getContent());
      });
  };

  const getOptions = (instance: PedalUser, refresh: () => void) => {
    const hasSupportRole = AuthService.hasAnyRole([AccesRole.ROLE_SUPPORT], instance.user as User);
    const hasAdminRole = AuthService.hasAnyRole([AccesRole.ROLE_ADMIN], instance.user as User);

    let options: { title: string; action: () => void }[] = [
      {
        title: 'View',
        action: () => viewDetail(instance),
      },
    ];

    if (!instance.user.enabled) {
      if (AuthService.hasAnyRole([AccesRole.ROLE_ADMIN, AccesRole.ROLE_SUPERADMIN])) {
        options.push({
          title: 'Enable',
          action: () => enableUser(instance, refresh),
        });
      }
    } else {
      if (AuthService.hasAnyRole([AccesRole.ROLE_ADMIN, AccesRole.ROLE_SUPERADMIN])) {
        options.push(
          {
            title: 'Disable',
            action: () => disableUser(instance, refresh),
          }
        );
      }
      options.push(
        {
          title: 'Contact user',
          action: () => {
            setSelectedUser(instance);
            setOpenModal(true);
          },
        }
      );
      if (AuthService.hasAnyRole([AccesRole.ROLE_ADMIN, AccesRole.ROLE_SUPERADMIN, AccesRole.ROLE_SUPPORT])) {
        options.push(
          {
            title: `${!hasSupportRole ? 'Add' : 'Remove'} support role`,
            action: () => addOrRemoveRole(instance, refresh, AccesRole.ROLE_SUPPORT, !hasSupportRole),
          }
        );
      }
    }

    if (AuthService.hasAnyRole([AccesRole.ROLE_SUPERADMIN, AccesRole.ROLE_SUPPORT])) { //remove support role
      options.push({
        title: `${!hasAdminRole ? 'Add' : 'Remove'} admin role`,
        action: () => addOrRemoveRole(instance, refresh, AccesRole.ROLE_ADMIN, !hasAdminRole),
      });
    }

    if (!instance.user.enabled && AuthService.hasAnyRole([AccesRole.ROLE_ADMIN, AccesRole.ROLE_SUPERADMIN, AccesRole.ROLE_SUPPORT])) {
      options.push(
        {
          title: 'Resend activation mail',
          action: () => reSendActivationMail(instance.externalId!),
        }
      );
    }
    return options;
  };

  const handleUserNameFilterChange = (value: string) => {
    if (value.trim().length > 3 || value !== userNameOrEmail) {
      setUserNameOrEmail(value);
    }
  };

  const sendNewMessage = async () => {
    const chatChannelDto = new ChatChannelCreation(selectedUser?.externalId!, contactUserMessage);

    (await InboxService.createChatChannel(chatChannelDto))
      .onSuccess(() => {
        setOpenModal(false);
        setContactUserMessage('');
        PdlAlertService.showSuccessMessage('Message successfully sent');
      })
      .onError((response) => {
        PdlAlertService.showSnackCustomError(response);
      });
  };

  return (
    <>
      <Title>Users</Title>
      <Box style={{ textAlign: 'center' }}>
        <IconButton
          onClick={() => handleFilterChange(selectedFilterPdlUser)}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Panel>
        <TableBasedCrud
          elementName={'Pdl-user'}
          onDataFetch={handleOnDataFetchFromPdlUser}
          page={state?.pageNumber}
          rowsPerPage={state?.pageSize}
          renderDialogContent={(register, errors, control) => <></>}
          tableContainer={(props) => <Box>{props.children}</Box>}
          forceUpdateOn={updateResultsOn}
          additionalToolbarButtons={() => (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <DropdownMenu
                handleOptionChange={handleFilterChange}
                label={'Filter:'}
                value={selectedFilterPdlUser}
                options={[
                  {
                    title: 'All users',
                    value: 'allUsers',
                  },
                  {
                    title: 'Enabled',
                    value: 'includeEnabled',
                  },
                  {
                    title: 'Disabled',
                    value: 'includeDisabled',
                  },
                ]}
              />
              <PortalInput
                label={'Search: '}
                value={userNameOrEmail}
                handleChange={(val) => handleUserNameFilterChange(val.target.value)}
                placeholder={'User name or email'}
              />
            </div>
          )}
          tableColumns={[
            {
              header: 'First name',
              content: (instance) => (
                <RoundedImageWithText
                  src={instance.user.avatarUrl}
                  title={instance.user.firstName}
                />
              ),
            },
            {
              header: 'Last name',
              content: (instance) => <>{instance.user.lastName}</>,
            },
            {
              header: 'Email',
              content: (instance) => <>{instance.user.email}</>,
            },
            {
              header: 'Roles',
              content: (instance) => <>{getRoles(instance.user as User)}</>,
            },
            {
              header: 'Status',
              content: (instance) => <>{instance.user.enabled ? 'Enabled' : 'Disabled'}</>,
            },
          ]}
          buildAdditionalActions={(instance, refresh) => getOptions(instance, refresh)}
        />
      </Panel>

      <GenericModal
        title={'Send a message'}
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={sendNewMessage}
        disabled={contactUserMessage === ''}
        onClose={() => {
          setOpenModal(false);
          setContactUserMessage('');
        }}
        children={
          <TextField
            multiline
            rows={8}
            value={contactUserMessage}
            onChange={(val) => setContactUserMessage(val.target.value)}
            placeholder="Write a message..."
            style={{ margin: '1em 0', width: '600px' }}
          />
        }
      />
    </>
  );
}
