import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { SnackBarData } from "../../types/SnackbarData";

/**
 * Shows a Snackbar message on top of the screen.
 *
 * Usage: dispatch an event with the following structure in any component.
 *
 *  const sendMessage = (data: SnackBarData) => {
 *    const snackData = data;
 *    const customEvent = new CustomEvent('snackMessage', {detail: {snackData}});
 *    document.dispatchEvent(customEvent);
 *  }
 *
 *   This SnackbarComponent with listen to that event and update the snackbar properly.
 */
export default function SnackbarComponent() {
  const [snackData, setSnackData] = useState<SnackBarData | null>(null);

  useEffect(() => {
    document.addEventListener("snackMessage", updateSnackData);
    return () => {
      document.removeEventListener("snackMessage", updateSnackData);
    };
  }, []);

  const updateSnackData = (event: any) => {
    setSnackData(event.detail.snackData);
  };

  return (
    snackData && (
      <Snackbar
        open={!!snackData}
        autoHideDuration={6000}
        onClose={() => setSnackData(null)}
      >
        <MuiAlert elevation={6} variant="filled" severity={snackData.severity}>
          {snackData.text}
        </MuiAlert>
      </Snackbar>
    )
  );
}
