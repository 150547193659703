import { Avatar, Box, Divider, makeStyles } from '@material-ui/core';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { ChatMessage as ChatMessageModel } from '../../external/pdl-common/model/ChatMessage';
import ChatUtils from '../../external/pdl-common/utils/ChatUtils';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import StringUtils from '../../external/pdl-common/utils/StringUtils';

interface Props {
  message: ChatMessageModel;
  showDivider?: boolean;
  isFirstMessage?: boolean;
  rentalDates?: string;
}

export const ChatMessage = ({
  message,
  showDivider = true,
  isFirstMessage,
  rentalDates,
}: Props) => {
  const classes = useStyles();

  if (isFirstMessage) {
    message.body = ChatUtils.replaceStringReservationDates(message.body, rentalDates!);
  }

  return (
    <Box style={{ marginBottom: '1.5em' }}>
      <Box className={classes.chatMessageContainer}>
        {/* Message avatar */}
        <Avatar src={message.sender.user.avatarUrl} />

        {/* Message details */}
        <Box style={{ width: '100%' }}>
          <Box className={classes.userContainer}>
            <AppTypography
              type={PDLTypography.smallHeading}
              children={StringUtils.formatShortUserName(message.sender.user)}
            />
            <AppTypography
              type={PDLTypography.portalLabel}
              children={DateUtils.formattedPortalChatDate(new Date(message.createdAt))}
            />
          </Box>

          {/* Message body */}
          <AppTypography type={PDLTypography.paragraph} children={message.body} />
        </Box>
      </Box>

      {showDivider && <Divider />}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  chatMessageContainer: {
    display: 'flex',
    gap: '1em',
    width: '100%',
    marginBottom: '1.5em',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1em',
  },
}));
